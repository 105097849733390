import React from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';

class ArrowForwardComponent extends React.Component {
  render() {
    return (
      <ArrowForward
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        variant={this.props.variant}
        className={this.props.className}
      />
    )
  }
}

ArrowForwardComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
}

export default ArrowForwardComponent;