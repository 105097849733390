import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '../../coraWebMComponents/inputs/IconButton';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import { Transition } from './support';
import ChartDetail from './ChartDetail.js';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    boxSizing: "border-box",
    // border: `1px dotted ${theme.palette.divider}`,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  dialogContent: {
    width: '100%',
    // height: '98%',
    // border: `1px dotted ${theme.palette.divider}`,
    boxSizing: "border-box",
    paddingTop: "100px",
  },
  dialogTitle: {
    boxSizing: "border-box",
    padding: theme.spacing(1),
  },
  divider: {
    borderTop: `1px dotted ${theme.palette.primary.main}`,
  },
}));

const Chart = ({ _params, onClose, chartParams, data, isMobile }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        className={classes.root}
        open={true}
        onClose={onClose}
        scroll='body'
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        fullWidth={true}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle id="scroll-dialog-title"
          className={classes.root}
        >
          <Stack justifyContent="space-between" alignItems={"baseline"} width="100%" spacing={1} direction={'row'} p={1}>
            <Stack flexGrow="1" justifyContent="flex-start" spacing={2} direction={'row'}>
              <Typography variant="h5" className={classes.dialogTitle}>{chartParams.nGraf}</Typography>
            </Stack>
            <Stack flexGrow="0" justifyContent="flex-end" spacing={1} direction={'row'}>
              <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Box className={classes.divider} />

        </DialogTitle>
        <DialogContent className={classNames(classes.dialogContent)}>
          <Box>
            {data.Items?.length > 0 && <ChartDetail data={data} chartParams={chartParams} isMobile={isMobile} />}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

Chart.propTypes = {
  params: PropTypes.object.isRequired,
  chartParams: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Chart;