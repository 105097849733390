import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import Menu from "../../coraWebMComponents/dataDisplay/Menu";
import MoreVertIcon from "../../coraWebMComponents/dataDisplay/icons/MoreVertIcon";

import OptionsButtonMenuItem from "./OptionsButtonMenuItem";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  riRoot: {
    display: "flex",
    alignItems: "center",
    marginRight: "0px",
  },
  riBtn: {
    padding: "0px",
    marginRight: "0px",
    backgroundColor: theme.palette.secondary.light,
    // backgroundColor: alpha(theme.palette.common.black, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
  },
  riIcon: {},
  rim: {},
  rimItem: {},
  rimItemIcon: {},
  rimText: {
    fontSize: theme.palette.secondary.fontSize,
  },
  requiredColor: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
});

// const OptionsButtonComponent = ({ classes, anchorOrigin, transformOrigin, items, customColor }) => {
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   let ii = 0;
export class OptionsButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  //  VertIcon = this.props.IconComponent;

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const anchorOrigin = this.props.anchorOrigin ?? {
      vertical: "top",
      horizontal: "right",
    };
    const transformOrigin = this.props.transformOrigin ?? {
      vertical: "top",
      horizontal: "right",
    };
    const showMenu = this.props.items?.length > 0;

    let ii = 0;
    return (
      <div className={classNames(classes.riRoot)}>
        <IconButton
          className={classNames(
            classes.riBtn,
            this.props.customColor && classes.requiredColor
          )}
          onClick={this.handleMenu}
          color="inherit"
          toolTip="Viac"
          toolTipPlace="left"
          disabled={!showMenu ? true : false}
        >
          <MoreVertIcon className={classNames(classes.riIcon)} />
        </IconButton>
        {
          showMenu && <Menu
            className={classNames(classes.rim)}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {this.props.items.map((x) => (
              <OptionsButtonMenuItem
                key={++ii}
                classes={classes}
                itemText={x.itemText}
                itemIcon={x.itemIcon}
                onClick={() => this.setState({ anchorEl: null }, x.onClick())}
                disabled={x.disabled}
              />
            ))}
          </Menu>
        }

      </div>
    );
  }
}

OptionsButtonComponent.propTypes = {
  id: PropTypes.number,
  anchorOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  transformOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      itemText: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default withStyles(styles)(OptionsButtonComponent);
