import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';

class CheckCircleIconComponent extends React.Component {
  render() {
    return (
      <CheckCircle
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

CheckCircleIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}

export default CheckCircleIconComponent;
