import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SortDirection, AutoSizer /*, SortIndicator*/ } from "react-virtualized";

import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import IconButton from "../../coraWebMComponents/inputs/IconButton";

import MobileGridItem from "./MobileGridItem";
import VirtualizedTable from "./VirtualizedTable";
import { getGridVisibleColumns, buildValue } from "./support";
import GridTitle from "./GridTitle";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  mobileGrid: {
    marginTop: "10px",
    overflowY: "auto",
    width: "100%",
    // height: '766px',
    // height: "100vh",
    // flexGrow: 1
    boxSizing: "border-box",
  },
  grid: {
    overflow: "auto",
    width: "100%",
    // height: '766px',
    // cela vyska - sirka top banera - rezerva na hearer
    height: "calc(100vh - 65px - 80px)",
    // flexGrow: 1
    // padding: "10px",
    marginTop: "10px",
    borderColor: theme.palette.grey[theme.palette.type === "dark" ? 800 : 200],
    boxSizing: "border-box"
  },
  riBtn: {
    margin: 0,
    padding: 0,
    marginTop: 0,
    marginLeft: "0.3rem",
    marginRight: 1,
    // backgroundColor: theme.palette.secondary.light,
    backgroundColor: alpha(theme.palette.common.white, 1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    borderRadius: theme.shape.borderRadius,
    width: 24,
    boxSizing: "border-box"
  },
  riIcon: {
    color: alpha(theme.palette.common.black, 1),
  },
  backIcon: {
    transform: "scaleX(-1)",
  },
  riIconActive: {
    color: alpha(theme.palette.common.black, 1),
  },
  riIconNotActive: {
    color: alpha(theme.palette.common.black, 0.25),
    border: "1px solid #ced4da",
    borderRadius: theme.shape.borderRadius,
  },
  riBothIcons: {
    display: "flex",
    justifyContent: "right",
  },
});

const MobileGrid = ({
  primaryField,
  schema,
  rows,
  gridRowOptions,
  classes,
  onScrollEnd,
  isMobile
}) => {
  const pkFieldName = primaryField.split('-').pop();
  const columns = getGridVisibleColumns(schema.Grid, false);
  const customUI = schema.Args;
  const formatMask =
    customUI && customUI.detail && customUI.detail.label
      ? customUI.detail.label
      : schema.NFrm;

  return (
    <Box
      className={classNames("container w-padding", classes.mobileGrid)} onScroll={onScrollEnd}>
      <GridTitle isMobile={isMobile} frmData={schema.Args} />
      {rows.map((rowData) => {
        const pkValue = rowData[pkFieldName];
        return (
          <MobileGridItem
            key={pkValue}
            rowData={rowData}
            columns={columns}
            rowTitle={formatMask}
            pkValue={pkValue}
            createOptionsButton={gridRowOptions.createBodyButton}
          />
        );
      })}
    </Box>
  );
};

const WebGrid = ({
  _primaryField,
  schema,
  rows,
  gridRowOptions,
  onSortClick,
  classes,
  onScrollEnd,
  handleDetailDialogOpen,
  isMobile,
  frmState
}) => {
  const [state, setState] = useState({ firstColumn: 0 });
  const [sort, setSort] = useState({
    sortBy: frmState.sort[0]?.field,
    sortDirection: frmState.sort[0]?.dir
  });
  const refGridTitle = useRef(null);
  const [gridTitleHeight, setGridTitleHeight] = useState(0);

  const updateHeight = () => {
    if (refGridTitle.current) {
      const height = refGridTitle.current.offsetHeight;
      setGridTitleHeight(height);
    }
  };

  // pri kazdom renderingu zaktualizuj vysku
  useEffect(() => {
    updateHeight();
  });

  const columns = getGridVisibleColumns(schema.Grid, true);

  const createPreviousButton = (isActive) => {
    return (
      <IconButton
        className={classNames(classes.riBtn)}
        disabled={state.firstColumn <= 0}
        onClick={() => setState({ firstColumn: state.firstColumn - 1 })}
        toolTip="Zobraziť predchádzajúci stĺpec"
      >
        <NavigateNextIcon
          className={classNames(
            classes.riIcon,
            classes.backIcon,
            isActive ? classes.riIconActive : classes.riIconNotActive
          )}
        />
        {/* <ArrowBackIos
          className={classNames(classes.riIcon, classes.backIcon)}
        /> */}
      </IconButton>
    );
  };

  const createNextButton = (isActive) => {
    return (
      <>
        <IconButton
          className={classNames(classes.riBtn)}
          disabled={!isActive}
          onClick={() => setState({ firstColumn: state.firstColumn + 1 })}
          toolTip="Zobraziť nasledujúci stĺpec"
        >
          <NavigateNextIcon
            className={classNames(
              classes.riIcon,
              isActive ? classes.riIconActive : classes.riIconNotActive
            )}
          />

          {/* <ArrowForwardIos className={classNames(classes.riIcon)} /> */}
        </IconButton>
      </>
    );
  };

  const setSort2 = (column) => {
    let { sortBy, sortDirection } = sort;
    if (!sortBy) {
      sortBy = column.name;
      sortDirection = SortDirection.ASC;
    } else if (sortBy !== column.name) {
      sortBy = column.name;
      sortDirection = SortDirection.ASC;
    } else if ((sortDirection ?? SortDirection.ASC) === SortDirection.ASC) {
      sortBy = column.name;
      sortDirection = SortDirection.DESC;
    } else {
      sortBy = null;
      sortDirection = SortDirection.ASC;
    }
    setSort({ sortBy, sortDirection });
    onSortClick(sortBy, sortDirection);
  };

  const colOptions = {
    name: "colOptions",
    header: gridRowOptions.createHeaderButton(),
    cell: (rowData) => gridRowOptions.createBodyButton(rowData),
    cellProps: { style: { paddingRight: 0 } },
    width: 44,
  };

  const createCombinedButton = (hasPrev, hasNext) => (
    <Stack flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"} className={classes.riBothIcons}>
      {createPreviousButton(hasPrev)}
      {createNextButton(hasNext)}
    </Stack>
  );

  const getColumns = (width) => {
    const _SCROLL_BAR_WIDTH = 20;
    const _MIN_WIDTH = 64;
    const _BUTTONS_WIDTH = 110;

    const innerWidth = width - _SCROLL_BAR_WIDTH;

    const { firstColumn } = state;

    const cols = [colOptions];
    let iLen = colOptions.width;

    const beforeCols = [];
    let beforeCol = null;
    const afterCols = [];
    let afterCol = null;

    columns.map((col, index) => {
      if (!col.width) {
        col.width = 160;
      }

      if (index < firstColumn) {
        beforeCols.push(col);
        if (!beforeCol) {
          beforeCol = {
            name: "beforeCol",
            cell: () => "...",
            header: "",
            width: _MIN_WIDTH,
            cellProps: { align: "left" },
          };
          cols.push(beforeCol);
          iLen += beforeCol.width;
        }
      } else if (afterCol || iLen + col.width + _BUTTONS_WIDTH > innerWidth) {
        afterCols.push(col);
        if (!afterCol) {
          afterCol = {
            name: "afterCol",
            header: "",
            cell: () => "...",
            width: _BUTTONS_WIDTH,
            cellProps: { align: "right" },
          };
          cols.push(afterCol);
          iLen += afterCol.width;
        }
      } else {
        const destCol = {
          ...col,
          sortBy: null,
          onHeaderClick: (event, { column }) => {
            setSort2(column);
          },
        };
        cols.push(destCol);
        iLen += destCol.width;
      }
      return col;
    });

    if (afterCol || beforeCol) {
      const hasPrev = beforeCols.length > 0;
      const hasNext = afterCols.length > 0;
      const combinedButtonHeader = createCombinedButton(hasPrev, hasNext);
      let freeSpace;
      if (afterCol) {
        freeSpace = innerWidth - iLen;
      } else {
        freeSpace = innerWidth - iLen - _BUTTONS_WIDTH;
      }
      if (afterCol && beforeCol) {
        const afterLen = Math.floor(freeSpace / 2);
        afterCol.width += afterLen - 2; // HACK: -2 lebo preteka pravy okraj a sposobuje zobrazenie spodneho scroll baru
        const beforeLen = freeSpace - afterLen;
        beforeCol.width += beforeLen - 2; // HACK: -2 lebo preteka pravy okraj a sposobuje zobrazenie spodneho scroll baru
      } else if (afterCol) {
        afterCol.width = Math.max(afterCol.width + freeSpace - 2, _BUTTONS_WIDTH); // HACK: -2 lebo preteka pravy okraj a sposobuje zobrazenie spodneho scroll baru
      } else if (beforeCol) {
        beforeCol.width += freeSpace - 2; // HACK: -2 lebo preteka pravy okraj a sposobuje zobrazenie spodneho scroll baru
      }
      if (!afterCol) {
        afterCol = {
          name: "afterCol",
          header: combinedButtonHeader,
          cell: null,
          width: _BUTTONS_WIDTH,
          cellProps: { align: "right" },
        };
        cols.push(afterCol);
      } else {
        afterCol.header = combinedButtonHeader;
      }
    }

    return cols;
  };

  useEffect(() => {
    const onResize = () => {
      setState({ firstColumn: 0 });
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <Box className={classNames(classes.grid)} >
      <GridTitle classes={classes} isMobile={isMobile} frmData={schema.Args} ref={refGridTitle} />
      <AutoSizer>
        {({ width, height }) => {
          const cols = getColumns(width, height);
          return (
            <VirtualizedTable
              data={rows}
              columns={cols}
              width={width + 0} //2=sirka okraja, cize 2*1px
              height={height - gridTitleHeight} // ma fixed vysku, aby neubiehal vertikalny scrollbar odrata sa gridTitleHeight
              includeHeaders={true}
              fixedRowCount={1}
              rowHeight={32}
              isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) =>
                rowData.id && rowData.id === hoveredRowData.id
              }
              cellProps={(column, rowData) => {
                const res = rowData && rowData.id ? {} : { align: "center" };
                return res;
              }}
              onCellDoubleClick={(event, { rowData }) => {
                handleDetailDialogOpen(rowData["id"]);
              }}
              onScrollEnd={onScrollEnd}
              classes={classes}
              isMobile={isMobile}
              frmData={schema.Args}
              orderBy={sort.sortBy}
              orderDirection={sort.sortDirection}
            />
          );
        }}
      </AutoSizer>
    </Box>
  );
};

const GridComponent = (props) => {
  const {
    classes,
    isMobile,
    primaryField,
    schema,
    frmData,
    onScrollEnd,
    handleDetailDialogOpen,
    frmPodFields
  } = props;
  const pkFieldName = primaryField.split('-').pop();

  const getData = () => {
    //zaznamy pre grid
    let resData = frmData?.data?.map((rowData) => {
      const pkValue = rowData[pkFieldName];
      const row = { ...rowData, id: pkValue };
      schema.Grid.map((col) => {
        row[col.field] = buildValue(rowData, col);
        return col;
      });
      return row;
    });
    // let resData = rows;
    if (window.config.isDev && frmPodFields?.length > 0) {
      // const frmPodFields = Object.keys(devFlt).filter(x => devFlt[x] === true).map(x => x);
      resData = resData.filter(x => frmPodFields.every(z => x.hasOwnProperty(z) && x[z].total > 0));
    }
    return resData;
  };
  const rows = React.useMemo(getData, [frmData.data, frmPodFields]);

  return (
    <>
      {!isMobile && (
        <WebGrid
          primaryField={primaryField}
          schema={schema}
          rows={rows}
          gridRowOptions={props.gridRowOptions}
          onSortClick={props.onSortClick}
          classes={classes}
          onScrollEnd={onScrollEnd}
          handleDetailDialogOpen={handleDetailDialogOpen}
          isMobile={isMobile}
          frmState={props.frmState}
        />
      )}
      {isMobile && (
        <MobileGrid
          primaryField={primaryField}
          schema={schema}
          rows={rows}
          gridRowOptions={props.gridRowOptions}
          classes={classes}
          onScrollEnd={onScrollEnd}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

GridComponent.propTypes = {
  classes: PropTypes.object,
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  frmData: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  gridRowOptions: PropTypes.object.isRequired,
  onSortClick: PropTypes.func.isRequired,
  onScrollEnd: PropTypes.func.isRequired,
  handleDetailDialogOpen: PropTypes.func.isRequired,
  frmState: PropTypes.object.isRequired,
  frmPodFields: PropTypes.array
};

export default withStyles(styles)(GridComponent);
