import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';

import { getParkZoneTimes } from '../../utils/parkingUtils';
import MapDialogGrid from './ZoneMapDialogGrid';

const styles = theme => ({
  ...getSharedStyles(theme),
});

const ZoneMapDialog = ({ open, onClose, focusedPolygon, classes }) => {
  const [parkZoneTimes, setParkZoneTimes] = useState(null);

  useEffect(() => {
    focusedPolygon && getParkZoneTimes(focusedPolygon.key).then((data) => {
      if (!data.Data) {
        setParkZoneTimes([
          { SKR_DEN: "PO", DEN: 1 },
          { SKR_DEN: "UT", DEN: 2 },
          { SKR_DEN: "ST", DEN: 3 },
          { SKR_DEN: "ŠT", DEN: 4 },
          { SKR_DEN: "PI", DEN: 5 },
          { SKR_DEN: "SO", DEN: 6 },
          { SKR_DEN: "NE", DEN: 7 }]);
      }
      else {
        setParkZoneTimes(data.Data.Items);
      }
    });
    !open && setParkZoneTimes(null);
  }, [focusedPolygon, open]);

  return (focusedPolygon && parkZoneTimes) ? (
    <Dialog
      open={open}
      onClose={onClose}
      title={focusedPolygon.title}
      titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
      actions={[]}
      contentClasses={{ root: classes.textCenter }}
      content={parkZoneTimes && <MapDialogGrid times={parkZoneTimes} id={focusedPolygon.key} tooltip={focusedPolygon.toolTip} />}
      closeButton={<div style={{ textAlign: 'right' }}><IconButton size='small' onClick={onClose} ><CloseIcon fontSize='small' /></IconButton></div>}
      titleStyle={{ paddingTop: 0 }}
    />
  ) : null;
};

ZoneMapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  focusedPolygon: PropTypes.shape({
    coordinates: PropTypes.array,
    color: PropTypes.string,
    key: PropTypes.number,
    title: PropTypes.string,
    toolTip: PropTypes.string,
  }),
};

export default (withStyles(styles)(ZoneMapDialog));