import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class OrganizationIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M11,16.3
		c0,0.4-0.3,0.8-0.8,0.8H8.8C8.3,17,8,16.7,8,16.3v-1.5C8,14.3,8.3,14,8.8,14h1.5c0.4,0,0.8,0.3,0.8,0.8V16.3z"/>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M12,5.8
		C12,6.4,11.4,7,10.8,7H8.3C7.6,7,7,6.4,7,5.8V3.3C7,2.6,7.6,2,8.3,2h2.5C11.4,2,12,2.6,12,3.3V5.8z"/>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M17,16.3
		c0,0.4-0.3,0.8-0.8,0.8h-1.5c-0.4,0-0.8-0.3-0.8-0.8v-1.5c0-0.4,0.3-0.8,0.8-0.8h1.5c0.4,0,0.8,0.3,0.8,0.8V16.3z"/>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M5,16.3
		C5,16.7,4.7,17,4.3,17H2.8C2.3,17,2,16.7,2,16.3v-1.5C2,14.3,2.3,14,2.8,14h1.5C4.7,14,5,14.3,5,14.8V16.3z"/>
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="9.5" y1="8" x2="9.5" y2="13" />
          <path fill="none" strokeLinejoin="round" strokeMiterlimit="10" d="M3.5,14v-2.5c0-0.6,0.4-1,1-1h10
		c0.6,0,1,0.4,1,1V14"/>
        </g>
      </SvgIcon>
    );
  }
}

OrganizationIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default OrganizationIconComponent;
