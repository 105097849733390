import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

/**
 * Progress component
 * @module
 * @param {number, func} value - value of progress
 * @param {string} color - 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
 */
class Progress extends React.Component {
  render() {
      return <LinearProgress 
                variant="determinate" 
                value={this.props.value} 
                style={this.props.style}
                color={this.props.color}/>
    
  }
}

Progress.propTypes = {  
}

export default Progress;