import React from 'react';
import { connect } from 'react-redux';
import isNil from "lodash/isNil";
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import withWidth from '@material-ui/core/withWidth';

import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import Button from '../../coraWebMComponents/inputs/Button';
import TextField from '../../coraWebMComponents/inputs/TextField';
import SelectFilterField from '../../coraWebMComponents/inputs/SelectFilterField';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import CustomSwitch from '../../coraWebMComponents/inputs/CustomSwitch';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Switch from '../../coraWebMComponents/inputs/Switch';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import CheckBox from '../../coraWebMComponents/inputs/CheckBox';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import { isValidEcv } from '../../coraWebMComponents/utils/validator';
import withRouter from '../../coraWebMComponents/utils/withRouter';

import routes from '../../routes';
import InfoDialog from '../info/InfoDialog';
import * as userActions from '../../actions/userActions';
import * as accountActions from '../../actions/accountActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as publicActions from '../../actions/publicZone/publicActions';

const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _autocompleteTimeDelay = window.config.autocompleteTimeDelayInMs;

/** ID projektu  4003 - Parkovanie */
const _PrjId = 4003;

/** Zdroj platby 1 - Parkovacia karta */
const _PayType = 1;

const _currencyCode = 'EUR';

const styles = theme => ({
  ...getSharedStyles(theme),
  paperCard: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '15px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '30px',
    },
  },
  payBtn: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px'
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  }
});

let _iVer = 0;
class ParkCard extends React.Component {
  constructor(props) {
    super(props);
    var lang = '{ "languages" : [' +
      '{ "LANGUAGE":"sk-SK" , "N_LANGUAGE":"Slovenčina" },' +
      '{ "LANGUAGE":"en-US" , "N_LANGUAGE":"English" },' +
      '{ "LANGUAGE":"de-DE" , "N_LANGUAGE":"Deutsch" } ]}';

    const listLanguage = JSON.parse(lang);

    this.state = {
      isLoad: true,
      activeStep: 1,
      tabs: 0,
      I_O_TYP: 1,
      EMAIL: '',
      PF: '',
      MENO: '',
      ICO: '',
      DIC: '',
      L_DPH: false,
      IC_DPH: '',
      krajina: {
        I_STAT: 1,
        N_STAT: 'Slovenská republika'
      },
      N_M: '',
      N_U: '',
      CS_CO: '',
      ECV: '',
      I_KARTA_TYP: null,
      cardType: {
        I_KARTA_TYP: null,
        N_KARTA_TYP: ''
      },
      CENA: 0,
      LANGUAGE: 'sk-SK',
      listLanguage: listLanguage.languages,
      gdpr: false,
      openGdpr: false,
      textGdpr: '',
      conditions: false,
      openConditions: false,
      textConditions: '',
      errorTextEcv: '',
      errorTextCardType: '',
      errorTextPf: '',
      errorTextMeno: '',
      errorTextIco: '',
      errorTextDic: '',
      errorTextIcdph: '',
      errorTextKrajina: '',
      errorTextNm: '',
      errorTextCsco: '',
      textTypPopis: '',
    };
  }

  componentDidMount = async () => {

    if (!this.props.paymentSettings.isLoaded) {
      await this.props.dispatch(ciselnikyActions.getPaymentSettings());
    }

    if (!this.props.adminMode.isActive) {
      const search = window.location.href.split('?').pop();
      const params = new URLSearchParams(search);
      _iVer = params.get("iVer");

      if (_iVer === null || _iVer === 0)
        return;

      await this.props.dispatch(accountActions.UpdVerifyEmail(_iVer));
      await this.props.dispatch(accountActions.GetVerifyEmail(_iVer));
      await this.props.dispatch(publicActions.getListCardTypeEShop(this.state.I_O_TYP));
    }


    if (!this.props.urlGdpr)
      await this.props.dispatch(ciselnikyActions.getUrlGdpr());

    if (!this.props.urlConditionsPC)
      await this.props.dispatch(ciselnikyActions.getUrlConditionsPC());

    const txtGdpr = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_GDPR');
    const txtConditions = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PZ_PARK_CARD');
    this.setState({
      isLoad: false,
      LANGUAGE: this.props.locale,
      EMAIL: this.props.verifyEmail.EMAIL,
      textGdpr: txtGdpr,
      textConditions: txtConditions,
    });
  };

  handleChange = async (name, value) => {
    switch (name) {
      case 'LANGUAGE':
        await this.props.dispatch(userActions.setLocale(value.trim()));
        break;
      case 'ECV':
        value = value.toUpperCase();
        value = value.trim();
        this.setState({ errorTextEcv: value ? isValidEcv(value, this.state.LANGUAGE) : this.props.translate('ECV') + this.props.translate('isRequired') });
        break;
      case 'I_KARTA_TYP':
        const cena = value ? this.getCena(value) : 0;
        this.setState({
          I_KARTA_TYP: value, CENA: cena,
          errorTextCardType: value ? '' : this.props.translate('parkCard.cardType') + this.props.translate('isRequired'),
          textTypPopis: this.GetTypPopis(value)
        });
        break;
      case 'CS_CO':
        if (value.length > 16)
          value = value.substr(0, 16);

        this.setState({ errorTextCsco: value ? '' : this.props.translate('csco') + ' ' + this.props.translate('isRequired') });
        break;
      case 'DIC':
        if (value.length > 15)
          value = value.substr(0, 15);

        this.setState({ errorTextDic: this.state.I_O_TYP === 2 && !value ? this.props.translate('dic') + ' ' + this.props.translate('isRequired') : '' });
        break;
      case 'IC_DPH':
        if (value.length > 15)
          value = value.substr(0, 15);

        this.setState({ errorTextIcdph: this.state.I_O_TYP === 2 && this.state.L_DPH && !value ? this.props.translate('icDph') + ' ' + this.props.translate('isRequired') : '' });
        break;
      case 'ICO':
        if (value.length > 11)
          value = value.substr(0, 11);

        this.setState({ errorTextIco: this.state.I_O_TYP === 2 && !value ? this.props.translate('ico') + ' ' + this.props.translate('isRequired') : '' });
        break;
      case 'MENO':
        if (value.length > 40)
          value = value.substr(0, 40);

        this.setState({ errorTextMeno: this.state.I_O_TYP === 1 && !value ? this.props.translate('name') + ' ' + this.props.translate('isRequired') : '' });
        break;
      case 'PF':
        if (value.length > 100)
          value = value.substr(0, 100);

        this.setState({ errorTextPf: value ? '' : this.props.translate(this.state.I_O_TYP === 1 ? 'lastName' : 'businessName') + ' ' + this.props.translate('isRequired') });
        break;
      case 'krajina':
        this.setState({ errorTextKrajina: value ? '' : this.props.translate('country') + ' ' + this.props.translate('isRequired') });
        break;
      case 'N_U':
        if (value.length > 40)
          value = value.substr(0, 40);
        break;
      case 'N_M':
        if (value.length > 50)
          value = value.substr(0, 50);

        this.setState({ errorTextNm: value ? '' : this.props.translate('city') + ' ' + this.props.translate('isRequired') });
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  getCena = (iKartaTyp) => {
    var first = this.props.listCardTypeEShop.findIndex(myFunction);
    function myFunction(value) {
      return value.I_KARTA_TYP === iKartaTyp;
    }

    return this.props.listCardTypeEShop[first].CENA;
  };

  GetTypPopis = (iKartaTyp) => {
    let retTxt = null;
    if (this.props.listCardTypeEShop.length > 0) {
      this.props.listCardTypeEShop.forEach(getPopis);

      function getPopis(value) {
        if (value.I_KARTA_TYP === iKartaTyp)
          retTxt = value.POPIS;

      }
    }
    return retTxt;
  };

  handleChangeTypOs = (event, value) => {
    this.setState({
      tabs: value,
      I_O_TYP: value === 0 ? 1 : 2,
      ICO: '',
      DIC: '',
      MENO: '',
      PF: '',
      L_DPH: false,
      IC_DPH: '',
      errorTextPf: '',
      errorTextMeno: '',
      errorTextIco: '',
      errorTextDic: '',
      errorTextIcdph: '',
      errorTextKrajina: '',
      errorTextNm: '',
      errorTextCsco: ''
    });

    this.props.dispatch(publicActions.getListCardTypeEShop(value === 0 ? 1 : 2));
  };

  handleSearchCountries = (searchText) => {
    this.props.dispatch(ciselnikyActions.getListCountries(searchText || ''));
  };

  handleNext = () => {
    this.setState(state => ({ activeStep: state.activeStep + 1 }));
  };

  handleBack = () => {
    if (this.state.activeStep === 1)
      if (this.props.adminMode.isActive) {
        this.props.navigate(routes.PZ_PARKING_CARD_VERIFY, { state: { adminModeActive: true } });
      } else {
        this.props.navigate({ pathname: routes.PRELOGIN });
      }
    else
      this.setState(state => ({ activeStep: state.activeStep - 1 }));
  };

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeGdpr = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleInfoGdpr = () => {
    if (this.props.urlGdpr.HODN)
      window.open(this.props.urlGdpr.HODN, '_blank').focus();
    else
      this.setState({ openGdpr: !this.state.openGdpr });
  };

  handleChangeConditions = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleInfoConditions = () => {
    if (this.props.urlConditionsPC.HODN)
      window.open(this.props.urlConditionsPC.HODN, '_blank').focus();
    else
      this.setState({ openConditions: !this.state.openConditions });
  };

  get paymentGatewayHandler() { return this.props.paymentSettings.settings?.getPaymentGatewayHandler(_PrjId); }

  AddParkCard = async () => {
    this.setState({ isLoad: true });
    const I_karta_typ = this.state.I_KARTA_TYP;
    const errorTextEcv = this.state.ECV ? isValidEcv(this.state.ECV, this.state.LANGUAGE) : this.props.translate('ECV') + this.props.translate('isRequired');
    const errorTextCardType = I_karta_typ ? '' : this.props.translate('parkCard.cardType') + this.props.translate('isRequired');

    const errorTextPf = this.state.PF ? '' : this.props.translate(this.state.I_O_TYP === 1 ? 'lastName' : 'businessName') + ' ' + this.props.translate('isRequired');
    const errorTextMeno = this.state.I_O_TYP === 1 && !this.state.MENO ? this.props.translate('name') + ' ' + this.props.translate('isRequired') : '';
    const errorTextIco = this.state.I_O_TYP === 2 && !this.state.ICO ? this.props.translate('ico') + ' ' + this.props.translate('isRequired') : '';
    const errorTextDic = this.state.I_O_TYP === 2 && !this.state.DIC ? this.props.translate('dic') + ' ' + this.props.translate('isRequired') : '';
    const errorTextIcdph = this.state.I_O_TYP === 2 && this.state.L_DPH && !this.state.IC_DPH ? this.props.translate('icDph') + ' ' + this.props.translate('isRequired') : '';
    const errorTextKrajina = this.state.krajina ? '' : this.props.translate('country') + ' ' + this.props.translate('isRequired');
    const errorTextNm = this.state.N_M ? '' : this.props.translate('city') + ' ' + this.props.translate('isRequired');
    const errorTextCsco = this.state.CS_CO ? '' : this.props.translate('csco') + ' ' + this.props.translate('isRequired');

    this.setState({ errorTextEcv, errorTextCardType, errorTextPf, errorTextMeno, errorTextIco, errorTextDic, errorTextIcdph, errorTextKrajina, errorTextNm, errorTextCsco });

    if (!errorTextEcv &&
      !errorTextCardType &&
      !errorTextPf &&
      !errorTextMeno &&
      !errorTextIco &&
      !errorTextDic &&
      !errorTextIcdph &&
      !errorTextKrajina &&
      !errorTextNm &&
      !errorTextCsco) {

      let isOK = false;
      let dataPay;
      let parkingCard = await this.props.dispatch(publicActions.addParkCard(this.state));

      if (parkingCard && parkingCard.I_KARTA && parkingCard.VS) {

        isOK = true;

        //TODO: VW_KP_KARTA_LIGHT bude vracat aj udaje ECV a C_KARTA, zatial ECV naplnim zo state
        parkingCard.ECV = this.state.ECV;

        const gatewayHandler = this.paymentGatewayHandler;

        // už iba hodnota 1 (0 pre pôvodné riešenie sa už nepoužíva)
        const payMethod = gatewayHandler.payMethod;

        // povodne riesenie
        dataPay = { VS: parkingCard.VS, CENA: this.state.CENA, LANG: this.state.LANGUAGE.substring(0, 2), EMAIL: this.state.EMAIL };

        const prjId = _PrjId;
        const payType = _PayType;
        const iPk = parkingCard.I_KARTA;
        const vs = dataPay.VS;
        const language = this.state.LANGUAGE.substring(3, 5);
        const email = dataPay.EMAIL;

        // sprievodka
        const waybill = { payMethod, prjId, payType, iPk, vs, language, email, 'transactionId': null };

        // udaje pre platbu
        const payData = { 'items': [], 'totalAmount': 0, 'totalAmountDec': 0, currencyCode: _currencyCode };

        let name = `Parkovacia karta`;
        if (parkingCard.C_KARTA)
          name += ` č. ${parkingCard.C_KARTA}`;
        if (parkingCard.ECV)
          name += ` pre EČV ${parkingCard.ECV}`;

        const amountDec = Number(dataPay.CENA);
        payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

        const amount = amountDec * 100;
        payData.totalAmount = Number(payData.totalAmount) + amount;

        payData.items.push({ name, amount, amountDec });

        if (!isNil(gatewayHandler.minSum) && gatewayHandler.minSum > 0.00) {
          if (payData.totalAmountDec < gatewayHandler.minSum) {
            this.props.dispatch(userActions.showErrorMsg('Nepostačujúca suma pre realizáciu platby.'));
            this.setState({ isLoad: false });
            return;
          }
        }

        dataPay = { ...dataPay, waybill, payData };

        if (payData.totalAmount <= 0)
          isOK = false;
      }
      if (isOK) {
        this.props.navigate(routes.PAYMENT_GATEWAY, { state: dataPay });
      } else {
        this.props.dispatch(userActions.showErrorMsg(this.props.translate('parkCard.failure')));
      }

    } else {
      let _activeStep = 2;

      if (errorTextPf || errorTextMeno || errorTextIco || errorTextDic || errorTextIcdph || errorTextKrajina || errorTextNm || errorTextCsco)
        _activeStep = 1;

      this.setState({ isLoad: false, activeStep: _activeStep });

    }
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { classes, width } = this.props;
    return (
      <div className='container' style={{ marginTop: this.isMobile(width) ? '60px' : '70px' }}>
        <CircularProgress loading={this.state.isLoad} />
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 3} >
          <div className={classNames(classes.row, classes.navigationBackButton, classes.alignItemsCenter, classes.mb2)}>
            <IconButton
              className={classNames(classes.backButton, classes.ml1, classes.mr6)}
              color="inherit"
              onClick={this.handleBack}
              toolTip={this.props.translate('back')}
              toolTipPlace="right"
            >
              <ArrowBack
                variant='fab'
                style={{ fontSize: 30 }}
              />
            </IconButton>
            <Typography variant="body1" className={classNames(classes.ml6)} style={{ opacity: this.state.activeStep === 3 ? "0" : "1" }}><b>{this.state.activeStep}</b>/2</Typography>
            <div >
              <SelectFilterField
                floatingLabelText="Výber jazyka"
                required={true}
                value={this.state.LANGUAGE}
                onChange={this.handleChange}
                fullwidth={true}
                schema={{ label: 'N_LANGUAGE', value: 'LANGUAGE' }}
                data={this.state.listLanguage}
                name='LANGUAGE'
              />
            </div>
          </div>
          {(this.state.activeStep === 1 || this.state.activeStep === 3) &&
            <div >
              <Typography variant="subtitle2" className={classNames(classes.mb2, classes.textCenter)}>
                {this.props.translate('parkCard.subjectType')}
              </Typography>
              {this.state.activeStep !== 3 ? <div className={classNames(classes.row, classes.justifyContentCenter, classes.mb4)}>
                <CustomSwitch
                  disabled={this.state.activeStep === 3}
                  value={this.state.tabs}
                  onChange={this.handleChangeTypOs}
                  onLabel={this.props.translate('parkCard.subjectTypeFO')}
                  offLabel={this.props.translate('parkCard.subjectTypePO')}
                  indicatorClasses={classes.customSwitchIndicator}
                  mobileFullWidth={true}
                />
              </div> : <div>
                <Typography variant="body1" className={classNames(classes.mb2, classes.textCenter)}>
                  {this.state.I_O_TYP === 1 ? this.props.translate('parkCard.subjectTypeFO') : this.props.translate('parkCard.subjectTypePO')}
                </Typography>
              </div>}
              <Typography variant="subtitle2" className={classNames(classes.mb3, classes.textCenter)}>
                {this.props.translate('parkCard.billingInfo')}
              </Typography>
              {this.state.I_O_TYP === 1 &&
                <div >
                  <TextField
                    disabled={this.state.activeStep === 3}
                    label={this.props.translate('name')}
                    errorText={this.state.errorTextMeno}
                    error={this.state.errorTextMeno ? true : false}
                    value={this.state.MENO}
                    onChange={this.handleChange}
                    name='MENO'
                    fullwidth={true}
                    className={classNames(classes.mb2)}
                    variant="standard"
                  />
                  <TextField
                    disabled={this.state.activeStep === 3}
                    label={this.props.translate('lastName')}
                    errorText={this.state.errorTextPf}
                    error={this.state.errorTextPf ? true : false}
                    value={this.state.PF}
                    onChange={this.handleChange}
                    name='PF'
                    fullwidth={true}
                    className={classNames(classes.mb2)}
                    variant="standard"
                  />
                </div>
              }
              {this.state.I_O_TYP === 2 &&
                <div >
                  <TextField
                    disabled={this.state.activeStep === 3}
                    fullwidth={true}
                    label={this.props.translate('businessName')}
                    errorText={this.state.errorTextPf}
                    error={this.state.errorTextPf ? true : false}
                    value={this.state.PF}
                    onChange={this.handleChange}
                    name='PF'
                    className={classNames(classes.mb2)}
                    variant="standard"
                  />
                  <TextField
                    disabled={this.state.activeStep === 3}
                    label={this.props.translate('ico')}
                    errorText={this.state.errorTextIco}
                    error={this.state.errorTextIco ? true : false}
                    fullwidth={true}
                    value={this.state.ICO}
                    onChange={this.handleChange}
                    className={classNames(classes.mb2)}
                    name='ICO'
                    variant="standard"
                  />
                  <TextField
                    disabled={this.state.activeStep === 3}
                    fullwidth={true}
                    label={this.props.translate('dic')}
                    errorText={this.state.errorTextDic}
                    error={this.state.errorTextDic ? true : false}
                    value={this.state.DIC}
                    onChange={this.handleChange}
                    name='DIC'
                    className={classNames(classes.mb2)}
                    variant="standard"
                  />
                  <Switch
                    disabled={this.state.activeStep === 3}
                    color='default'
                    checked={this.state.L_DPH}
                    onChange={this.handleChangeSwitch('L_DPH')}
                    value='L_DPH'
                    label={(this.state.L_DPH ? this.props.translate('platca') : this.props.translate('neplatca')) + this.props.translate('dph')}
                  />
                  {this.state.L_DPH &&
                    <div >
                      <TextField
                        disabled={this.state.activeStep === 3}
                        label={this.props.translate('icDph')}
                        errorText={this.state.errorTextIcdph}
                        error={this.state.errorTextIcdph ? true : false}
                        value={this.state.IC_DPH}
                        onChange={this.handleChange}
                        name='IC_DPH'
                        fullwidth={true}
                        className={classNames(classes.mb2)}
                        variant="standard"
                      />
                    </div>
                  }
                </div>
              }
              <Autocomplete
                disabled={this.state.activeStep === 3}
                errorText={this.state.errorTextKrajina}
                error={this.state.errorTextKrajina ? true : false}
                data={this.props.listCountries}
                schema={{ label: 'N_STAT', value: 'I_STAT' }}
                onChange={this.handleSearchCountries}
                onSelect={this.handleChange}
                selectedItem={this.state.krajina}
                label={this.props.translate('country')}
                name='krajina'
                fullWidth={true}
                countCharStart={_autocompleteCountCharStart}
                timeDelay={_autocompleteTimeDelay}
              />
              <TextField
                disabled={this.state.activeStep === 3}
                label={this.props.translate('city')}
                errorText={this.state.errorTextNm}
                error={this.state.errorTextNm ? true : false}
                fullwidth={true}
                value={this.state.N_M}
                onChange={this.handleChange}
                name='N_M'
                className={classNames(classes.mb2)}
                variant="standard"
              />
              <TextField
                disabled={this.state.activeStep === 3}
                label={this.props.translate('street')}
                fullwidth={true}
                value={this.state.N_U}
                onChange={this.handleChange}
                name='N_U'
                className={classNames(classes.mb2)}
                variant="standard"
              />
              <TextField
                disabled={this.state.activeStep === 3}
                label={this.props.translate('csco')}
                errorText={this.state.errorTextCsco}
                error={this.state.errorTextCsco ? true : false}
                fullwidth={true}
                value={this.state.CS}
                onChange={this.handleChange}
                name='CS_CO'
                className={classNames(classes.mb6)}
                variant="standard"
              />
              {this.state.activeStep !== 3 && <div className='center' style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Button
                  color="primary"
                  aria-label="Ďalej"
                  onClick={this.handleNext}
                  style={{ backgroundColor: 'transparent!important' }}
                >
                  <img style={{ width: 90, height: 90 }} src={'assets/arrow_forward-white.svg'} alt='Ďalej' />
                </Button>
              </div>}
            </div>
          }

          {(this.state.activeStep === 2 || this.state.activeStep === 3) &&
            <div >
              <Typography variant="subtitle2" className={classNames(classes.mb3, classes.mt3, classes.textCenter)}>
                {this.props.translate('parkCard.card')}
              </Typography>
              <TextField
                disabled={this.state.activeStep === 3}
                label={this.props.translate('ECV')}
                fullwidth={true}
                value={this.state.ECV}
                errorText={this.state.errorTextEcv}
                error={this.state.errorTextEcv ? true : false}
                onChange={this.handleChange}
                name='ECV'
                className={classNames(classes.mb3)}
                imputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                      <CarIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <SelectField
                disabled={this.state.activeStep === 3}
                errorText={this.state.errorTextCardType}
                error={this.state.errorTextCardType ? true : false}
                floatingLabelText={this.props.translate('parkCard.cardType')}
                value={this.state.I_KARTA_TYP}
                onChange={this.handleChange}
                fullwidth={true}
                schema={{ label: 'N_KARTA_TYP', value: 'I_KARTA_TYP' }}
                data={this.props.listCardTypeEShop}
                name='I_KARTA_TYP'
                className={classNames(classes.mb3, classes.mt2)}
              />
              {this.state.textTypPopis &&
                <Typography variant="caption" className={classNames(classes.mt3, classes.mb3, classes.textCenter)}>{this.state.textTypPopis}</Typography>
              }
              <div>
                <CheckBox
                  disabled={this.state.activeStep === 3}

                  toolTip={this.state.textGdpr}
                  toolTipPlace="bottom-start"
                  color='secondary'
                  checked={this.state.gdpr}
                  onChange={this.handleChangeGdpr('gdpr')}
                  value='gdpr'
                  label={this.props.translate('agree') + this.props.translate('processing')}
                  style={{ marginLeft: '3px', marginRight: '3px', width: '0px' }}
                  styleLabel={{ marginLeft: '1px', marginRight: '3px' }}
                />
                <Button
                  onClick={this.handleInfoGdpr}
                  style={{ padding: '0px 0px', minWidth: '0px', textTransform: 'none', }}
                >
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>{this.props.translate('personalData')}</Typography>
                </Button>
              </div>
              <div className={classNames(classes.row, classes.justifyContentStart, classes.mb6)}>
                <CheckBox
                  disabled={this.state.activeStep === 3}

                  toolTip={this.state.textConditions}
                  toolTipPlace="bottom-start"
                  color='secondary'
                  checked={this.state.conditions}
                  onChange={this.handleChangeConditions('conditions')}
                  value='conditions'
                  label={this.props.translate('agree') + this.props.translate('with')}
                  style={{ marginLeft: '3px', marginRight: '3px', width: '0px' }}
                  styleLabel={{ marginLeft: '1px', marginRight: '3px' }}
                />
                <Button
                  onClick={this.handleInfoConditions}
                  style={{ padding: '0px 0px', minWidth: '0px', textTransform: 'none', }}
                >
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>{this.props.translate('conditions')}</Typography>
                </Button>
                <Typography variant="body1" className={classNames(classes.ml1, classes.alignSelfCenter)}>{this.props.translate('issuing')}</Typography>
              </div>
              <div className={classNames(classes.col, classes.alignItemsCenter, classes.ml4, classes.mr4, classes.mb4)} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                {this.state.activeStep !== 3 && <RaisedButton style={{ width: '100%', padding: '13px 30px', border: "1px solid grey", backgroundColor: "white", marginBottom: "10px" }} onClick={this.handleNext} color="primary" aria-label="Náhľad">
                  <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                    <Typography variant="button" style={{ color: '#000' }}>Náhľad</Typography>
                    <img src={'assets/nahlad_button.svg'} alt='Náhľad' style={{ width: '40px', height: '40px', color: "black" }} />
                  </div>
                </RaisedButton>}
                <Button
                  disabled={!this.state.conditions || !this.state.gdpr || !this.state.ECV || this.state.CENA <= 0}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classNames(classes.payBtn, classes.w100)}
                  onClick={this.AddParkCard}
                >
                  {this.props.translate('pay')}   {this.state.CENA.toString().replace('.', ',')}€
                </Button>
              </div>
            </div>
          }
        </Paper>
        <InfoDialog
          open={this.state.openGdpr}
          handleClose={this.handleInfoGdpr}
          title={this.props.translate('agree') + this.props.translate('processing') + this.props.translate('personalData')}
          N_KLUC="DM_GDPR"
          currEdited="GDPR"
          I_FRM_TXT={31}
          ID={4004001}
        />
        <InfoDialog
          open={this.state.openConditions}
          handleClose={this.handleInfoConditions}
          title={this.props.translate('parkCard.conditions')}
          N_KLUC="DM_PZ_PARK_CARD"
          currEdited="PZ_PARK_CARD"
          I_FRM_TXT={35}
          ID={4004002}
        />
      </div >
    );
  };
};

const mapStateToProps = (state) => {
  return {
    verifyEmail: state.verifyEmail.data,
    locale: state.locale.locale,
    listCountries: state.listCountries.data,
    listCardTypeEShop: state.listCardTypeEShop.data,
    urlGdpr: state.urlGdpr.data.Data,
    urlConditionsPC: state.urlConditionsPC.data.Data,
    adminMode: state.adminMode,
    paymentSettings: state.paymentSettings
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(ParkCard)))));
