import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { flushSync } from 'react-dom';

import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import * as storage from '../../coraWebMComponents/utils/storage';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as userActions from '../../actions/userActions';
import * as locatorActions from '../../actions/locatorActions';
import * as locatorUtils from '../../utils/locatorUtils';
import routes from '../../routes';
import ZstSlzDetailItem from "./ZstSlzDetailItem";
import ZstZsNavigation from "./ZstZsNavigation";
import ZstSlzNavigation from "./ZstSlzNavigation";
import ZstSlzDetailNavigation from "./ZstSlzDetailNavigation";
import ZstSearch from "./ZstSearch";
import ZstZsList from "./ZstZsList";
import ZstSlzList from "./ZstSlzList";
import ZstDotaznik from "./ZstDotaznik";
import ZstContact from "./ZstContact";
import ZstZsTitle from "./ZstZsTitle";
import ZstSlzTitle from "./ZstSlzTitle";
import ZstSlzDetailTitle from "./ZstSlzDetailTitle";
import ZstActiveServices from "./ZstActiveServices";
import ZstUsedServices from "./ZstUsedServices";
import ZstLastUsedServices from "./ZstUsedServices";

const _RETURN_PATH = "/#/zst";
const _STORAGE_KEYNAME = "SlzFrm";

const styles = theme => ({
  ...getSharedStyles(theme),
  btnZstZs: {
    display: "inline-flex",
    marginRight: `${theme.spacing.unit * 2}px`,
    marginTop: `${theme.spacing.unit * 2}px`,
    width: `calc(33.3333% - 20px)`,
    borderRadius: theme.shape.borderRadius,
    borderColor: "rgb(50, 238, 223)",
  },
  switchedOn: {
    backgroundColor: "rgb(138, 238, 238)",
  },
  switchedOff: {
    backgroundColor: "rgb(255, 255, 255)",
  },
  root: {
    width: `calc(100% - 30px) !important;`
  },
  divZstZs: {
    paddingTop: `${theme.spacing.unit * 2}px`,
    // paddingBottom:`${theme.spacing.unit * 2}px`,
    "&:hover": {
      borderColor: "rgb(155, 229, 204)",
    },
  },
  divZstSlz: {
    paddingTop: `${theme.spacing.unit * 1}px`,
    paddingBottom: `${theme.spacing.unit * 1}px`,
  },
  test: {
    backgroundColor: "#335666"
  },
  textCaption: {
    color: "#78909C"
  },
  textMore: {
    color: "#78909C"
  },
  ePodaniaWrapper: {
    backgroundColor: "#222",
    padding: "1em"
  },
  ePodaniaTextMain: {
    color: "#EEEEEE"
  },
  ePodaniaTextCaption: {
    color: "#9E9E9E"
  },
  ePodaniaLink: {
    color: "#9E9E9E"
  },
  ePodaniaFormLinkWrapper: {
    backgroundColor: "#555",
    padding: "1em",
    marginTop: "1em",
    borderRadius: theme.shape.borderRadius,
    width: "100%"
  },
  eTlacivoWrapper: {
    backgroundColor: "#aaa",
    padding: "1em"
  },
  eTlacivoTextMain: {
    color: "#222"
  },
  eTlacivoTextCaption: {
    color: "#37474F"
  },
  eTlacivoFormLinkWrapper: {
    backgroundColor: "#999",
    padding: "1em",
    marginTop: "1em",
    borderRadius: theme.shape.borderRadius,
  },
  textDecorationNone: {
    textDecoration: 'none'
  },
  boxSizingBorderBox: {
    boxSizing: 'border-box'
  },
  uxTextCaption: {
    color: "#039BE5"
  },
  infoCounter: {
    color: "#424242",
    backgroundColor: "#E1F5FE",
    minWidth: "3em",
    margin: 0,
    padding: ' 0.15em 0.4em 0.15em 0.4em',
    borderRadius: '1em',
  },
});

const getEFormFillerResult = (resultCode) => {
  switch (resultCode) {
    case 1:
      return { code: resultCode, message: "Elektronický formulár bol úspešne odoslaný" };
    case 2:
      return { code: resultCode, message: "Elektronický formulár sa nepodarilo odoslať" };
    case 3:
      return { code: resultCode, message: "Používateľ ukončil proces pred odoslaním elektronického formulára" };
    case 9:
    default:
      return { code: resultCode, message: "Pri práci s elektronickým formulárom sa vyskytla chyba" };
  }
};

/**
 * Hlavny komponen zivotnych situacii
 *
 * @param {*} props
 * @return {*} 
 */
const LifeSituationsComponent = (props) => {
  const theme = useTheme();
  const breakpointMd = theme.breakpoints.up('md');
  const matchesThemeMD = useMediaQuery(breakpointMd);
  // filter
  const [flt, setFlt] = useState({ searchText: "", selectedIDs: [] });
  // uroven detailu pohladu: 1=Agendy zoznam; 2=Sluzby zoznam; 3=Sluzba detail
  const [detailLevel, setDetailLevel] = useState(1);
  // ID služby pre detail
  const [selectedISlz, setSelectedISlz] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // prechod na zoznam sluzieb (Level 1)
  const setLevel1 = (level) => {
    setDetailLevel(level);
    // setFlt({ ...flt, selectedIDs: [] })
  };


  // prechod na zoznam sluzieb (Level 1)
  const setLevel2 = (level) => {
    setDetailLevel(level);
  };

  // inicializačné načítanie dát
  useEffect(() => {
    async function fetchData() {
      // Url CG eGOV
      if (!Boolean(props.egovHandlerUrl)) {
        await props.getEgovHandlerUrl();
      }

      // zoznam životných situácií
      if (!props.listZstZs.isLoaded) {
        await props.getZstZsList();
      }

      // zoznam aktualnych sluzieb
      if (!props.listSlzAkt.isLoaded) {
        await props.getSlzAktList();
      }

      // zoznam najvyhladavanejsich sluzieb
      if (!props.listSlzUsed.isLoaded) {
        await props.getSlzUsedList();
      }

      // zoznam sluzieb naposledy pouzitych userom 
      if (!props.listSlzUsedUser.isLoaded) {
        await props.getSlzUsedUserList();
      }

      // zoznam vsetkych ZS a ich sluzieb
      if (!props.zstZsTree.isLoaded) {
        await props.getZstZsTree();
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {

      // zoznam služieb životných situácií
      await props.getZstSlzList(flt);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flt]);

  useEffect(() => {
    console.debug(`useEffect[location.search="${[props.location.search]}"]`);
    if (props.location.search.length > 0) {

      //výsledok z eFormFillera
      const search = props.location.search.split('?').pop();
      const params = new URLSearchParams(search);
      const resultCode = Number(params.get("resultCode"));
      const eFormFillerResult = getEFormFillerResult(resultCode);

      //načítanie metadát zo session storage
      const metadata = storage.getItem(_STORAGE_KEYNAME) || {};
      storage.removeItem(_STORAGE_KEYNAME);

      if (resultCode === 1) {
        props.dispatch(userActions.showMsg(eFormFillerResult.message));
        props.navigate(routes.ZST_DOT, { state: { metadata, dotType: 1 } });
      } else {
        props.dispatch(userActions.showErrorMsg(eFormFillerResult.message));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  useEffect(() => {
    const stateObj = props.location.state;
    console.debug(`useEffect[location.state=${stateObj}"]`);
    if (Boolean(stateObj)) {

      if (stateObj.isOK) {
        props.dispatch(userActions.showMsg(stateObj.message));
      } else {
        props.dispatch(userActions.showErrorMsg(stateObj.message));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  /** Udalosť Nastavenie SearchFilter */
  const handleSearchFilterChange = async (searchText) => {
    setLevel1(1);
    setFlt({ ...flt, searchText, selectedIDs: [] });
  };

  const handleSelectZsClick = (id) => {
    // let { selectedIDs } = flt;
    // const exist = selectedIDs.includes(id);
    // if (exist) {
    //   selectedIDs = selectedIDs.filter(x => x !== id);
    // } else {
    // selectedIDs.push(id);
    let selectedIDs = [id];
    // }
    setFlt({ ...flt, selectedIDs });
    setDetailLevel(2);
  };

  const handleSelectSlzClick = async (id) => {
    const res = await locatorUtils.NotifyOpenSlz(id);
    console.debug(`NotifyOpenSlz(${id}), result ${res}`);
    await props.getSlzUsedList();
    await props.getSlzUsedUserList();
    setSelectedISlz(id);
    setDetailLevel(3);
  };

  const handleSelectFrmClick = async (slzId, frmId) => {
    setIsLoading(true);
    // ulozenie sprievodky do storage
    const zstSlz = props.listZstSlz.data.find((x) => x.ISlz === slzId);
    const feForm = zstSlz.EFrms.find((x) => x.FeForm.IFeForm === frmId).FeForm;
    storage.setItem(_STORAGE_KEYNAME, { nZstZs: zstSlz.ZstZs.NZstZs, nSlz: zstSlz.NSlz, nFeForm: feForm.NFeForm });

    try {

      // odovzdanie prístupových dát eFormFilleru
      const spOver = await locatorUtils.createSpOver({ user: props.user?.data, person: props.person });

      const egovUrl = props.egovHandlerUrl.data?.Data?.HODN;
      const returnUrl = window.location.origin + _RETURN_PATH;
      const url = `${egovUrl}/EFormCgFiller.ashx?FrmID=${frmId}&returnUrl=${encodeURIComponent(returnUrl)}&SessionID=${spOver.Kod}`;
      window.open(url, '_self').focus();
      // await new Promise((resolve) => setTimeout(resolve, 3000));

    } catch (e) {
      console.error(e);
      props.dispatch(userActions.showErrorMsg(`Pri otváraní elektronického formulára sa vyskytla chyba`));
    }
  };

  const handleDotClick = () => {
    const state = { resultCode: 0, zstSlz: {}, feForm: {}, dotType: 0 };
    props.navigate(routes.ZST_DOT, { state });
  };

  const handleInfoBanerLinkClick = async (idSlz, idZs) => {
    async function fetchData() {
      // zoznam služieb životných situácií
      await props.getZstSlzList(flt);
    }

    // TODO: zapnut ak bude chciet pocitat kliky do liniek v banery
    const res = await locatorUtils.NotifyOpenSlz(idSlz);
    console.debug(`NotifyOpenSlz(${idSlz}), result ${res}`);
    await props.getSlzUsedList();
    await props.getSlzUsedUserList();

    let selectedIDs = [idZs];
    flushSync(() => {
      setFlt({ ...flt, searchText: null, selectedIDs });
    });
    flushSync(() => {
      fetchData();
    });
    flushSync(() => {
      setSelectedISlz(idSlz);
    });
    flushSync(() => {
      setDetailLevel(3);
    });
  };

  return (
    <>
      <CircularProgress loading={props.egovHandlerUrl.isLoading || props.listZstZs.isLoading || props.listZstSlz.isLoading} />
      <Box
        // backgroundColor={"#999"}
        className='container w-margin w-padding'
        style={{ maxWidth: matchesThemeMD ? "80%" : "90%" }}
      >
        {/* Wrapper  */}
        <Box
          // backgroundColor={"#FF5"}
          sx={{ flexDirection: "collumn" }}
        >
          {/* Navigation  */}
          <Stack
            // backgroundColor={"#555"}
            sx={{ flexDirection: "collumn" }}
            justifyContent="flex-start"
            alignItems="flex-start" //na horny okraj
            // alignItems={"stretch"}
            spacing={2}
            useFlexGap
            pt={0}
          >
            <Box
            // backgroundColor={"#fbf"}

            >
              {(() => {
                switch (detailLevel) {
                  case 1:
                    return <ZstZsNavigation classes={props.classes} />;
                  case 2:
                    return <ZstSlzNavigation classes={props.classes} handleClick={setLevel1} />;
                  case 3:
                    return <ZstSlzDetailNavigation classes={props.classes} handleClick={setLevel2} />;
                  default:
                    return null;
                }
              })()}
            </Box>
          </Stack>

          {/* Title and search  */}
          <Stack
            // backgroundColor={"#900"}
            sx={{ flexDirection: matchesThemeMD ? "row" : "collumn" }}
            justifyContent="space-between"
            // alignItems="flex-start" //na horny okraj
            alignItems={"stretch"}
            // spacing={2}
            useFlexGap
            pt={2}
          >
            {/* Title */}
            <Box
              // backgroundColor={"#c00"}
              width={{ width: matchesThemeMD ? "68%" : "100%" }}
            >
              {(() => {
                switch (detailLevel) {
                  case 1:
                    return <ZstZsTitle
                      classes={props.classes}
                    />;

                  case 2:
                    return <ZstSlzTitle
                      classes={props.classes}
                      listZstZs={props.listZstZs}
                      listZstSlz={props.listZstSlz}
                      flt={flt}
                      onClick={props.selectSlzClick}
                    />;
                  case 3:
                    return <ZstSlzDetailTitle
                      classes={props.classes}
                      listZstSlz={props.listZstSlz}
                      selectedISlz={selectedISlz}
                    />;
                  default:
                    return null;
                }
              })()}
            </Box>

            {/* Search  */}
            <Box
              // backgroundColor={"#f00"}
              sx={{
                width: matchesThemeMD ? "30%" : "100%",
                pt: matchesThemeMD ? 0 : 2
              }}
            >
              <ZstSearch
                classes={props.classes}
                searchFilterChange={handleSearchFilterChange}
                flt={flt}
                listZstZs={props.listZstZs}
              />
            </Box>
          </Stack>

          {/* Agendy + dotaznik + Info baner  */}
          <Stack
            // backgroundColor={"#040"}
            sx={{ flexDirection: matchesThemeMD ? "row" : "collumn" }}
            justifyContent={"space-between"}
            pt={2}
            pb={2}
          >
            {/* Agendy + dotaznik  */}
            <Stack
              // backgroundColor={"#BF6"}
              width={{ width: matchesThemeMD ? "68%" : "100%" }}
            >
              {/* Agendy  */}
              <Stack
                // backgroundColor={"#090"}
                spacing={2}
                mt={matchesThemeMD ? 0 : 1}
                useFlexGap
              >

                <Box sx={{ flexGrow: 1 }} spacing={1} >
                  {(() => {
                    switch (detailLevel) {
                      case 1:
                        return <ZstZsList
                          classes={props.classes}
                          listZstZs={props.listZstZs}
                          listZstSlz={props.listZstSlz}
                          flt={flt}
                          selectedIDs={flt.selectedIDs}
                          onClick={handleSelectZsClick}
                        />;
                      case 2:
                        return <ZstSlzList
                          classes={props.classes}
                          listZstZs={props.listZstZs}
                          listZstSlz={props.listZstSlz}
                          flt={flt}
                          onClick={handleSelectSlzClick}
                        />;
                      case 3:
                        // if (props.listZstSlz.isLoaded) {
                        return <ZstSlzDetailItem
                          classes={props.classes}
                          listZstSlz={props.listZstSlz}
                          selectedISlz={selectedISlz}
                          personTypeId={props.person.I_O_TYP}
                          egovUrl={props.egovHandlerUrl.data?.Data?.HODN}
                          onBackClick={() => { handleSelectSlzClick(null); }}
                          selectFrmClick={handleSelectFrmClick}
                          isLoading={isLoading}
                          matchesThemeMD={matchesThemeMD}
                        />;
                      // }
                      // else return null
                      default:
                        return null;
                    }
                  })()}
                </Box>
              </Stack>
            </Stack>

            {/* Dotaznik + Info Baner */}
            <Stack
              // backgroundColor={"#448"}
              width={{ width: matchesThemeMD ? "30%" : "100%" }}
              mt={matchesThemeMD ? 0 : 5}
              mb={matchesThemeMD ? 0 : 2}
            >
              {/* Info Baner */}
              <Stack
                // backgroundColor={"#0fd"}
                direction={"column"}
                spacing={5}
              >
                {/* Najviac vyhladavane sluzby */}
                <Stack>
                  <Typography variant={"body2"}>Často vyhľadávané</Typography>
                  {
                    props.listSlzUsed.isLoaded && props.listSlzUsedUser.isLoaded && props.listSlzAkt.isLoaded &&
                    <ZstUsedServices
                      classes={props.classes}
                      matchesThemeMD={matchesThemeMD}
                      onClick={handleInfoBanerLinkClick}
                      filterUser={false}
                    />
                  }
                </Stack>

                {/* Aktualne temy */}
                <Stack>
                  <Typography variant={"body2"}>Aktuálne témy</Typography>
                  {
                    props.listSlzUsed.isLoaded && props.listSlzUsedUser.isLoaded && props.listSlzAkt.isLoaded &&
                    <ZstActiveServices
                      classes={props.classes}
                      matchesThemeMD={matchesThemeMD}
                      onClick={handleInfoBanerLinkClick}
                    />
                  } </Stack>

                {/* Posledne vyuzite sluzby - user */}
                <Stack>
                  <Typography variant={"body2"}>Vami naposledy využité služby</Typography>
                  {
                    props.listSlzUsed.isLoaded && props.listSlzUsedUser.isLoaded && props.listSlzAkt.isLoaded &&
                    <ZstLastUsedServices
                      classes={props.classes}
                      matchesThemeMD={matchesThemeMD}
                      onClick={handleInfoBanerLinkClick}
                      filterUser={true}
                    />
                  }
                </Stack>

                {/* TODO: caka sa na doplnenie do API */}
                {/* Info */}
                {/* <Typography variant={"body2"}>Info</Typography> */}
                {/* <ZstInfo
                  classes={props.classes}
                  matchesThemeMD={matchesThemeMD}
                /> */}

                {/* Kontakt + Dotaznik */}
                <Stack>
                  <Typography variant={"body2"}>Nenašli ste čo ste hľadali?</Typography>
                  <ZstContact
                    classes={props.classes}
                    listZstZs={props.listZstZs}
                    matchesThemeMD={matchesThemeMD}
                  />
                  <ZstDotaznik
                    classes={props.classes}
                    listZstZs={props.listZstZs}
                    dotClick={handleDotClick}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box >
      </Box >
      {/* <ZstBanner /> */}
    </>
  );
};

LifeSituationsComponent.propTypes = {
};

const mapStateToProps = (state) => ({
  egovHandlerUrl: state.egovHandlerUrl,
  listZstZs: state.listZstZs,
  listZstSlz: state.listZstSlz,
  person: state.person?.data,
  user: state.user,
  zstZsTree: state.zstZsTree,
  listSlzAkt: state.listSlzAkt,
  listSlzUsed: state.listSlzUsed,
  listSlzUsedUser: state.listSlzUsedUser
});

const mapDispatchToProps = (dispatch) => ({
  getEgovHandlerUrl: () => dispatch(ciselnikyActions.getEgovHandlerUrl()),
  getZstZsList: () => dispatch(locatorActions.getZstZsList()),
  getZstSlzList: (flt) => dispatch(locatorActions.getZstSlzList(flt)),
  getZstZsTree: () => dispatch(locatorActions.getZstZsTree()),
  getSlzAktList: () => dispatch(locatorActions.getSlzAktList()),
  getSlzUsedList: () => dispatch(locatorActions.getSlzUsedList(false)),
  getSlzUsedUserList: () => dispatch(locatorActions.getSlzUsedUserList(true)),
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(LifeSituationsComponent))));
