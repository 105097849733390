import { React, useState } from "react";
import { connect } from 'react-redux';

import withWidth from '@material-ui/core/withWidth';

import Map from "../../coraWebMComponents/map/Map";
import CircularProgress from "../../coraWebMComponents/feedBack/progress/CircularProgress";
import { useDidMount } from "../../coraWebMComponents/utils/hooks";
import withRouter from '../../coraWebMComponents/utils/withRouter';

import { getParkPlaces, getParkZones, getParkAutomaty } from "../../utils/parkingUtils";
import { getPosition } from "../../utils/locationUtils";
import MapContent from "./MapContent";
import * as parkingActions from "../../actions/parkingActions";
import * as mapConsts from '../../constants/mapConsts';

const MapWrapper = (props) => {
  const [parkZones, setParkZones] = useState([]);
  const [parkPlaces, setParkPlaces] = useState([]);
  const [parkMachines, setParkMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPosition, setCurrentPosition] = useState(window.config.map.defaultPosition);

  const isMobile = (width) => {
    if (width === "xs") {
      return true;
    }
    return false;
  };

  const getCurrentPosition = async () => {
    const res = await getPosition();
    const GPS_X = res?.coords?.latitude ?? window.config.map.defaultPosition[0];
    const GPS_Y = res?.coords?.longitude ?? window.config.map.defaultPosition[1];
    return [GPS_X, GPS_Y];
  };

  const handleLocalize = () => {
    getCurrentPosition().then((position) => {
      setCurrentPosition(position);
    });
  };

  useDidMount(async () => {
    if (props.location.state?.from === "PARKING_SHORT") {
      getCurrentPosition().then((position) => {
        setCurrentPosition(position);
      });
    }
    const layerSettings = await props.dispatch(parkingActions.getMapLayersSettings());

    const parkZonesSettings = layerSettings.find((layer) => layer.I_MAP_VRST_GEO === mapConsts.MAP_ZONES);
    const parkPlacesSettings = layerSettings.find((layer) => layer.I_MAP_VRST_GEO === mapConsts.MAP_PLACES);
    const parkMachinesSettings = layerSettings.find((layer) => layer.I_MAP_VRST_GEO === mapConsts.MAP_TICKET_MACHINE);

    if (!parkZonesSettings?.L_ZOBRAZ && !parkPlacesSettings?.L_ZOBRAZ && !parkMachinesSettings?.L_ZOBRAZ) {
      setIsLoading(false);
      return;
    }

    Promise.all([
      parkZonesSettings?.L_ZOBRAZ && getParkZones(),
      parkPlacesSettings?.L_ZOBRAZ && getParkPlaces(),
      parkMachinesSettings?.L_ZOBRAZ && getParkAutomaty()
    ]).then((data) => {
      if (parkZonesSettings?.L_ZOBRAZ) {
        const parkZones = data[0].Data?.Items?.map((zone) => {
          return zone.geometry.type === "Polygon"
            ? // geometry.type: Polygon
            {
              coordinates: zone.geometry.coordinates.map((singlePolygon) => {
                return singlePolygon.map((coord) => {
                  return [coord[1], coord[0]];
                });
              }),
              color: zone.properties.PARK_ZONA_STYLE,
              title: zone.properties.N_ZONA,
              toolTip: zone.properties.Label ?? "bezplatné",
              key: zone.properties.I_ZONA,
            }
            : // geometry.type: MultiPolygon
            {
              coordinates: zone.geometry.coordinates.map((singlePolygon) => {
                return singlePolygon[0].map((coord) => {
                  return [coord[1], coord[0]];
                });
              }),
              color: zone.properties.PARK_ZONA_STYLE,
              title: zone.properties.N_ZONA,
              toolTip: zone.properties.Label ?? "bezplatné",
              key: zone.properties.I_ZONA,
            };
        });
        setParkZones(parkZones);
      }

      if (parkPlacesSettings?.L_ZOBRAZ) {
        const parkPlaces = data[1].Data?.Items?.map((place) => {
          return {
            coordinates: place.geometry.coordinates[0].map((coord) => {
              return [coord[1], coord[0]];
            }),
            key: place.properties.I_PARK_MIESTO_GEO,
            color: place.properties.PARK_MIESTO_STYLE,
            iMiestoTyp: place.properties.I_MIESTO_TYP,
            iStavMiesto: place.properties.I_STAV_MIESTO,
            nU: place.properties.N_U,
            cParkMiesto: place.properties.C_PARK_MIESTO,
          };
        });
        setParkPlaces(parkPlaces);
      }

      if (parkMachinesSettings?.L_ZOBRAZ) {
        const parkMachines = data[2].Data?.Items
        setParkMachines(parkMachines);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  });

  return (
    <>
      <CircularProgress loading={isLoading} />
      <Map
        style={{ bottom: 0, right: 0, left: 0, position: "absolute" }}
        position={currentPosition}
        center={currentPosition}
        zoom={14}
        maxZoom={window.config.map.maxZoomLevel}
        minZoom={window.config.map.minZoomLevel}
        scrollWheelZoom={true}
        height={window.innerHeight - (isMobile(props.width) ? 56 : 64)}
        markers={[]}
        zoomControlPosition="bottomleft"
        currentPositionMarker={true}
        maxBounds={[
          [
            window.config.map.defaultPosition[0] -
            window.config.map.maxBoundsOffsetX,
            window.config.map.defaultPosition[1] -
            window.config.map.maxBoundsOffsetY,
          ],
          [
            window.config.map.defaultPosition[0] +
            window.config.map.maxBoundsOffsetX,
            window.config.map.defaultPosition[1] +
            window.config.map.maxBoundsOffsetY,
          ],
        ]}
      >
        <MapContent
          parkZones={parkZones}
          parkPlaces={parkPlaces}
          parkMachines={parkMachines}
          handleLocalize={handleLocalize}
        />
      </Map>
    </>

  );
};

export default withRouter(connect()(withWidth()(MapWrapper)));