import actionTypes from './actionTypes';
import * as taxesUtils from '../utils/taxesUtils';

const getListTaxesDecisionRequest = () => ({
  type: actionTypes.GET_LIST_TAXES_DECISION_REQUEST
});

const getListTaxesDecisionSuccess = (res) => ({
  type: actionTypes.GET_LIST_TAXES_DECISION_SUCCESS,
  res
});

const getListTaxesDecisionFailure = (error) => ({
  type: actionTypes.GET_LIST_TAXES_DECISION_FAILURE,
  error
});

/**
 * Získanie zoznamu rozhodnutí
 * @param {number} iO ID osoby
 * @returns 
 */
export const getListTaxesDecision = (iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListTaxesDecisionRequest());
      let res = await taxesUtils.getListTaxesDecision(iO);
      dispatch(getListTaxesDecisionSuccess(res));
    } catch (error) {
      dispatch(getListTaxesDecisionFailure(error));
    }
  };
};

const getListProceedingsRequest = () => ({
  type: actionTypes.GET_LIST_PROCEEDINGS_REQUEST
});

const getListProceedingsSuccess = (res) => ({
  type: actionTypes.GET_LIST_PROCEEDINGS_SUCCESS,
  res
});

const getListProceedingsFailure = (error) => ({
  type: actionTypes.GET_LIST_PROCEEDINGS_FAILURE,
  error
});

/**
 * Získanie detailu rozhodnutí pre zastupovanú osobu - zoznam konaní
 * @param {string} zozCisRozh Zoznam identifikátorov rozhodnutí
 * @param {number} iO ID osoby
 * @returns 
 */
export const getListProceedings = (zozCisRozh, iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListProceedingsRequest());
      let res = await taxesUtils.getListProceedings(zozCisRozh, iO);
      dispatch(getListProceedingsSuccess(res));
    } catch (error) {
      dispatch(getListProceedingsFailure(error));
    }
  };
};

const getProceedingDetailRequest = () => ({
  type: actionTypes.GET_PROCEEDING_DETAIL_REQUEST
});

const getProceedingDetailSuccess = (res) => ({
  type: actionTypes.GET_PROCEEDING_DETAIL_SUCCESS,
  res
});

const getProceedingDetailFailure = (error) => ({
  type: actionTypes.GET_PROCEEDING_DETAIL_FAILURE,
  error
});

/**
 * Získanie detailu konania
 * @param {number} iKon ID konania
 * @param {number} cisRozh Identifikátor rozhodnutia
 * @returns 
 */
export const getProceedingDetail = (iKon, cisRozh) => {
  return async (dispatch) => {
    try {
      dispatch(getProceedingDetailRequest());
      let res = await taxesUtils.getProceedingDetail(iKon, cisRozh);
      dispatch(getProceedingDetailSuccess(res));
    } catch (error) {
      dispatch(getProceedingDetailFailure(error));
    }
  };
};

const getListPropertyTaxesRequest = () => ({
  type: actionTypes.GET_LIST_PROPERTY_TAXES_REQUEST
});

const getListPropertyTaxesSuccess = (res) => ({
  type: actionTypes.GET_LIST_PROPERTY_TAXES_SUCCESS,
  res
});

const getListPropertyTaxesFailure = (error) => ({
  type: actionTypes.GET_LIST_PROPERTY_TAXES_FAILURE,
  error
});

/**
 * Získanie listu daní z nehnutelností
 * @param {*} iKon ID konania
 * @param {*} iO ID osoby
 * @returns GridItemsVm of DnRozpVm
 */
export const getListPropertyTaxes = (iKon, iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListPropertyTaxesRequest());
      let res = await taxesUtils.getListPropertyTaxes(iKon, iO);
      dispatch(getListPropertyTaxesSuccess(res));
    } catch (error) {
      dispatch(getListPropertyTaxesFailure(error));
    }
  };
};

const getListInstallmentsRequest = () => ({
  type: actionTypes.GET_LIST_INSTALLMENTS_REQUEST
});

const getListInstallmentsSuccess = (res) => ({
  type: actionTypes.GET_LIST_INSTALLMENTS_SUCCESS,
  res
});

const getListInstallmentsFailure = (error) => ({
  type: actionTypes.GET_LIST_INSTALLMENTS_FAILURE,
  error
});

/**
 * Získanie zoznamu splátok
 * @param {*} zozIPoh Zoznam pohybov splátok
 * @param {*} iO ID osoby
 * @returns GridItemsVm of DnRozpVm
 */
export const getListInstallments = (zozIPoh, iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListInstallmentsRequest());
      let res = await taxesUtils.getListInstallments(zozIPoh, iO);
      dispatch(getListInstallmentsSuccess(res));
    } catch (error) {
      dispatch(getListInstallmentsFailure(error));
    }
  };
};

// paments (menu item)
const getListPaymentsRequest = () => ({
  type: actionTypes.GET_LIST_PAYMENTS_REQUEST
});

const getListPaymentsSuccess = (res) => ({
  type: actionTypes.GET_LIST_PAYMENTS_SUCCESS,
  res
});

const getListPaymentsFailure = (error) => ({
  type: actionTypes.GET_LIST_PAYMENTS_FAILURE,
  error
});

/**
 * Získanie zoznamu úhrad
 * @param {*} zozIPoh 
 * @param {*} iO 
 * @returns GridItemsVm of DnPoUhrVm
 */
export const getListPayments = (zozIPoh, iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListPaymentsRequest());
      let res = await taxesUtils.getListPayments(zozIPoh, iO);
      dispatch(getListPaymentsSuccess(res));
    } catch (error) {
      dispatch(getListPaymentsFailure(error));
    }
  };
};

const getListTaxesArrearsRequest = () => ({
  type: actionTypes.GET_LIST_TAXES_ARREARS_REQUEST
});

const getListTaxesArrearsSuccess = (res) => ({
  type: actionTypes.GET_LIST_TAXES_ARREARS_SUCCESS,
  res
});

const getListTaxesArrearsFailure = (error) => ({
  type: actionTypes.GET_LIST_TAXES_ARREARS_FAILURE,
  error
});

/**
 * Získanie zoznamu nedoplatkov
 * @param {number} iO ID osoby
 * @returns 
 */
export const getListTaxesArrears = (iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListTaxesArrearsRequest());
      let res = await taxesUtils.getListTaxesArrears(iO);
      dispatch(getListTaxesArrearsSuccess(res));
    } catch (error) {
      dispatch(getListTaxesArrearsFailure(error));
    }
  };
};

const getTaxesBySquareRequest = () => ({
  type: actionTypes.GET_TAXES_BY_SQUARE_REQUEST
});

const getTaxesBySquareSuccess = (res) => ({
  type: actionTypes.GET_TAXES_BY_SQUARE_SUCCESS,
  res
});

const getTaxesBySquareFailure = (error) => ({
  type: actionTypes.GET_TAXES_BY_SQUARE_FAILURE,
  error
});

export const getTaxesBySquare = (iban, vs, suma, recipient, ss) => {
  return async (dispatch) => {
    try {
      dispatch(getTaxesBySquareRequest());
      let res = await taxesUtils.getTaxesBySquare(iban, vs, suma, recipient, ss);
      dispatch(getTaxesBySquareSuccess(res));
    } catch (error) {
      dispatch(getTaxesBySquareFailure(error));
    }
  };
};

// PDF download
export const getPdfTaxProceeding = (iFile) => {
  return async (dispatch) => {
    try {
      await taxesUtils.getPdfTaxProceeding(iFile);
    } catch (error) {
      dispatch(getDownloadFailure(error));
    }
  };
};

const getDownloadFailure = (error) => ({
  type: "DOWNLOAD_FAILURE",
  error
});