import actionTypes from "./actionTypes";
import * as adminModeUtils from "../utils/adminModeUtils";

export const adminModeSetActiveState = (isActive) => ({
  type: actionTypes.ADMIN_MODE_SET_ACTIVE_STATE,
  isActive,
});

export const adminModeSetCurrEdited = (currEdited) => ({
  type: actionTypes.ADMIN_MODE_SET_CURR_EDITED_SECTION,
  currEdited,
});

export const adminModeSetEditedData = (dataEdited) => ({
  type: actionTypes.ADMIN_MODE_SET_EDITED_DATA,
  dataEdited,
});

export const adminModeReset = () => ({
  type: actionTypes.ADMIN_MODE_RESET,
});

const adminModeUpdateOrAddRequest = () => ({
  type: actionTypes.ADMIN_MODE_UPDATE_OR_ADD_REQUEST,
});

const adminModeUpdateOrAddSuccess = (res) => ({
  type: actionTypes.ADMIN_MODE_UPDATE_OR_ADD_SUCCESS,
  res,
});

const adminModeUpdateOrAddFailure = (error) => ({
  type: actionTypes.ADMIN_MODE_UPDATE_OR_ADD_FAILURE,
  error,
});

export const adminModeUpdateOrAdd = (primaryField, data) => {
  return async (dispatch) => {
    try {
      dispatch(adminModeUpdateOrAddRequest());
      let res = await adminModeUtils.updateOrAdd(primaryField, data);
      dispatch(adminModeUpdateOrAddSuccess(res));
      return true;
    } catch (error) {
      dispatch(adminModeUpdateOrAddFailure(error));
      return false;
    }
  };
};
