import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';

import routes from "../../routes";

/**
 * Navigacny baner k zoznamu zivotnych situacii
 *
 * @param {*} { classes }
 * @return {*} 
 */
const ZstZsNavigation = ({ classes }) => {
  return (
    <>
      <Button title={`Návrat na Domovskú stránku`}>
        <Link to={routes.HOME} title={`Návrat na Domovskú stránku`}>
          <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}>
            <ArrowBack />
          </Box>
        </Link>
      </Button>
    </>
  );
};

ZstZsNavigation.propTypes = {
  classes: PropTypes.object,
};

export default ZstZsNavigation;