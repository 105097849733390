import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import PersonIcon from "../../coraWebMComponents/dataDisplay/icons/PersonIcon";
import PeopleIcon from "../../coraWebMComponents/dataDisplay/icons/PeopleIcon";
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import withRouter from '../../coraWebMComponents/utils/withRouter';

import routes from '../../routes';
import * as userActions from '../../actions/userActions';
import * as accountActions from '../../actions/accountActions';

const styles = theme => ({ ...getSharedStyles(theme), });

class LoginEid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAlert: false,
      openAlertTyp: false,
      circle: true,
      code: '',
      openChosenPeople: false
    };
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code) {
      if (await this.props.dispatch(userActions.getPersonEidType(code))) {
        this.setState({ openAlertTyp: true, circle: false, code: '' });
      }
      else {
        const user = await this.props.dispatch(userActions.loginEid(code));

        if (user) {
          await this.props.dispatch(accountActions.getPerson(user.Data.i_o));

          if (this.props.listPeople.length > 1)
            this.setState({ openChosenPeople: true });
          else
            this.props.navigate(routes.HOME);


        }
        else {
          this.setState({ openAlert: true, circle: false, code });
        }
      }
    }
    else {
      this.setState({ openAlert: true, circle: false, code: '' });
    }
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false, openAlertTyp: false });
    this.props.navigate(routes.LOGIN);
  };

  handlePermit = () => {
    this.props.navigate({ pathname: routes.ACCOUNT_PERMIT_SUCCESS, search: `?code=${this.state.code}` });
  };

  handleGoHomePeople = async (i_o) => {
    await this.props.dispatch(accountActions.getPerson(i_o));
    this.props.navigate(routes.HOME);
  };


  render() {
    const { classes } = this.props;
    const fMelements =
      <div >
        <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Výber osoby</Typography>
        {this.props.listPeople
          .filter((x) => x.L_ZASTUPUJUCI)
          .map((x) => (
            <MenuItem key={x.I_O_ZASTUP.toString()} onClick={(_event) => this.handleGoHomePeople(x.I_O_ZASTUP)}>
              <PersonIcon />
              <div className={classNames(classes.ml2, classes.mr3)}>
                <Typography>{x.ZASTUPOVANY}</Typography>
              </div>
            </MenuItem>
          ))}

        {this.props.listPeople
          .filter((x) => !x.L_ZASTUPUJUCI)
          .map((x) => (
            <MenuItem key={x.I_O_ZASTUP.toString()} onClick={(_event) => this.handleGoHomePeople(x.I_O_ZASTUP)}>
              <PeopleIcon />
              <div className={classNames(classes.ml2, classes.mr3)}>
                <Typography>{x.ZASTUPOVANY}</Typography>
              </div>
            </MenuItem>
          ))}

      </div>;
    return (
      <div >
        <CircularProgress loading={this.state.circle} />
        <Dialog
          open={this.state.openAlert}
          onClose={this.handleCloseAlert}
          contentText='Nepodarilo sa prihlásiť pomocou eID!<br>Ak v datamesta nemáte konto, registrujte sa.'
          title='Upozornenie'
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseAlert,
              keyboardFocused: true
            },
            {
              label: 'Registrácia',
              color: 'primary',
              onClick: this.handlePermit,
              keyboardFocused: true
            }
          ]}
        />
        <Dialog
          open={this.state.openAlertTyp}
          onClose={this.handleCloseAlert}
          contentText='Nie je možné prihlásenie, alebo registrácia pre právnické osoby!'
          title='Upozornenie'
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseAlert,
              keyboardFocused: true
            }
          ]}
        />
        <FormModal
          open={this.state.openChosenPeople}
          elements={fMelements}
        />
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    listPeople: state.listPeople.data,

  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(LoginEid)));
