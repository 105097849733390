import actionTypes from '../../actions/actionTypes';

const _initState = {
  locale: 'sk-SK',
};

const locale = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCALE_REQUEST:
      return { locale: 'sk-SK' };

    case actionTypes.SET_LOCALE_SUCCESS:
      return { locale: action.res };

    case actionTypes.SET_LOCALE_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default locale;