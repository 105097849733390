import actionTypes from '../../actions/actionTypes';
import { PaymentSettingsHandler } from '../../utils/paymentUtils.js';

const _initState = {
  settings: null,
  isLoading: false,
  isLoaded: false
}

const paymentSettings = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_SETTINGS_REQUEST:
      return { ...state, isLoading: true, isLoaded: false };

    case actionTypes.GET_PAYMENT_SETTINGS_SUCCESS:
      return { settings: action.res.Data ? new PaymentSettingsHandler(action.res.Data) : null, isLoading: false, isLoaded: true }

    case actionTypes.GET_PAYMENT_SETTINGS_FAILURE:
      return { ..._initState, isLoading: false, isLoaded: true };

    default:
      return state
  }
}

export default paymentSettings;