import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';

import MenuTilesItem from "./MenuTilesItem";

const MenuTiles = ({ classes, menuItems, handleMenuClick }) => {
  const theme = useTheme();
  const breakpointSM = theme.breakpoints.up('sm');
  const matchesThemeSM = useMediaQuery(breakpointSM);
  const breakpointMD = theme.breakpoints.up('md');
  const matchesThemeMD = useMediaQuery(breakpointMD);

  return (
    <Box mb={2} mt={4}>
      {menuItems && <Grid container spacing={2} direction="row" justifyContent="left" alignItems="stretch">
        {
          menuItems?.length > 0 && menuItems.map(item => {
            return (
              <Grid item key={item.MenuID} xs={matchesThemeMD ? 3 : (matchesThemeSM ? 4 : 6)} >
                <MenuTilesItem classes={classes} item={item} onClick={handleMenuClick} matchesThemeMD={matchesThemeMD} />
              </Grid >
            );
          })}
      </Grid>
      }
    </Box>
  );
};

export default MenuTiles;