import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class RegistryRecordsIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polygon fill="none" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" points="18,19 1,19 1,11 
		6,11 7,12 12,12 13,11 18,11 	"/>
          <polyline fill="none" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" points="1,11 4,1 6,1 
		13,1 15,1 18,11 	"/>
          <polyline fill="none" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" points="14,10 14,7 5,7 
		5,10 	"/>
          <polyline fill="none" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" points="6,6 6,4 13,4 
		13,6 	"/>
          <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="12" y1="9.5" x2="7" y2="9.5" />
        </g>
      </SvgIcon>
    );
  }
}

RegistryRecordsIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default RegistryRecordsIconComponent;
