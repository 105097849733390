import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: []
}

const visiblePublicZone = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_VISIBLE_PUBLIC_ZONE_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_VISIBLE_PUBLIC_ZONE_SUCCESS:
      return { isLoading: false, data: action.res }

    case actionTypes.GET_VISIBLE_PUBLIC_ZONE_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default visiblePublicZone;