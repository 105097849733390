import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class CatalogOpenDataIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polyline fill="none" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="
		15,5 18,5 18,17 1,17 1,5 4,5 	"/>

          <polygon fill="none" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="
		15,13 9.5,17 4,13 4,2 9.5,6 15,2 	"/>

          <circle fill="none" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" cx="9.5" cy="18" r="0.5" />

          <line fill="none" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="9.5" y1="12" x2="9.5" y2="7" />
        </g>
      </SvgIcon>
    );
  }
}

CatalogOpenDataIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default CatalogOpenDataIconComponent;
