import * as storage from '../../coraWebMComponents/utils/storage';

import actionTypes from '../../actions/actionTypes';

const user = (state = {
  isLoading: false,
  data: storage.getUser()
}, action) => {
  switch (action.type) {
    case actionTypes.LOGINEID_REQUEST:
    case actionTypes.LOGIN_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.LOGINEID_SUCCESS:
    case actionTypes.LOGIN_SUCCESS:
      return { isLoading: false, data: action.user.Data };

    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGINEID_FAILURE:
    case actionTypes.LOGIN_FAILURE:
      return { isLoading: false, data: null };

    default:
      return state;
  }
};

export default user;