import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@mui/material/Box';

// import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import SearchFilter from "../metaGrid/SearchFilter";

/**
 * Vyhladavanie
 *
 * @param {*} { classes, flt, listZstZs, searchFilterChange }
 * @return {*} 
 */
const ZstSearch = ({ classes, flt, listZstZs, searchFilterChange }) => {
  return (
    <>
      {listZstZs.data.length > 0 &&
        <Box>
          <SearchFilter
            className={classNames(classes.w100)}
            searchText={flt.searchText}
            placeholderText={"Vyhľadajte službu"}
            onClick={searchFilterChange}
          />
        </Box>
      }
    </>
  );
};

ZstSearch.propTypes = {
  classes: PropTypes.object,
  listZstZs: PropTypes.object,
  flt: PropTypes.object,
  searchFilterChange: PropTypes.func,
};

export default ZstSearch;