import React from "react";
import { Route, Outlet, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/sk";
import find from "lodash/find";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

import { red, grey, blue, lightBlue, lightGreen } from "@material-ui/core/colors";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import withMeta from "./coraWebMComponents/utils/withMeta";
import MetaRoute, { AuthLayout } from "./coraWebMComponents/meta/MetaRoute";
import LocalizationProvider from "./coraWebMComponents/localization/withLocalization";
import Snackbar from "./coraWebMComponents/feedBack/SnackBar";
import AppOffline from "./coraWebMComponents/views/AppOffline";

// import * as accountActions from "./actions/accountActions";
import * as userAction from "./actions/userActions";
import ErrorDialog from "./components/errorDialog/ErrorDialog";
import Routes from "./routes";
import AppBar from "./components/appBar/AppBar";
import Home from "./components/home/Home";
import AccountPermit from "./components/account/AccountPermit";
import AccountPermitSuccess from "./components/account/AccountPermitSuccess";
import AccountAddPerson from "./components/account/AccountAddPerson";
import AccountModify from "./components/account/AccountModify";
import Parking from "./components/parking/Parking";
import ParkingPermit from "./components/parking/ParkingPermit";
import ParkingModify from "./components/parking/ParkingModify";
import ParkingTickets from "./components/parking/ParkingTickets";
import Map from "./components/map/Map";
import Credit from "./components/parking/Credit";
import CreditDraw from "./components/parking/CreditDraw";
import CreditDrawList from "./components/parking/CreditDrawList";
import TempEcv from "./components/parking/TempEcv";
import Login from "./components/user/Login";
import LoginEid from "./components/user/LoginEid";
import PreLogin from "./components/user/PreLogin";
import Pay from "./components/payment/Pay";
import Gateway from "./components/payment/Gateway";
import PayReceived from "./components/payment/PayReceived";
import Taxes from "./components/taxes/Taxes";
import SubsidiesRating from "./components/subsidiesRating/SubsidiesRating";

//PublicZone
import PublicZone from "./components/publicZone/PublicZone";
import ParkingTicket from "./components/publicZone/parkingTicket/ParkingTicket";
import ParkCard from "./components/publicZone/ParkCard";
import ParkCardVerify from "./components/publicZone/ParkCardVerify";
import RequiredPublic from "./components/publicZone/RequiredPublic";
import PzMnu from "./components/publicZone/PzMnu";
import PzFrm from "./components/publicZone/PzFrm";
import PublicCardVerify from "./components/publicZone/PublicCardVerify";
import OpenData from "./components/publicZone/OpenData";

//Zivotne situacie
import LifeSituations from "./components/locator/LifeSituations";
import ZstQuestionnaire from "./components/locator/ZstQuestionnaire";

//Rozpočet
import ProgRozp from "./components/rozpocet/ProgRozp";

//sprava
// import SpPrj from './components/admin/proj';

//PWA
import InstallButton from "./coraWebMComponents/InstallButton";

import en from "./localization/en.json";
import sk from "./localization/sk.json";
import de from "./localization/de.json";

const muiTheme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5",
    },
    primary: {
      light: grey[50],
      main: grey[400],
      dark: grey[600],
      contrastText: "#000",
    },
    secondary: {
      light: grey[500],
      main: grey[800],
      dark: grey[700],
      contrastText: "#fff",
    },
    lightBlue: {
      light: lightBlue[50],
      main: lightBlue[100],
      dark: lightBlue[200],
      contrastText: "#fff",
    },
    lightGreen: {
      light: lightGreen[50],
      main: lightGreen[100],
      dark: lightGreen[200],
      contrastText: "#fff",
    },
    adminBlue: {
      light: blue[300],
      main: blue[400],
      dark: blue[500],
      contrastText: "#fff",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
    fontSize: 15,
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 500,
    },
    bodySmall: {
      fontSize: 13,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 17,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: grey[800],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#000",
      },
    },
  },
});

document.documentElement.style.setProperty(
  "--body-bg",
  muiTheme.palette.background.default
);
document.documentElement.style.setProperty(
  "--primary-light-color",
  muiTheme.palette.primary.light
);
document.documentElement.style.setProperty(
  "--primary-main-color",
  muiTheme.palette.primary.main
);
document.documentElement.style.setProperty(
  "--primary-dark-color",
  muiTheme.palette.primary.dark
);
document.documentElement.style.setProperty(
  "--secondary-light-color",
  muiTheme.palette.secondary.light
);
document.documentElement.style.setProperty(
  "--secondary-main-color",
  muiTheme.palette.secondary.main
);
document.documentElement.style.setProperty(
  "--secondary-dark-color",
  muiTheme.palette.secondary.dark
);
document.documentElement.style.setProperty(
  "--secondary-contrast-text-color",
  muiTheme.palette.secondary.contrastText
);

/** Verejná zóna, Moja zóna, Parkovanie */
const isMainMenu = (menuId) => [4004017, 4004018, 4004019].includes(menuId);

/** Payment komponenty Gateway, PayReceived, Pay */
const isPaymentMenu = (menuId) => [4004013, 4004014, 4004015].includes(menuId);

/** metadátové komponenty PzMnu, PzFrm, OpenData */
const isCommonComponent = (componentId) => [4032, 4033, 4035].includes(componentId);

const getComponent = (id) => {
  switch (id) {
    case 4002:
      return Parking;
    case 4003:
      return Pay;
    case 4004:
      return AccountAddPerson;
    case 4005:
      return AccountModify;
    case 4006:
      return ParkingModify;
    case 4007:
      return ParkingPermit;
    case 4008:
      return RequiredPublic;
    case 4013:
      return Gateway;
    case 4014:
      return Credit;
    case 4015:
      return CreditDrawList;
    case 4016:
      return CreditDraw;
    case 4017:
      return ParkingTicket;
    case 4018:
      return PayReceived;
    case 4019:
      return PublicZone;
    case 4020:
      return AccountPermit;
    case 4021:
      return AccountPermitSuccess;
    case 4022:
      return ParkCard;
    case 4023:
      return ParkCardVerify;
    case 4024:
      return TempEcv;
    case 4026:
      return Taxes;
    case 4027:
      return ParkingTickets;
    case 4028:
      return SubsidiesRating;
    case 4029:
      return LifeSituations;
    case 4030:
      return ZstQuestionnaire;
    case 4031:
      return ProgRozp;
    case 4032:
      return PzMnu;
    case 4033:
      return PzFrm;
    case 4034:
      return PublicCardVerify;
    case 4035:
      return OpenData;
    default:
      return Home;
  }
};

const getAppBarTitle = (menuTree, routePath) => {

  const route = routePath.split('?').reverse().pop();
  switch (route) {
    case Routes.HOME:
      return "Moje údaje";
    case Routes.ACCOUNT:
      return "Môj účet";
    case Routes.ACCOUNT_ADD_PERSON:
      return "Pridanie zastupovanej osoby";
    case Routes.ACCOUNT_MODIFY:
      return "Zmena údajov";
    case Routes.PARKING:
    case Routes.PARKING_MODIFY:
    case Routes.PARKING_PERMIT:
      return "Parkovacie karty";
    case Routes.PARKING_CREDIT:
      return "Kredity parkovacej karty";
    case Routes.PARKING_CREDIT_DRAW_LIST:
      return "Prehľad čerpaní kreditu";
    case Routes.PARKING_CREDIT_DRAW:
      return "Čerpanie kreditu";
    case Routes.MAP:
      return "Parkovanie";
    case Routes.PZ:
      return "Verejná zóna";
    case Routes.PZ_RP:
    case Routes.PZ_RP_DOT:
      return "Povinné zverejňovanie";
    case Routes.PZ_PARKING_SHORT:
      return "Parkovanie";
    case Routes.PZ_PARKING_CARD:
    case Routes.PZ_PARKING_CARD_VERIFY:
    case Routes.PARKING_TEMP_ECV:
      return "Parkovacia karta";
    case Routes.PAYMENT_PAY:
      return "Platba";
    case Routes.PAYMENT_GATEWAY:
      return "Platobná brána";
    case Routes.PAYMENT_RECEIVED:
      return "Výsledok platby";
    case Routes.TAXES:
      return "Dane a poplatky";
    case Routes.ZST:
    case Routes.ZST_DOT:
      return "Životné situácie";
    case Routes.PARKING_TICKET:
      return "Parkovacie lístky";
    case Routes.SUBSIDIES_RATING:
      return "Hodnotenie žiadostí o dotácie";
    case Routes.SP_PRJ:
      return "Správa projektov";
    case Routes.SP_PRJ_PRJ:
      return "Projekty";
    case Routes.SP_PRJ_UZ:
      return "Priradenie projektov";
    case Routes.PZ_ROZP:
      return "Programový rozpočet";
    case Routes.PUBLIC_CARD_VERIFY:
      return "Overenie parkovacej karty";
    default:
      break;
  }
  const menuFound = findDeepMenu(menuTree, (menuActual) => menuActual.Route === route);
  if (menuFound && menuFound.Title && (!menuFound.ComponentId || isCommonComponent(menuFound.ComponentId))) {
    return menuFound.Title;
  }
  return "Datamesta";
};

const AppBarWrapper = ({ menuTree, initMenu }) => {
  const location = useLocation();
  let title = getAppBarTitle(menuTree, location.pathname);
  return (
    <>
      <AppBar menu={menuTree} initMenu={initMenu} appBarTitle={title} />
      <Outlet />
    </>
  );
};

export const findDeepMenu = (menuParent, fn) => {
  let menuFound = find(menuParent.children, fn);
  if (!menuFound) {
    menuParent.children.every((item) => {
      menuFound = findDeepMenu(item, fn);
      return !menuFound;
    });
  }
  return menuFound;
};

let _init;

class App extends React.Component {
  handleCloseMsg = () => {
    this.props.dispatch(userAction.hideMsg());
  };
  handleCloseErrorMsg = () => {
    this.props.dispatch(userAction.hideErrorMsg());
  };

  componentDidMount = () => {
    if (!this.props.isAuth) {
      this.props.meta.init(true);
    }
  };

  render() {
    if (this.props.isAuth) {
      if (!_init) {
        _init = true;
        this.props.meta.init();
      }
    } else {
      _init = false;
    }

    if (this.props.meta.isAppOffline) return <AppOffline />;

    //HOTFIX: neotvarali sa linky podstranok, po priamom vlozeni do prehliadaca. Vzdy sa prv otvorila home page
    if (this.props.meta.menu.length === 0) return null;

    const preLoginBtns = this.props.meta.menuTree.children.filter((x) => isMainMenu(x.MenuID));

    return (
      <LocalizationProvider en={en} sk={sk} de={de} locale={this.props.locale}>
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={this.props.locale.substring(0, 2)}
            moment={moment}
          >
            <div id="nadRouter">
              <div id="nadAppBar">
                <MetaRoute
                  isAuth={this.props.isAuth}
                  akcFactory={null}
                  appBarWrapper={
                    <AppBarWrapper
                      menuTree={this.props.meta.menuTree}
                      initMenu={this.props.meta.init}
                    />
                  }
                  customRoutes={
                    <>
                      {this.props.meta.menu
                        .filter(
                          (x) =>
                            x.ComponentId &&
                            x.NadMenuID &&
                            !isPaymentMenu(x.MenuID)
                        )
                        .map((x) => {
                          const Component = getComponent(x.ComponentId);
                          if (x.PrjId === 4004) {
                            return (
                              <Route
                                key={x.Route}
                                path={x.Route}
                                element={
                                  <Component
                                    menuTree={this.props.meta.menuTree}
                                    isAuth={this.props.isAuth}
                                  />
                                }
                              />
                            );
                          } else {
                            return (
                              <Route
                                key={x.Route}
                                element={
                                  <AuthLayout userData={this.props.user} />
                                }
                              >
                                <Route
                                  path={x.Route}
                                  element={
                                    <Component
                                      menuTree={this.props.meta.menuTree}
                                      isAuth={this.props.isAuth}
                                    />
                                  }
                                />
                              </Route>
                            );
                          }
                        })}
                      <Route
                        path={Routes.PRELOGIN}
                        element={<PreLogin preLoginBtns={preLoginBtns} />}
                      />
                      <Route exact path={Routes.LOGIN} element={<Login />} />
                      <Route path={Routes.MAP} element={<Map />} />
                      <Route path={Routes.LOGINEID} element={<LoginEid />} />
                      <Route
                        element={<AuthLayout userData={this.props.user} />}
                      >
                        <Route
                          path={Routes.HOME}
                          element={<Home userData={this.props.user} />}
                        />
                      </Route>
                      <Route path={"/payment"}>
                        <Route path={"pay"} element={<Pay />} />
                        <Route path={"gateway"} element={<Gateway />} />
                        <Route path={"recieved"}>
                          <Route
                            index={true}
                            element={<PayReceived isAuth={this.props.isAuth} />}
                          />
                          <Route
                            path={"*"}
                            element={<PayReceived isAuth={this.props.isAuth} />}
                          />
                        </Route>
                      </Route>
                      <Route
                        path={Routes.ACCOUNT_PERMIT}
                        element={<AccountPermit />}
                      />
                      <Route
                        path={Routes.PZ_PARKING_CARD}
                        element={<ParkCard />}
                      />
                    </>
                  }
                />
              </div>
              <ErrorDialog />
              <Snackbar
                message={this.props.msg}
                variant="success"
                onClose={this.handleCloseMsg}
              />
              <Snackbar
                message={this.props.errorMsg}
                variant="error"
                onClose={this.handleCloseErrorMsg}
              />
              <InstallButton />
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    msg: state.msg,
    errorMsg: state.errorMsg,
    isAuth: state.user.data !== null,
    locale: state.locale.locale,
  };
};

export default withMeta(connect(mapStateToProps)(App));
