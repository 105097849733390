import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';

import * as ciselnikyUtils from '../../utils/ciselnikyUtils';

async function getPh(id) {
  try {
    const res = await ciselnikyUtils.getPh(id);
    return { value: res?.Data.HODN, description: res?.Data.POPIS_OB };
  } catch (error) {
    console.error(error);
  }
}

/**
 * Baner - kontakt
 *
 * @param {*} { classes, matchesThemeMD }
 * @return {*} 
 */
const ZstContact = ({ classes, matchesThemeMD }) => {
  const [showContact, setShowContact] = useState(false);
  const [msuName, setMsuName] = useState({});
  const [msuMail, setMsuMail] = useState({});
  const [msuTel, setMsuTel] = useState({});

  // inicializačné načítanie dát
  useEffect(() => {
    async function fetchData() {
      setMsuName(await getPh(999998001));
      setMsuMail(await getPh(999998006));
      setMsuTel(await getPh(999998004));
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (

    <Box>
      <Link
        component="button"
        variant="caption"
        onClick={() => setShowContact(!showContact)}
        className={classNames(classes.textDecorationNone)}
      >
        <Typography variant="caption" className={classNames(classes.uxTextCaption)}>
          Kontakt
        </Typography>
      </Link>

      {
        showContact && (
          <Grid container pl={0} pb={1} rowSpacing={0} columnSpacing={0}>
            <Grid item xs={matchesThemeMD ? 5 : 3} >
              <Typography variant="caption">{msuName.description}:</Typography>
            </Grid>
            <Grid item xs={matchesThemeMD ? 7 : 9}  >
              <Typography variant="body2">{msuName.value} </Typography>
            </Grid>
            <Grid item xs={matchesThemeMD ? 5 : 3}  >
              <Typography variant="caption">{msuMail.description}:</Typography>
            </Grid>
            <Grid item xs={matchesThemeMD ? 7 : 9} >
              <Link to={`mailto: ${msuMail.value}`} className={classNames(classes.textDecorationNone)}>
                <Typography variant="body2" className={classNames(classes.uxTextCaption)}>{`${msuMail.value}`}</Typography>
              </Link>
            </Grid>
            <Grid item xs={matchesThemeMD ? 5 : 3} >
              <Typography variant="caption">{msuTel.description}:</Typography>
            </Grid>
            <Grid item xs={matchesThemeMD ? 7 : 9}>
              <Typography variant="body2">{msuTel.value}</Typography>
            </Grid>
          </Grid>
        )
      }
    </Box>
  );
};

ZstContact.propTypes = {
  classes: PropTypes.object,
  matchesThemeMD: PropTypes.bool
};

export default ZstContact;