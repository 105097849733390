import * as requests from '../coraWebMComponents/utils/requests';

const buildGetListSubsidiesRatingFilter = (io, iStav = '', iCelok, iOblast, nazovAkcie, menoZiadatela) => {

  if (iStav === 0)
    iStav = '';

  return {
    filters: [
      { field: "I_O", operator: "in", value: io },
      { field: "I_STAV", operator: "in", value: iStav },
      { field: "I_CLK", operator: "in", value: iCelok },
      { field: "I_OBLST", operator: "in", value: iOblast },
      { field: "N_PROJ", operator: "contains", value: nazovAkcie },
      { field: "N_O_ZIAD", operator: "contains", value: menoZiadatela }
    ]
  };
};

export const getListSubsidiesRating = async (io, iStav, iCelok, iOblast, nazovAkcie, menoZiadatela) => {
  try {
    let filter = buildGetListSubsidiesRatingFilter(io, iStav, iCelok, iOblast, nazovAkcie, menoZiadatela);
    let page = { skip: 1, take: 1000 };
    return await requests.getList('/api/Subsidies/DT_DT', filter, '', page);
  }
  catch (error) {
    throw error;
  }
};

export const getListSubsidyDetail = async (iDt) => {
  try {
    let filter = { filters: [{ field: "I_DT", operator: "in", value: iDt }] };
    let page = { skip: 1, take: 1000 };
    let sort = [{ field: "C_ZORAD_PKRT", dir: "asc" }, { field: "C_ZORAD_KRT", dir: "asc" }, { field: "I_PKRT", dir: "asc" }];
    return await requests.getList('/api/Subsidies/DT_TXT', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
};

export const getListSubsidyRatingDetail = async (iDt) => {
  try {
    let filter = { filters: [{ field: "I_DT", operator: "in", value: iDt }] };
    let page = { skip: 1, take: 1000 };
    let sort = [{ field: "C_ZORAD_KRT", dir: "asc" }];
    return await requests.getList('/api/Subsidies/DT_HOD', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
};

/*export const updSubsidyRating = async (iDt, hod) => {
  const url = `/api/Subsidies/DT_HOD?id=${iDt}&hod=${hod !== null ? hod : ''}`;
  try {
    return await requests.put(url);
  }
  catch (error) {
    throw error;
  }
}*/

export const updSubsidyRating = async (data) => {
  try {
    return await requests.put(`/api/Subsidies/DT_HOD`, data);
  }
  catch (error) {
    throw error;
  }
};

/**
 * Polozky filtra pre oblasti
 *
 * @return {*} 
 */
export const getFilterSubsidyOblst = async () => {
  try {
    let filter = { filters: [] };
    let page = { skip: 1, take: 1000 };
    let sort = [{ field: "N_OBLST", dir: "asc" }];
    return await requests.getList('/api/Subsidies/DT_OBLST', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
};
/**
 * Polozky filtra pre celky
 *
 * @return {*} 
 */
export const getFilterSubsidyClk = async () => {
  try {
    let filter = { filters: [] };
    let page = { skip: 1, take: 1000 };
    let sort = [{ field: "N_CLK", dir: "asc" }];
    return await requests.getList('/api/Subsidies/DT_CLK', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
};