import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

class ChipComponent extends React.Component {
  render() {
    return (
      <Chip
        style={this.props.style}
        icon={this.props.icon}
        label={this.props.label}
        onDelete={this.props.onDelete}
        className={this.props.className}
        title={this.props.title}
      />
    )
  }
}

ChipComponent.propTypes = {
  style: PropTypes.object,
  icon: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}

export default ChipComponent;