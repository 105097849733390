import React from 'react';
import PropTypes from 'prop-types';
import withPaymentGateway from './utils/withPaymentGateway';
import './PaymentGateway.css';
import * as storage from './utils/storage';

const PAYMENT_WAYBILL_KEY = 'PaymentWaybill';
let _vs, _result, _transactionId;
class PaymentGatewayComponent extends React.Component {
  constructor(props) {
    super(props);

    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _vs = params.get("VS");
    _result = params.get("RESULT");
    _transactionId = params.get("transactionId");

    this.ref = React.createRef();
  }

  async componentDidMount() {

    const { waybill, payData } = this.props;
    const payMethod = waybill?.payMethod ?? 0;

    if (payMethod === 1) {
      // riesenie CG HD 70888

      if (_transactionId) {

        // overenie platby
        try {

          // overenie platby na platobnej brane a zapis vysledku do DB
          // navratova hodnota typu bool - uspesnost zaplatenia
          let resData = await this.props.paymentVerification(this.props.url, waybill);

          // zaregistrovanie stavu do state (1 - platba OK, 2-platba OK ale volanie overenia zlyhalo)
          this.props.onVerify(resData ? 1 : 2);

        } catch (error) {
          console.error(error);

          // zaregistrovanie stavu do state (3 - platba OK ale overenia zlyhalo)
          this.props.onVerify(3); //3-platba OK ale overenia zlyhalo
        }
      }
      else if (!_transactionId && payData) {
        // platba
        try {

          // zaregistrovanie platby na platobnej brane
          // navratova hodnota obsahuje transactionId a redirectUrl
          let resData = await this.props.paymentIntent({ waybill, payData });

          // nastavenie ziskaneho transactionId do sprievodky
          waybill.transactionId = resData.transactionId;

          // ulozenie sprievodky do storage
          storage.setItem(PAYMENT_WAYBILL_KEY, waybill);

          // redirect na ziskanu adresu redirectUrl
          window.open(resData.redirectUrl, '_parent').focus();

        } catch (error) {
          console.error(error);
        }
      }

    } else {

      // povodne riesenie

      var _elm = document.getElementById('payment-gateway-container');

      if (_vs) {

        // overenie platby
        if (_result === 'OK') {

          try {
            // overenie platby na platobnej brane a zapis vysledku do DB
            // navratova hodnota typu bool - uspesnost zaplatenia
            const resData = await this.props.isReceived(this.props.url, _vs);

            // zaregistrovanie stavu do state (1 - platba OK, 2-platba OK ale volanie overenia zlyhalo)
            this.props.onVerify(resData ? 1 : 2);
          } catch (error) {
            console.error(error);

            // zaregistrovanie stavu do state (3 - platba OK ale overenia zlyhalo)
            this.props.onVerify(3);
          }
        }
        else {
          // zaregistrovanie stavu do state (4- platba zlyhanie)
          this.props.onVerify(4);
        }

      }
      else if (!_vs && this.props.vs) {

        // platba
        try {
          const { vs, amount, lang, email } = this.props;

          // vygenerovanie url pre vytvorenie platby
          const resData = await this.props.sign(amount, vs, lang, email);

          // volanie ziskanej url pre vytvorenie platby
          window.Besteron.show(document, _elm, resData.Data);
        } catch (error) {
          console.error(error);
        }
      }

    }

    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
          this.props.onBack();
        }
      }
    });
    if (this.props.onBack) {
      this.observer.observe(this.ref.current, { childList: true });
    }
  }

  componentWillUnmount() {
    if (this.observer)
      this.observer.disconnect();
  }

  render() {
    return (
      <div ref={this.ref}>
        <div className="payment-gateway-container" id="payment-gateway-container" />
      </div>
    );
  }
}

PaymentGatewayComponent.propTypes = {
  vs: PropTypes.string,
  amount: PropTypes.number,
  lang: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.string,
  onVerify: PropTypes.func,
  onBack: PropTypes.func,
  waybill: PropTypes.object,
  payData: PropTypes.object
}

export default withPaymentGateway(PaymentGatewayComponent);