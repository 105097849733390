import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class AdminSegmentationIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polygon fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="9,5 5,3 9,1 13,3 
			"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="9,10 5,8 5,3 
		9,5 9,10 13,8 13,3 9,5 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="9,10 13,12 
		13,17 9,15 9,10 5,12 5,17 9,15 	"/>
          <polygon fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="13,12 9,10 13,8 
		17,10 	"/>
          <polygon fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="5,12 1,10 5,8 
		9,10 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="1,10 1,15 5,17 	
		"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="17,10 17,15 
		13,17 	"/>
        </g>
      </SvgIcon>
    );
  }
}

AdminSegmentationIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default AdminSegmentationIconComponent;
