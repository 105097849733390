import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: []
};

const parkingCard = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_CARD_REQUEST:
      return { data: state.data, isLoading: true };

    case actionTypes.GET_PARKING_CARD_SUCCESS:
      return { isLoading: false, data: action.res };

    case actionTypes.GET_PARKING_CARD_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default parkingCard;