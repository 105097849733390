import actionTypes from './actionTypes';
//import * as userActions from './userActions';
import * as accountUtils from '../utils/accountUtils';

const AddAccountPermitRequest = () => ({
  type: actionTypes.ADD_ACCOUNT_PERMIT_REQUEST
});

const AddAccountPermitSuccess = (res) => ({
  type: actionTypes.ADD_ACCOUNT_PERMIT_SUCCESS,
  res
});

const AddAccountPermitFailure = (error) => ({
  type: actionTypes.ADD_ACCOUNT_PERMIT_FAILURE,
  error
});

export const AddAccountPermit = (accountPermit) => {
  return async (dispatch) => {
    try {
      dispatch(AddAccountPermitRequest());
      let res = await accountUtils.addAccountPermit(accountPermit);
      //dispatch(userActions.showMsg('Vaša žiadosť bola zaevidovaná.'));
      dispatch(AddAccountPermitSuccess(res));
      return true;
    } catch (error) {
      dispatch(AddAccountPermitFailure(error));
      return false;
    }
  };
};

const AddAccountPersonRequest = () => ({
  type: actionTypes.ADD_ACCOUNT_PERSON_REQUEST
});

const AddAccountPersonSuccess = (res) => ({
  type: actionTypes.ADD_ACCOUNT_PERSON_SUCCESS,
  res
});

const AddAccountPersonFailure = (error) => ({
  type: actionTypes.ADD_ACCOUNT_PERSON_FAILURE,
  error
});

export const AddAccountPerson = (accountPerson) => {
  return async (dispatch) => {
    try {
      dispatch(AddAccountPersonRequest());
      let res = await accountUtils.addAccountPerson(accountPerson);
      //dispatch(userActions.showMsg('Vaša žiadosť bola zaevidovaná.'));
      dispatch(AddAccountPersonSuccess(res));
      return true;
    } catch (error) {
      dispatch(AddAccountPersonFailure(error));
      return false;
    }
  };
};

const CancelAccountPersonRequest = () => ({
  type: actionTypes.CANCEL_ACCOUNT_PERSON_REQUEST
});

const CancelAccountPersonSuccess = (res) => ({
  type: actionTypes.CANCEL_ACCOUNT_PERSON_SUCCESS,
  res
});

const CancelAccountPersonFailure = (error) => ({
  type: actionTypes.CANCEL_ACCOUNT_PERSON_FAILURE,
  error
});

export const CancelAccountPerson = (io) => {
  return async (dispatch) => {
    try {
      dispatch(CancelAccountPersonRequest());
      let res = await accountUtils.cancelAccountPerson(io);

      dispatch(CancelAccountPersonSuccess(res));
      return true;
    } catch (error) {
      dispatch(CancelAccountPersonFailure(error));
      return false;
    }
  };
};


const getZiadostByNameRequest = () => ({
  type: actionTypes.GET_ZIADOST_BY_NAME_REQUEST
});

const getZiadostByNameSuccess = (res) => ({
  type: actionTypes.GET_ZIADOST_BY_NAME_SUCCESS,
  res
});

const getZiadostByNameFailure = (error) => ({
  type: actionTypes.GET_ZIADOST_BY_NAME_FAILURE,
  error
});

export const getZiadostByName = (name) => {
  return async (dispatch) => {
    try {
      dispatch(getZiadostByNameRequest());
      let res = await accountUtils.getZiadostByName(name);
      dispatch(getZiadostByNameSuccess(res));
    } catch (error) {
      dispatch(getZiadostByNameFailure(error));
    }
  };
};

const getListPeopleRequest = () => ({
  type: actionTypes.GET_LIST_PEOPLE_REQUEST
});

const getListPeopleSuccess = (res) => ({
  type: actionTypes.GET_LIST_PEOPLE_SUCCESS,
  res
});

const getListPeopleFailure = (error) => ({
  type: actionTypes.GET_LIST_PEOPLE_FAILURE,
  error
});

export const getListPeople = () => {
  return async (dispatch) => {
    try {
      dispatch(getListPeopleRequest());
      let res = await accountUtils.getListPeople();
      dispatch(getListPeopleSuccess(res));
    } catch (error) {
      dispatch(getListPeopleFailure(error));
    }
  };
};


const getPeopleByRcRequest = () => ({
  type: actionTypes.GET_PEOPLE_BY_RC_REQUEST
});

const getPeopleByRcSuccess = (res) => ({
  type: actionTypes.GET_PEOPLE_BY_RC_SUCCESS,
  res
});

const getPeopleByRcFailure = (error) => ({
  type: actionTypes.GET_PEOPLE_BY_RC_FAILURE,
  error
});

export const getPeopleByRc = (icoRc) => {
  return async (dispatch) => {
    try {
      dispatch(getPeopleByRcRequest());
      let res = await accountUtils.getPeopleByRc(icoRc);
      dispatch(getPeopleByRcSuccess(res));
    } catch (error) {
      dispatch(getPeopleByRcFailure(error));
    }
  };
};


const getPersonRequest = () => ({
  type: actionTypes.GET_PERSON_REQUEST
});

const getPersonSuccess = (res) => ({
  type: actionTypes.GET_PERSON_SUCCESS,
  res
});

const getPersonFailure = (error) => ({
  type: actionTypes.GET_PERSON_FAILURE,
  error
});

export const getPerson = (i_o) => {
  return async (dispatch) => {
    try {
      dispatch(getPersonRequest());
      let res = await accountUtils.getPerson(i_o);
      dispatch(getPersonSuccess(res));
    } catch (error) {
      dispatch(getPersonFailure(error));
    }
  };
};

// const SetPermitVerifyRequest = () => ({
//   type: actionTypes.SET_PERMIT_VERIFY_REQUEST
// });

// const SetPermitVerifySuccess = (res) => ({
//   type: actionTypes.SET_PERMIT_VERIFY_SUCCESS,
//   res
// });

// const SetPermitVerifyFailure = (error) => ({
//   type: actionTypes.SET_PERMIT_VERIFY_FAILURE,
//   error
// });

// export const SetPermitVerify = (iZiadost) => {
//   return async (dispatch) => {
//     try {
//       dispatch(SetPermitVerifyRequest());
//       let res = await accountUtils.setPermitVerify(iZiadost);
//       dispatch(SetPermitVerifySuccess(res));
//       return true;
//     } catch (error) {
//       dispatch(SetPermitVerifyFailure(error));
//       return false;
//     }
//   }
// }

const AddVerifyEmailRequest = () => ({
  type: actionTypes.ADD_VERIFY_EMAIL_REQUEST
});

const AddVerifyEmailSuccess = (res) => ({
  type: actionTypes.ADD_VERIFY_EMAIL_SUCCESS,
  res
});

const AddVerifyEmailFailure = (error) => ({
  type: actionTypes.ADD_VERIFY_EMAIL_FAILURE,
  error
});

export const AddVerifyEmail = (RcToken, Email, iVerify, code) => {
  return async (dispatch) => {
    try {
      dispatch(AddVerifyEmailRequest());
      let res = await accountUtils.addVerifyEmail(RcToken, Email, iVerify, code);
      dispatch(AddVerifyEmailSuccess(res));
      return true;
    } catch (error) {
      dispatch(AddVerifyEmailFailure(error));
      return false;
    }
  };
};

const UpdVerifyEmailRequest = () => ({
  type: actionTypes.UPD_VERIFY_EMAIL_REQUEST
});

const UpdVerifyEmailSuccess = (res) => ({
  type: actionTypes.UPD_VERIFY_EMAIL_SUCCESS,
  res
});

const UpdVerifyEmailFailure = (error) => ({
  type: actionTypes.UPD_VERIFY_EMAIL_FAILURE,
  error
});

export const UpdVerifyEmail = (iVer) => {
  return async (dispatch) => {
    try {
      dispatch(UpdVerifyEmailRequest());
      let res = await accountUtils.updVerifyEmail(iVer);
      dispatch(UpdVerifyEmailSuccess(res));
      return true;
    } catch (error) {
      dispatch(UpdVerifyEmailFailure(error));
      return false;
    }
  };
};


const getVerifyEmailRequest = () => ({
  type: actionTypes.GET_VERIFY_EMAIL_REQUEST
});

const getVerifyEmailSuccess = (res) => ({
  type: actionTypes.GET_VERIFY_EMAIL_SUCCESS,
  res
});

const getVerifyEmailFailure = (error) => ({
  type: actionTypes.GET_VERIFY_EMAIL_FAILURE,
  error
});

export const GetVerifyEmail = (iVer) => {
  return async (dispatch) => {
    try {
      dispatch(getVerifyEmailRequest());
      let res = await accountUtils.getVerifyEmail(iVer);
      dispatch(getVerifyEmailSuccess(res));
    } catch (error) {
      dispatch(getVerifyEmailFailure(error));
    }
  };
};

export const GetVerifyEmailM = (mail) => {
  return async (dispatch) => {
    try {
      dispatch(getVerifyEmailRequest());
      let res = await accountUtils.getVerifyEmailM(mail);
      dispatch(getVerifyEmailSuccess(res));
    } catch (error) {
      dispatch(getVerifyEmailFailure(error));
    }
  };
};

const getActiveQuickActionsRequest = () => ({
  type: actionTypes.GET_ACTIVE_QUICK_ACTIONS_REQUEST
});

const getActiveQuickActionsSuccess = (res) => ({
  type: actionTypes.GET_ACTIVE_QUICK_ACTIONS_SUCCESS,
  res
});

const getActiveQuickActionsFailure = (error) => ({
  type: actionTypes.GET_ACTIVE_QUICK_ACTIONS_FAILURE,
  error
});

export const getListActiveQuickActions = () => {
  return async (dispatch) => {
    try {
      dispatch(getActiveQuickActionsRequest());
      let res = await accountUtils.getActiveQuickActions();
      dispatch(getActiveQuickActionsSuccess(res));
    } catch (error) {
      dispatch(getActiveQuickActionsFailure(error));
    }
  };
};