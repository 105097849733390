import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class PetitionsIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="11,3 13,3 13,18 
		2,18 2,3 4,3 	"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="16" y1="4" x2="16" y2="15" />
          <polygon points="16,16.2 15.9,16 16.1,16" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="2" x2="10" y2="2" />
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="6.5" x2="9" y2="6.5" />
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="8.5" x2="9" y2="8.5" />
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="11.5" x2="9" y2="11.5" />
        </g>
      </SvgIcon>
    );
  }
}

PetitionsIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default PetitionsIconComponent;
