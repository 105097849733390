import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class BudgetIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M12,5h4c0.6,0,1,0.4,1,1v4
              h1v3h-1v2c0,0.6-0.4,1-1,1H2c-0.6,0-1-0.4-1-1V6c0-0.6,0.4-1,1-1h6"/>
          <polyline fill="none" strokeLinejoin="round" strokeMiterlimit="10" points="3,7.5 6.4,7.5 16,7.5 	" />
          <polyline fill="none" strokeLinejoin="round" strokeMiterlimit="10" points="16,10.5 12.5,10.5 12.5,12.5 
              16,12.5 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="5.8,7.3 
              10.3,2.8 14.8,7.3 	"/>
        </g>
      </SvgIcon>
    );
  }
}

BudgetIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default BudgetIconComponent;
