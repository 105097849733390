import React from 'react';
import PropTypes from 'prop-types';
import NavigationNext from '@mui/icons-material/NavigateNext';

class NavigationNextComponent extends React.Component {
  render() {
    return (
      <NavigationNext
        style={this.props.style}
        color={this.props.color}
      />
    )
  }
}

NavigationNextComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
}

export default NavigationNextComponent;