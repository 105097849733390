import actionTypes from './actionTypes';
import * as locatorUtils from '../utils/locatorUtils';

// *** getZstZsTree ***

const getZstZsTreeRequest = () => ({
  type: actionTypes.GET_ZST_ZS_TREE_REQUEST
});

const getZstZsTreeSuccess = (res) => ({
  type: actionTypes.GET_ZST_ZS_TREE_SUCCESS,
  res
});

const getZstZsTreeFailure = (error) => ({
  type: actionTypes.GET_ZST_ZS_TREE_FAILURE,
  error
});

/**
 * Získanie zoznamu Životných situácií s odpovedajúcimi službami
 * @returns GridItemsVm of ZstZsVm
 */
export const getZstZsTree = () => {
  return async (dispatch) => {
    try {
      dispatch(getZstZsTreeRequest());
      let res = await locatorUtils.getZstZsTree();
      dispatch(getZstZsTreeSuccess(res));
    } catch (error) {
      dispatch(getZstZsTreeFailure(error));
    }
  };
};


// *** getZstZsList ***

const getZstZsListRequest = () => ({
  type: actionTypes.GET_ZST_ZS_LIST_REQUEST
});

const getZstZsListSuccess = (res) => ({
  type: actionTypes.GET_ZST_ZS_LIST_SUCCESS,
  res
});

const getZstZsListFailure = (error) => ({
  type: actionTypes.GET_ZST_ZS_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu Životných situácií
 * @returns GridItemsVm of ZstZsVm
 */
export const getZstZsList = () => {
  return async (dispatch) => {
    try {
      dispatch(getZstZsListRequest());
      let res = await locatorUtils.getZstZsList();
      dispatch(getZstZsListSuccess(res));
    } catch (error) {
      dispatch(getZstZsListFailure(error));
    }
  };
};


// *** getZstSlzList ***

const getZstSlzListRequest = () => ({
  type: actionTypes.GET_ZST_SLZ_LIST_REQUEST
});

const getZstSlzListSuccess = (res) => ({
  type: actionTypes.GET_ZST_SLZ_LIST_SUCCESS,
  res
});

const getZstSlzListFailure = (error) => ({
  type: actionTypes.GET_ZST_SLZ_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu služieb Životných situácií
 * @returns GridItemsVm of ZstSlzVm
 */
export const getZstSlzList = (flt) => {
  return async (dispatch) => {
    try {
      dispatch(getZstSlzListRequest(flt));
      let res = await locatorUtils.getZstSlzList(flt);
      dispatch(getZstSlzListSuccess(res));
    } catch (error) {
      dispatch(getZstSlzListFailure(error));
    }
  };
};


// *** getZstCDtzOtzList ***

const getZstCDtzOtzListRequest = () => ({
  type: actionTypes.GET_ZST_C_DTZ_OTZ_LIST_REQUEST
});

const getZstCDtzOtzListSuccess = (res) => ({
  type: actionTypes.GET_ZST_C_DTZ_OTZ_LIST_SUCCESS,
  res
});

const getZstCDtzOtzListFailure = (error) => ({
  type: actionTypes.GET_ZST_C_DTZ_OTZ_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu otázok dotazníka služby životnej situácie
 * @returns GridItemsVm of ZstCDtzOtzVm
 */
export const getZstCDtzOtzList = (flt) => {
  return async (dispatch) => {
    try {
      dispatch(getZstCDtzOtzListRequest(flt));
      let res = await locatorUtils.getZstCDtzOtzList(flt);
      dispatch(getZstCDtzOtzListSuccess(res));
    } catch (error) {
      dispatch(getZstCDtzOtzListFailure(error));
    }
  };
};


// *** getZstLsOtzList ***

const getZstLsOtzListRequest = () => ({
  type: actionTypes.GET_ZST_LS_OTZ_LIST_REQUEST
});

const getZstLsOtzListSuccess = (res) => ({
  type: actionTypes.GET_ZST_LS_OTZ_LIST_SUCCESS,
  res
});

const getZstLsOtzListFailure = (error) => ({
  type: actionTypes.GET_ZST_LS_OTZ_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu otázok dotazníka lokátora službieb životných situácii
 * @returns GridItemsVm of ZstLsOtzVm
 */
export const getZstLsOtzList = (flt) => {
  return async (dispatch) => {
    try {
      dispatch(getZstLsOtzListRequest(flt));
      let res = await locatorUtils.getZstLsOtzList(flt);
      dispatch(getZstLsOtzListSuccess(res));
    } catch (error) {
      dispatch(getZstLsOtzListFailure(error));
    }
  };
};

// *** getSlzAktList ***

const getSlzAktListRequest = () => ({
  type: actionTypes.GET_SLZ_AKT_LIST_REQUEST
});

const getSlzAktListSuccess = (res) => ({
  type: actionTypes.GET_SLZ_AKT_LIST_SUCCESS,
  res
});

const getSlzAktListFailure = (error) => ({
  type: actionTypes.GET_SLZ_AKT_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu aktualnych službieb životných situácii
 * @returns GridItemsVm of ZstLsOtzVm
 */
export const getSlzAktList = (flt) => {
  return async (dispatch) => {
    try {
      dispatch(getSlzAktListRequest(flt));
      let res = await locatorUtils.getSlzAktList(flt);
      dispatch(getSlzAktListSuccess(res));
    } catch (error) {
      dispatch(getSlzAktListFailure(error));
    }
  };
};

// *** getSlzUsedList ***

const getSlzUsedListRequest = () => ({
  type: actionTypes.GET_SLZ_USED_LIST_REQUEST
});

const getSlzUsedListSuccess = (res) => ({
  type: actionTypes.GET_SLZ_USED_LIST_SUCCESS,
  res
});

const getSlzUsedListFailure = (error) => ({
  type: actionTypes.GET_SLZ_USED_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu casto vyhladavanych sluzieb životných situácii
 * @returns GridItemsVm of ZstLsOtzVm
 */
export const getSlzUsedList = (flt) => {
  return async (dispatch) => {
    try {
      dispatch(getSlzUsedListRequest(flt));
      let res = await locatorUtils.getSlzUsedList(flt);
      dispatch(getSlzUsedListSuccess(res));
    } catch (error) {
      dispatch(getSlzUsedListFailure(error));
    }
  };
};

// *** getSlzUsedUserList ***

const getSlzUsedUserListRequest = () => ({
  type: actionTypes.GET_SLZ_USED_USER_LIST_REQUEST
});

const getSlzUsedUserListSuccess = (res) => ({
  type: actionTypes.GET_SLZ_USED_USER_LIST_SUCCESS,
  res
});

const getSlzUsedUserListFailure = (error) => ({
  type: actionTypes.GET_SLZ_USED_USER_LIST_FAILURE,
  error
});

/**
 * Získanie zoznamu naposledy vyhladavanych sluzieb životných situácii userom
 * @returns GridItemsVm of ZstLsOtzVm
 */
export const getSlzUsedUserList = (flt) => {
  return async (dispatch) => {
    try {
      dispatch(getSlzUsedUserListRequest(flt));
      let res = await locatorUtils.getSlzUsedList(flt);
      dispatch(getSlzUsedUserListSuccess(res));
    } catch (error) {
      dispatch(getSlzUsedUserListFailure(error));
    }
  };
};
