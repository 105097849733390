import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Select from '@mui/material/Select';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Paper from '../../coraWebMComponents/surfaces/Paper';

const styles = theme => ({
  ...getSharedStyles(theme),
  selectRoot: {
    background: 'transparent!important',
    display: 'flex',
    alignItems: 'center'
  }
});

const BootstrapInput = withStyles(theme => ({
  root: {

  },

}))(InputBase);


class SimpleSelectFilterComponent extends React.Component {
  render() {
    const { schema, data, required, name, classes, showIcon } = this.props;
    return (
      <div className={classNames(classes.row, classes.justifyContentEnd, classes.w100)} style={this.props.style}>
        <Paper elevation={2} className={classNames(classes.paperWrapper, classes.pl2, classes.pr2)}>
          <FormControl
            disabled={this.props.disabled}
            error={this.props.error}
            className={this.props.className}
          >
            {/* <InputLabel htmlFor={name} >{floatingLabelText}</InputLabel> */}
            <Select
              value={this.props.value || ''}
              onChange={event => this.props.onChange(name, event.target.value)}
              inputProps={{ id: name }}
              displayEmpty
              classes={{ select: classes.selectRoot }}
              input={<BootstrapInput name="filter" />}
            >
              {!required &&
                <MenuItem value=''>{this.props.defaultLabelText}</MenuItem>
              }
              {data.map(x =>
                <MenuItem key={x[schema.value]} value={x[schema.value]}>
                  {showIcon &&
                    <img
                      style={this.props.iconStyle}
                      src={x[schema.icon]}
                      alt={x[schema.value]}
                    />}
                  {x[schema.label]}</MenuItem>)
              }
            </Select>
            {this.props.error && <FormHelperText>{this.props.errorText}</FormHelperText>}
          </FormControl>
        </Paper>
      </div>
    );
  }
}

SimpleSelectFilterComponent.propTypes = {
  disabled: PropTypes.bool,
  floatingLabelText: PropTypes.string,
  defaultLabelText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func,
  fullwidth: PropTypes.bool,
  data: PropTypes.array.isRequired,
  schema: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string
  }).isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
  iconStyle: PropTypes.object,
  showIcon: PropTypes.bool
};
export default withStyles(styles)(SimpleSelectFilterComponent);