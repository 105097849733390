import React from 'react';
import DoneAll from '@mui/icons-material/DoneAll';
import PropTypes from 'prop-types';

class DoneAllComponent extends React.Component {
  render() {
    return (
      <DoneAll
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    );
  }
}

DoneAllComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
};

export default DoneAllComponent;