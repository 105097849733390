import React from 'react';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import PropTypes from 'prop-types';


class InsertDriveFileOutlinedComponent extends React.Component {
  render() {
    return (
      <InsertDriveFileOutlined style={this.props.style} />

    )
  }
}

InsertDriveFileOutlinedComponent.propTypes = {
  style: PropTypes.object,
}

export default InsertDriveFileOutlinedComponent; 