import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
};

const listProgRozp = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_PROG_ROZP_REQUEST:
      return { isLoading: true };

    case actionTypes.GET_LIST_PROG_ROZP_SUCCESS:
      return { isLoading: false };

    case actionTypes.GET_LIST_PROG_ROZP_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default listProgRozp;