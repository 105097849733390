import { useState, forwardRef, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ExpandIcon from '@mui/icons-material/AddBox';
import ReduceIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import EndIcon from '@mui/icons-material/DisabledByDefaultOutlined';

import "./treeViewComponent.css";

import TreeViewControl from './TreeViewControl';
import sk from './sk.json';
import * as rozpocetActions from '../../actions/rozpocetActions';

const LOCALE = sk.treeViewComponent;
/**
 * @param {*} { 
 *    initProgRozp - init data z reducera
 *    type - typ rozpoctu "INC"=Prijmy; "EXP"=Vydaje; "SUM"=Sumar
 *    data - data rozpoctu vo formate pre TreeView
 *    maxLevel - max. povoleny level na zobrazenie 
 *    expandedNodes - zoznam rozbalenych uzlov v TreeView
 *    searchedNodes - zoznam uzlov kde sa nasiel fulltext
 *    RowDetail - komponent pre detail jedneho zaznamu
 *    autoExpandToLevel - level do akeho sa automaticky rozbali TreeView
 *    themeCustom - referencia na custom temu, pre riadenie breakpoints
 *    fullText - hladany text po fulltext
 *    classes - nazvy class pre CSS styly
 * }
 * @return {*} 
 */
const TreeViewComponent = ({ initProgRozp, type, data = {}, maxLevel = 1, expandedNodes = [], searchedNodes = null, RowDetail, autoExpandToLevel = 1, themeCustom, fullText = '', classes = '' }) => {
  const CFG_PG = initProgRozp;
  const SORT_ORDER = CFG_PG?.sortOrder;
  const MAX_AUTO_EXPAND_TO_LEVEL = CFG_PG?.maxAutoExpandToLevel;
  const MAX_EXPAND_TO_LEVEL = CFG_PG?.maxExpandToLevel;
  const EXCLUDED_LEVELS = useMemo(() =>
    CFG_PG?.excludedLevels ? CFG_PG?.excludedLevels : [3, 4, 5]
    , [CFG_PG.excludedLevels]);

  /**
   *  Uprav level pre pull-down podla typu
   */
  const fixAutoExpandToLevel = (level, type, forcedLevel = false) => {
    // nepovol rozbalit po viac ako MAX_AUTO_EXPAND_TO_LEVEL level, ale ak je forcedLevel tak povol
    level = level > MAX_AUTO_EXPAND_TO_LEVEL ? (forcedLevel ? level : MAX_AUTO_EXPAND_TO_LEVEL) : level;
    // uprav level pre prijmy, lebo prijmy nemaju level 3 az 5
    if (type === "INC" && EXCLUDED_LEVELS.includes(level)) {
      // ignoruj levely 3-5 pre Prijmy
      level = 2;
    }
    return level;
  };

  const [expanded, setExpanded] = useState([]);
  const [currentExpandedInc, setCurrentExpandedInc] = useState(fixAutoExpandToLevel(autoExpandToLevel, type));

  /**
   * Vrati pole povolenych urovni
   */
  const getAllovedLevels = (maxLevel, type) => {
    let excludedLevels = [];
    if (type === "INC") {
      excludedLevels = EXCLUDED_LEVELS;
    }
    let level = maxLevel > MAX_EXPAND_TO_LEVEL ? MAX_EXPAND_TO_LEVEL : maxLevel;
    return Array.from({ length: level }, (_, index) => index + 1)
      .filter(number => !excludedLevels.includes(number));
  };

  let allovedLevels = getAllovedLevels(maxLevel, type);

  /**
   *  Uprav level pre regExp filter podla typu
   */
  const fixFilterLevel = useCallback((level, type, forcedLevel = false) => {
    // nepovol rozbalit po viac ako MAX_AUTO_EXPAND_TO_LEVEL level, ale ak je forcedLevel tak povol
    level = level > MAX_AUTO_EXPAND_TO_LEVEL ? (forcedLevel ? level : MAX_AUTO_EXPAND_TO_LEVEL) : level;
    // uprav level pre prijmy, lebo prijmy nemaju level 3 az 5
    if (type === "INC" && EXCLUDED_LEVELS.includes(level)) {
      // ignoruj levely 3-5 pre Prijmy
      level = 2;
    } else if (type === "INC" && level >= Math.max(...EXCLUDED_LEVELS)) {
      // pre level 6,7,8,9 v indexe pre Prijmy je bodiek o 3 menej
      level -= 3;
    }
    return level;
  }, [EXCLUDED_LEVELS, MAX_AUTO_EXPAND_TO_LEVEL]);

  // Prepocet, ktore uzly sa maju rozbalit
  useEffect(() => {
    let newExpanded = [];
    if (!searchedNodes) {
      // ak NIE JE aktivovany fulltext search
      const filterLevel = fixFilterLevel(autoExpandToLevel, type);
      const regex = new RegExp(`^(?:[^.]*\\.){0,${filterLevel - 2}}[^.]*$`); //retazce z poctom bodiek: nula az autoExpandToLevel 
      newExpanded = expandedNodes.filter(str => regex.test(str));
    } else {
      // ak JE aktivovany fulltext search
      newExpanded = [...searchedNodes];
    }
    setExpanded(newExpanded);
  }, [autoExpandToLevel, expandedNodes, type, searchedNodes, fixFilterLevel]);

  const handleClickExpanded = () => {
    const filterLevel = fixFilterLevel(currentExpandedInc, type, true);
    // najdi ID s pzadovanym alebo mensim poctom bodiek; napr. ak hladam 2 bodky, najde ['X', 'X.YY', 'X.YY.YY']
    const regex = new RegExp(`^(?:[^.]*\\.){0,${filterLevel - 2}}[^.]*$`);
    let newExpanded = expandedNodes.filter(str => regex.test(str));
    setExpanded(() => newExpanded);
  };

  const handleClickUnexpanded = () => {
    setExpanded(() => []);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  // Prenos referenciie na RowDetail
  const RowDetailRef = forwardRef(function RowDetailContent(props, ref) {
    const { classes, nodeId, icon: iconProp, expansionIcon, displayIcon, RowDetail, item, count, isTitle, customClass, themeCustom, fullText = '' } = props;
    const { handleExpansion } = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    return (
      <Stack
        direction={'row'}
        className={clsx(classes, "tree-view-row-detail-wrapper")}
        ref={ref}
      >
        {isTitle ? null : (
          <Box
            onClick={handleExpansionClick}
            className={clsx(classes.iconContainer, "tree-view-row-detail-icon")}
          >
            {icon}
          </Box>
        )}
        <RowDetail
          handleClick={handleExpansionClick}
          item={item}
          count={count}
          isTitle={isTitle}
          customClass={customClass}
          themeCustom={themeCustom}
          fullText={fullText}
        />
      </Stack>
    );
  });

  // Prenos referenciie
  const CustomTreeItem = forwardRef(function TreeItemContent(props, ref) {
    return <TreeItem ContentComponent={RowDetailRef} {...props} ref={ref} />;
  });



  return (
    <>
      {/* {console.time("RETURN-time")} */}
      <Box className={classes}>
        {(type === "INC" || type === "EXP") &&
          <Box className='tree-view-controll-wrapper'>
            <TreeViewControl
              currentExpanded={currentExpandedInc}
              setCurrentExpanded={setCurrentExpandedInc}
              allovedLevels={allovedLevels}
              handleClickExpanded={handleClickExpanded}
              handleClickUnexpanded={handleClickUnexpanded}
              themeCustom={themeCustom}
            />
          </Box>
        }
        <Box>
          <TreeView
            defaultCollapseIcon={<ReduceIcon />}
            defaultExpandIcon={<ExpandIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
            defaultEndIcon={<EndIcon sx={{ color: '#AAA', cursor: 'no-drop' }} className='tree-view-end-icon' />}
          >
            {/* Hlavicka */}
            {(type === "INC" || type === "EXP" || type === "SUM") &&
              <CustomTreeItem
                className="tree-view-title"
                style={{ padding: "0.2em 0 0.2em 0" }}
                key={0}
                nodeId={'0'}
                ContentProps={{
                  RowDetail,
                  item: { title: LOCALE.headTitle, R_SCHVAL: LOCALE.headSchval, R_UPRAV: LOCALE.headUprav, R_SKUT: LOCALE.headPlnennie, percentoPlnenia: LOCALE.headPercentoPlnenia },
                  isTitle: true,
                  themeCustom
                }}
              />
            }

            {/* Verzia pre Prijmy */}
            {type === "INC" &&
              data.level1?.detail.filter(item => item.parrentId === null).sort().map((itemL1, index) => (
                <CustomTreeItem className={`l${itemL1.level}`} key={index} nodeId={itemL1.id}
                  ContentProps={{ RowDetail, item: itemL1, themeCustom, fullText }}
                >
                  {
                    data.level2?.detail.filter(item => item.parrentId === `${itemL1.id}`).sort((a, b) => {
                      return (a.sortKey - b.sortKey) * SORT_ORDER;
                    }).map((itemL2, index) => (
                      <CustomTreeItem className={`l${itemL2.level}`} key={index} nodeId={itemL2.id}
                        ContentProps={{ RowDetail, item: itemL2, themeCustom, fullText }}
                      >
                        {
                          maxLevel >= 6 && data.level6?.detail.filter(item => item.parrentId === `${itemL2.id}`).sort((a, b) => {
                            return (a.sortKey - b.sortKey) * SORT_ORDER;
                          }).map((itemL6, index) => (
                            <CustomTreeItem className={`l${itemL6.level}`} key={index} nodeId={itemL6.id}
                              ContentProps={{ RowDetail, item: itemL6, themeCustom, fullText }}
                            >
                              {
                                maxLevel >= 7 && data.level7?.detail.filter(item => item.parrentId === `${itemL6.id}`).sort((a, b) => {
                                  return (a.sortKey - b.sortKey) * SORT_ORDER;
                                }).map((itemL7, index) => (
                                  <CustomTreeItem className={`l${itemL7.level}`} key={index} nodeId={itemL7.id}
                                    ContentProps={{ RowDetail, item: itemL7, themeCustom, fullText }}
                                  >
                                    {
                                      maxLevel >= 8 && data.level8?.detail.filter(item => item.parrentId === `${itemL7.id}`).sort((a, b) => {
                                        return (a.sortKey - b.sortKey) * SORT_ORDER;
                                      }).map((itemL8, index) => (
                                        <CustomTreeItem className={`l${itemL8.level}`} key={index} nodeId={itemL8.id}
                                          ContentProps={{ RowDetail, item: itemL8, count: itemL8.children.length, themeCustom, fullText }}
                                        >
                                          {
                                            maxLevel >= 9 && data.level9?.detail.filter(item => item.parrentId === `${itemL8.id}`).sort((a, b) => {
                                              return (a.sortKey - b.sortKey) * SORT_ORDER;
                                            }).map((item) => (
                                              <RowDetail customClass="l9" key={item.id} item={item} themeCustom={themeCustom} fullText={fullText} />
                                              // <CustomTreeItem className={`l9`} key={index} nodeId={itemL9.id} label={itemL9.title}
                                              //   ContentProps={{ RowDetail, item: itemL9, themeCustom, fullText }}
                                              // />
                                            ))
                                          }
                                        </CustomTreeItem>
                                      ))
                                    }
                                  </CustomTreeItem>
                                ))
                              }
                            </CustomTreeItem>
                          ))
                        }
                      </CustomTreeItem>
                    ))
                  }
                </CustomTreeItem>
              ))
            }

            {/* Verzia pre Vydavky */}
            {type === "EXP" &&
              data.level1?.detail.filter(item => item.parrentId === null).sort().map((itemL1, index) => (
                <CustomTreeItem className={`l${itemL1.level}`} key={index} nodeId={itemL1.id}
                  ContentProps={{ RowDetail, item: itemL1, themeCustom, fullText }}
                >
                  {
                    data.level2?.detail.filter(item => item.parrentId === `${itemL1.id}`).sort((a, b) => {
                      return (a.sortKey - b.sortKey) * SORT_ORDER;
                    }).map((itemL2, index) => (
                      <CustomTreeItem className={`l${itemL2.level}`} key={index} nodeId={itemL2.id}
                        ContentProps={{ RowDetail, item: itemL2, themeCustom, fullText }}
                      >
                        {
                          maxLevel >= 3 && data.level3?.detail.filter(item => item.parrentId === `${itemL2.id}`).sort((a, b) => {
                            return (a.sortKey - b.sortKey) * SORT_ORDER;
                          }).map((itemL3, index) => (
                            <CustomTreeItem className={`l${itemL3.level}`} key={index} nodeId={itemL3.id}
                              ContentProps={{ RowDetail, item: itemL3, themeCustom, fullText }}
                            >
                              {
                                maxLevel >= 4 && data.level4?.detail.filter(item => item.parrentId === `${itemL3.id}`).sort((a, b) => {
                                  return (a.sortKey - b.sortKey) * SORT_ORDER;
                                }).map((itemL4, index) => (
                                  <CustomTreeItem className={`l${itemL4.level}`} key={index} nodeId={itemL4.id}
                                    ContentProps={{ RowDetail, item: itemL4, themeCustom, fullText }}
                                  >
                                    {
                                      maxLevel >= 5 && data.level5?.detail.filter(item => item.parrentId === `${itemL4.id}`).sort((a, b) => {
                                        return (a.sortKey - b.sortKey) * SORT_ORDER;
                                      }).map((itemL5, index) => (
                                        <CustomTreeItem className={`l${itemL5.level}`} key={index} nodeId={itemL5.id}
                                          ContentProps={{ RowDetail, item: itemL5, themeCustom, fullText }}
                                        >
                                          {
                                            maxLevel >= 6 && data.level6?.detail.filter(item => item.parrentId === `${itemL5.id}`).sort((a, b) => {
                                              return (a.sortKey - b.sortKey) * SORT_ORDER;
                                            }).map((itemL6, index) => (
                                              <CustomTreeItem className={`l${itemL6.level}`} key={index} nodeId={itemL6.id}
                                                ContentProps={{ RowDetail, item: itemL6, themeCustom, fullText }}
                                              >
                                                {
                                                  maxLevel >= 7 && data.level7?.detail.filter(item => item.parrentId === `${itemL6.id}`).sort((a, b) => {
                                                    return (a.sortKey - b.sortKey) * SORT_ORDER;
                                                  }).map((itemL7, index) => (
                                                    <CustomTreeItem className={`l${itemL7.level}`} key={index} nodeId={itemL7.id}
                                                      ContentProps={{ RowDetail, item: itemL7, themeCustom, fullText }}
                                                    >
                                                      {
                                                        maxLevel >= 8 && data.level8?.detail.filter(item => item.parrentId === `${itemL7.id}`).sort((a, b) => {
                                                          return (a.sortKey - b.sortKey) * SORT_ORDER;
                                                        }).map((itemL8, index) => (
                                                          <CustomTreeItem className={`l${itemL8.level}`} key={index} nodeId={itemL8.id}
                                                            ContentProps={{ RowDetail, item: itemL8, count: itemL8.children.length, themeCustom, fullText }}
                                                          >
                                                            {
                                                              maxLevel >= 9 && data.level9?.detail.filter(item => item.parrentId === `${itemL8.id}`).sort((a, b) => {
                                                                return (a.sortKey - b.sortKey) * SORT_ORDER;
                                                              }).map((item) => (
                                                                <RowDetail customClass="l9" key={item.id} item={item} themeCustom={themeCustom} fullText={fullText} />
                                                                // <CustomTreeItem className={`l9`} key={index} nodeId={itemL9.id} label={itemL9.title}
                                                                //   ContentProps={{ RowDetail, item: itemL9, themeCustom, fullText }}
                                                                // />
                                                              ))
                                                            }
                                                          </CustomTreeItem>
                                                        ))
                                                      }
                                                    </CustomTreeItem>
                                                  ))
                                                }
                                              </CustomTreeItem>
                                            ))
                                          }
                                        </CustomTreeItem>
                                      ))
                                    }
                                  </CustomTreeItem>
                                ))
                              }
                            </CustomTreeItem>
                          ))
                        }
                      </CustomTreeItem>))
                  }
                </CustomTreeItem>
              ))
            }

            {/* Verzia pre Prijmy - Vydavky */}
            {type === "SUM" &&
              data.level1?.detail.filter(item => item.parrentId === null).map((itemL1, index) => (
                <RowDetail customClass={`l${itemL1.level}-pv`} key={index} nodeId={itemL1.id}
                  // ContentProps={{ RowDetail, item: itemL1, themeCustom, fullText }}
                  item={itemL1} themeCustom={themeCustom} fullText={fullText}
                >
                </RowDetail>
                //<CustomTreeItem className={`l${itemL1.level}`} key={index} nodeId={itemL1.id}
                //   ContentProps={{ RowDetail, item: itemL1, themeCustom, fullText }}
                //>
                // </CustomTreeItem>
              ))
            }
          </TreeView>
        </Box>
      </Box>
      {/* {console.timeEnd("RETURN-time")} */}
    </>
  );
};

TreeViewComponent.propTypes = {
};

const mapStateToProps = (state) => ({
  isInitLoading: state.initProgRozp.isLoading,
  initProgRozp: state.initProgRozp.data,
});

const mapDispatchToProps = (dispatch) => ({
  getInitProgRozp: () => dispatch(rozpocetActions.getInitProgRozp()),
});

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TreeViewComponent));
export default connect(mapStateToProps, mapDispatchToProps)(TreeViewComponent);