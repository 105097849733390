import React from "react";
import PropTypes from "prop-types";
import { SortDirection /*, SortIndicator*/ } from "react-virtualized";

import withWidth from "@material-ui/core/withWidth";
import Stack from '@mui/material/Stack';

import CircularProgressV2 from "../../coraWebMComponents/feedBack/progress/CircularProgressV2";

import DetailDialog from "./DetailDialog";
import PodFrm from "./PodFrm";
import ComboFilterDialog from "./ComboFilterDialog";
import { getDetailTitle, getGridVisibleColumns } from "./support";
import FrmHeader from "./FrmHeader";
import Grid from "./Grid";
import OptionsButton from "./OptionsButton";
import ExportBtnDialog from "./ExportBtnDialog";
import ScrollTopButton from "../info/ScrollTopButton";
import { createDevButton, getInitDevFlt } from "./DevInfo";

export const NUMBER_OF_PAGE = 10;

class FrmComponent extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    const frmState = this.getInitFrmState(props);
    const devFlt = getInitDevFlt(props.schema.Frms);
    this.state = {
      detailOpen: false,
      exportBtnDialogOpen: false,
      selectedId: -1,
      podFrmId: null,
      cboFltId: null,
      frmState,
      loadingMore: false,
      showScrollTopButton: false,
      showScrollTopRef: null,
      loadingExport: false,
      devFlt
    };

    this.onScroll = this.onScroll.bind(this);
  }

  /** Úprava schémy po načítaní zo servera */
  getInitFrmState = (props) => {
    const { schema } = props;
    const cboFltValues = {};
    const { comboFilters } = schema.Args;

    comboFilters.map((x) => {
      let actValue = "";
      if (x.noItem && !x.noItem.disabled && x.noItem.value) {
        actValue = x.noItem.value;
      } else {
        actValue = x.dataItems[0].value;
      }
      cboFltValues[x.id] = actValue;
      return x;
    });
    return { sort: [], cboFltValues, searchText: null };
  };

  getFilter = (
    cboFltValues = this.state.frmState.cboFltValues,
    searchText = this.state.frmState.searchText
  ) => {
    const { schema } = this.props;
    const { comboFilters } = schema.Args;

    const filter = { filters: [] };
    comboFilters.map((x) => {
      const actValue = cboFltValues[x.id];
      if (actValue && !(x.noItem && !x.noItem.disabled && x.noItem.value && x.noItem.value === actValue)) {
        filter.filters.push({ field: x.identif, operator: "eq", value: actValue });
      }
      return x;
    });
    if (Boolean(searchText) && searchText.length > 0) {
      let flt = { operator: "contains", value: searchText, left: "(" };
      let fltAct;
      const columns = getGridVisibleColumns(schema.Grid, true);
      columns
        .filter((x) => x.type === "text")
        .map((x) => {
          fltAct = { field: x.field, ...flt };
          filter.filters.push(fltAct);
          flt = { operator: "contains", value: searchText, join: true };
          return x;
        });
      if (fltAct)
        fltAct.right = ")";
    }
    return filter;
  };

  getSort = (sort) => sort.length > 0 ? sort : this.props.schema.initSort;

  handleSetDevFilter = (fldName, bValue) => {
    const { devFlt } = this.state;
    if (devFlt[fldName] !== bValue) {
      devFlt[fldName] = bValue;
      this.setState({ ...this.state, devFlt });
    }
  };

  createHeaderRowOptionsButton = () => {
    if (window.config.isDev) {
      const { primaryField, schema, frmData } = this.props;
      const { devFlt } = this.state;
      const devProps = { devFilter: devFlt, setDevFilter: this.handleSetDevFilter, loadMoreData: this.loadMoreData };
      return createDevButton(primaryField, schema, frmData, devProps);
    }
    else {
      return "";
    }
  };

  createBodyRowOptionsButton = (rowData) => {
    const { schema } = this.props;

    const customUI = schema.Args;
    const pkValue = rowData["id"];
    const items = [];
    if (!customUI.detail.disabled) {
      items.push({
        itemText: "Detail",
        onClick: () => this.handleDetailDialogOpen(pkValue),
        disabled: false,
        itemIcon: 'InfoIcon',
      });
    }

    schema.Frms.map((x) => {
      const data = rowData[`FrmPod${x.Id}`]?.items;
      const disabled = !(data && data.length > 0);
      if (!disabled) {
        items.push({
          itemText: x.Name,
          onClick: () => this.handlePodFrmDialogOpen(x.Id, pkValue),
          disabled,
          itemIcon: 'DescriptionIcon', //TODO: urobit dynamicky podla druhu formulara
        });
      }
      // items.push({
      //   itemText: x.Name,
      //   onClick: () => this.handlePodFrmDialogOpen(x.Identif, pkValue),
      //   disabled,
      // });
      return x;
    });
    return <OptionsButton items={items} customColor={true} />;
  };

  /** Udalosť Otvorenie detailu */
  handleDetailDialogOpen = (selectedId) => {
    this.setState({ detailOpen: true, selectedId });
  };

  /** Udalosť Zatvorenie detailu */
  handleDetailClose = () => {
    this.setState({ detailOpen: false, selectedId: -1 });
  };

  /** Udalosť Otvorenie podradeného formulára */
  handlePodFrmDialogOpen = (podFrmId, selectedId) => {
    this.setState({ podFrmId, selectedId });
  };

  /** Udalosť Zatvorenie podradeného formulára */
  handlePodFrmClose = () => {
    this.setState({ podFrmId: null, selectedId: -1 });
  };

  /** Udalosť Nastavenie SearchFilter */
  handleSearchFilterChange = async (searchText) => {
    const frmState = { ...this.state.frmState, searchText };
    this.setState({ ...this.state, frmState });
    await this.props.getFrmData(
      this.getFilter(frmState.cboFltValues, frmState.searchText),
      this.getSort(frmState.sort),
      10,
      true
    );
  };

  handleJsonExportBtnClick = () => {
    const { frmData } = this.props;
    const jsonData = JSON.stringify(frmData.data, null, 2);
    this.exportData(jsonData, "data.json", "application/json");
  };

  arrayToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = `${Object.keys(array[0])
      .map((value) => `"${value}"`)
      .join(",")}\r\n`;

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        let value = array[i][index];
        if (typeof value === "string") {
          value = `"${value.replace(/"/g, '""')}"`;
        } else if (value === null) {
          value = "";
        }

        line += value;
      }

      str += `${line}\r\n`;
    }

    return str;
  }

  handleCsvExportBtnClick = () => {
    const { frmData } = this.props;
    const csvData = this.arrayToCSV(frmData.data);
    this.exportData(csvData, "data.csv", "text/csv");
  };

  handleLoadChartData = async () => {
    const { cboFltValues, searchText } = this.state.frmState;
    const filter = this.getFilter(cboFltValues, searchText);
    return await this.props.getChartData(filter);
  };

  handleGetExportData = async (fileType) => {
    this.setState((prev) => ({ ...prev, loadingExport: true }));
    const { primaryField, schema } = this.props;
    const { cboFltValues, searchText } = this.state.frmState;
    const filter = this.getFilter(cboFltValues, searchText);
    const sort = this.getSort(this.state.frmState.sort);
    const { blob, filename } = await this.props.postExportData(primaryField, filter, sort, fileType, schema);
    this.setState((prev) => ({ ...prev, loadingExport: false }));
    this.exportData(blob, filename, blob.type);
  };

  exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  handleExportBtnDialogOpen = () => {
    this.setState({ ...this.state, exportBtnDialogOpen: true });
  };

  /** Udalosť Otvorenie CboFltDialog */
  handleCboFltDialogOpen = (cboFltId) => {
    const state = { ...this.state, cboFltId };
    this.setState(state);
  };

  /** Udalosť Zatvorenie CboFltDialog */
  handleCboFltDialogClose = () => {
    // this.setState(state => ({ [primaryField]: { ...state[primaryField], cboFltId: null } }));
    const state = { ...this.state, cboFltId: null };
    this.setState(state);
  };

  handleExportBtnDialogClose = () => {
    this.setState({ ...this.state, exportBtnDialogOpen: false });
  };

  /** Udalosť výberu z objektu ComboFilter */
  handleComboFilterChange = async (name, value) => {
    const { cboFltValues } = this.state.frmState;

    let actValue = cboFltValues[name];
    if (!actValue || actValue !== value) {
      cboFltValues[name] = value;

      const frmState = { ...this.state.frmState, cboFltValues };
      this.setState({ ...this.state, frmState, cboFltId: null });
      await this.props.getFrmData(
        this.getFilter(cboFltValues, frmState.searchText),
        this.getSort(frmState.sort),
        10,
        true
      );
    } else {
      this.setState({ ...this.state, cboFltId: null });
    }
  };

  /** Udalosť Nastavenie triedenia */
  handleSortChange = async (sortBy, sortDirection = SortDirection.ASC) => {
    let sort = [];
    if (sortBy) {
      sort = [
        {
          field: sortBy,
          dir: sortDirection === SortDirection.DESC ? "desc" : "asc",
          disabled: false,
        },
      ];
    }
    const frmState = { ...this.state.frmState, sort };
    this.setState({ ...this.state, frmState });
    await this.props.getFrmData(
      this.getFilter(frmState.cboFltValues, frmState.searchText),
      this.getSort(frmState.sort),
      10,
      true
    );
  };

  onEnd = async (take = NUMBER_OF_PAGE) => {
    const { frmData } = this.props;
    if (!frmData.isLoading && frmData.hasMore) {
      const { cboFltValues, searchText } = this.state.frmState;
      const filter = this.getFilter(cboFltValues, searchText);
      const sort = this.getSort(this.state.frmState.sort);
      await this.props.getFrmData(filter, sort, take);
    }
  };

  onResize = async () => {
    const { frmData } = this.props;
    if (
      window.innerHeight > document.body.offsetHeight &&
      !frmData.isLoading &&
      frmData.hasMore
    ) {
      // await this.onEnd();
    }
  };

  loadMoreData = async () => {
    await this.onEnd(NUMBER_OF_PAGE);
  };

  onScroll = async (event) => {
    const target = event.current;
    const windowsHeight = target?.props?.height;
    const scrollTop = target?.state?.scrollTop | 0; // cele cislo
    const position = target?.props?.rowCount * target?.props?.rowHeight - windowsHeight;

    if (target?.props?.rowCount < 40 || scrollTop >= position - target?.props?.rowHeight) { // 40=pocet prvych zaznamov
      await this.onEnd(NUMBER_OF_PAGE);
    }

    if (target?.state?.scrollTop > 10 && !this.state.showScrollTopButton) {
      this.setState({ ...this.state, showScrollTopButton: true });
      this.setState((prev) => ({ ...prev, showScrollTopRef: target }));
    }
    if (target?.state?.scrollTop <= 10 && this.state.showScrollTopButton) {
      this.setState({ ...this.state, showScrollTopButton: false });
      this.setState((prev) => ({ ...prev, showScrollTopRef: target }));
    }
  };

  onScrollMobile = async (event) => {
    const target = event.target;

    if (Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) < 32) { // 32 = vyska riadku
      await this.onEnd(NUMBER_OF_PAGE);
    }
    if (target.scrollTop > 10 && !this.state.showScrollTopButton) {
      this.setState({ ...this.state, showScrollTopButton: true });
      this.setState((prev) => ({ ...prev, showScrollTopRef: target }));
    }
    if (target.scrollTop <= 10 && this.state.showScrollTopButton) {
      this.setState({ ...this.state, showScrollTopButton: false });
      this.setState((prev) => ({ ...prev, showScrollTopRef: target }));
    }
  };

  async decreaseNumberLinearly(valueUpdater, initialValue = 100, targetValue = 0, duration = 100, steps = 15) {
    const stepSize = (initialValue - targetValue) / steps;
    const timeInterval = duration / steps;

    for (let i = 0; i <= steps; i += 1) {
      valueUpdater(initialValue - i * stepSize);
      await new Promise(resolve => setTimeout(resolve, timeInterval));
    }
  }

  handleScrollTopButtonClick = async (target) => {
    // pre WebGrid
    if (target?.state?.scrollTop) {
      target.state.scrollTop = 0;
    }

    // pre MobileGrid
    if (target.scrollTop) {
      // target.scrollTop = 0;
      this.decreaseNumberLinearly(newValue => {
        target.scrollTop = newValue;
      });
    }

    // Skry tlačidlo po posune na vrch
    this.setState({ ...this.state, showScrollTopButton: false });
  };

  async componentDidMount() {
    const { cboFltValues, searchText } = this.state.frmState;
    const filter = this.getFilter(cboFltValues, searchText);
    const sort = this.getSort(this.state.frmState.sort);
    await this.props.getFrmData(filter, sort, 10, true);

    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  }

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { cboFltId, frmState, loadingExport } = this.state;
    if (!frmState) {
      return null;
    }

    const { width, primaryField, schema, frmData } = this.props;
    const pkFieldName = primaryField.split('-').pop();
    const isMobile = this.isMobile(width);
    const customUI = schema.Args;
    const { comboFilters } = customUI;
    const { isLoading, data } = frmData;
    const { cboFltValues } = frmState;
    const dataCboFlt = { showDialog: false, fltMeta: {}, fltValue: null };

    if (null != cboFltId) {
      const fltMeta = comboFilters.find((x) => x.id === cboFltId);
      if (null != fltMeta) {
        dataCboFlt.fltValue = cboFltValues[fltMeta.id];
        dataCboFlt.fltMeta = fltMeta;
        dataCboFlt.showDialog = true;
      }
    }

    const formatMask =
      customUI && customUI.detail && customUI.detail.label
        ? customUI.detail.label
        : schema.NFrm;

    //metadata pre zobrazenie detailu
    const dataDetail = {
      isOpen: false,
      columns: [],
      rowData: null,
      rowTitle: schema.NFrm,
    };

    if (this.state.detailOpen) {
      if (data.length > 0 && this.state.selectedId >= 0) {
        const rowData = data.find(
          (x) => x[pkFieldName] === this.state.selectedId
        );
        if (null != rowData) {
          dataDetail.isOpen = true;
          dataDetail.columns = schema.Detail;
          dataDetail.rowData = rowData;
          dataDetail.rowTitle = formatMask;
        }
      }
    }

    //metadata pre zobrazenie podradeného formulára
    const dataPodFrm = {
      isOpen: false,
      selectedFrm: null,
      rowData: null,
      recordTitle: null,
    };

    if (this.state.podFrmId) {
      if (data.length > 0 && this.state.selectedId > 0) {
        const rowData = data.find(
          (x) => x[pkFieldName] === this.state.selectedId
        );
        const selectedFrm = schema.Frms.find(
          (x) => x.Id === this.state.podFrmId
        );
        if (null != rowData) {
          dataPodFrm.isOpen = true;
          dataPodFrm.rowData = rowData;
          dataPodFrm.selectedFrm = selectedFrm;
          dataPodFrm.recordTitle = getDetailTitle(formatMask, rowData);
        }
      }
    }

    if (dataPodFrm.isOpen) {
      return (
        <PodFrm
          isMobile={isMobile}
          onBackClick={this.handlePodFrmClose}
          selectedFrm={dataPodFrm.selectedFrm}
          rowData={dataPodFrm.rowData}
          recordTitle={dataPodFrm.recordTitle}
        />
      );
    }

    const gridRowOptions = { createHeaderButton: this.createHeaderRowOptionsButton, createBodyButton: this.createBodyRowOptionsButton };
    const frmPodFields = Object.keys(this.state.devFlt).filter(x => this.state.devFlt[x] === true).map(x => x);

    return (
      <>
        <CircularProgressV2 loading={isLoading} top={"90%"} customColorPrimary={"#7a7a7a"} />
        <CircularProgressV2 loading={loadingExport} top={"55%"} customColorPrimary={"#7a7a7a"} />

        {/* Sipka pre scroll nahor */}
        {this.state.showScrollTopButton && (
          <ScrollTopButton onClick={() => this.handleScrollTopButtonClick(this.state.showScrollTopRef)} />
        )}

        {
          !dataPodFrm.isOpen && (
            <Stack
              // flexGrow="1"
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              height="calc(100vh - 65px)"
              sx={{}}
              px={1}
              style={{
                marginTop: "65px",
                // padding: "0 /*0.375rem*/",
                // backgroundColor: "#B2FF59",
                // backgroundColor: "inherit",
                // position: "relative",
                // width: "100%",
                // height: "100%",
                // overflow: "hidden",
                boxSizing: "border-box"
              }}
            >
              {!isMobile && (
                <>
                  <FrmHeader
                    primaryField={primaryField}
                    schema={schema}
                    frmData={frmData}
                    frmState={frmState}
                    onComboFilterClick={this.handleCboFltDialogOpen}
                    onSearchFilterClick={this.handleSearchFilterChange}
                    handleCsvExportBtnClick={this.handleCsvExportBtnClick}
                    handleJsonExportBtnClick={this.handleJsonExportBtnClick}
                    postExportData={this.handleGetExportData}
                    isMobile={isMobile}
                    backRoute={this.props.backRoute}
                    reloadSchema={this.props.reloadSchema}
                    loadChartData={this.handleLoadChartData}
                  />
                  <div
                  // style={{ /* marginTop: '65px',*/ padding: '0 /*0.375rem*/', backgroundColor: '#4aa' }}
                  >
                    <Grid
                      primaryField={primaryField}
                      schema={schema}
                      frmData={frmData}
                      isMobile={isMobile}
                      onSortClick={this.handleSortChange}
                      gridRowOptions={gridRowOptions}
                      onScrollEnd={this.onScroll}
                      handleDetailDialogOpen={customUI.detail.disabled ? () => { } : this.handleDetailDialogOpen}
                      frmState={this.state.frmState}
                      frmPodFields={frmPodFields}
                    />
                  </div>
                </>
              )}
              {isMobile && (
                <>
                  <FrmHeader
                    primaryField={primaryField}
                    schema={schema}
                    frmData={frmData}
                    frmState={frmState}
                    onComboFilterClick={this.handleCboFltDialogOpen}
                    onSearchFilterClick={this.handleSearchFilterChange}
                    isMobile={isMobile}
                    backRoute={this.props.backRoute}
                    reloadSchema={this.props.reloadSchema}
                    loadChartData={this.handleLoadChartData}
                  />
                  <Grid
                    primaryField={primaryField}
                    schema={schema}
                    frmData={frmData}
                    isMobile={isMobile}
                    onScrollEnd={this.onScrollMobile}
                    onSortClick={this.handleSortChange}
                    gridRowOptions={gridRowOptions}
                    handleDetailDialogOpen={customUI.detail.disabled ? () => { } : this.handleDetailDialogOpen}
                    frmState={this.state.frmState}
                    frmPodFields={frmPodFields}
                  />
                </>
              )}
              <ComboFilterDialog
                isOpen={dataCboFlt.showDialog}
                closeClick={this.handleCboFltDialogClose}
                fltMeta={dataCboFlt.fltMeta}
                value={dataCboFlt.fltValue}
                onClick={this.handleComboFilterChange}
              />
              <ExportBtnDialog
                closeClick={this.handleExportBtnDialogClose}
                isOpen={this.state.exportBtnDialogOpen}
                handleCsvExportBtnClick={this.handleCsvExportBtnClick}
                handleJsonExportBtnClick={this.handleJsonExportBtnClick}
                postExportData={this.handleGetExportData}
              />
            </Stack>
          )
        }
        {
          dataDetail.isOpen && (
            <DetailDialog
              closeClick={this.handleDetailClose}
              detailProps={dataDetail}
              pkValue={this.state.selectedId}
            />
          )
        }
      </>
    );
  }
}

FrmComponent.propTypes = {
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  frmData: PropTypes.object.isRequired,
  getFrmData: PropTypes.func.isRequired,
  getChartData: PropTypes.func.isRequired,
  postExportData: PropTypes.func.isRequired,
  backRoute: PropTypes.string,
  reloadSchema: PropTypes.func,
};

export default withWidth()(FrmComponent);
