import React from 'react';
import ReactDOM from 'react-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { MetaProvider } from './coraWebMComponents/utils/withMeta';

import './index.css';

import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import rootReducer from './reducers';
import errorHandler from './middleware/errorHandler';
import * as actionCreators from "./actions/actionTypes";

const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, errorHandler))
);

ReactDOM.render(
  // <React.StrictMode>
    <MetaProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MetaProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

if (window.config.enableServiceWorker) {
  registerServiceWorker(store.dispatch);
}
else {
  unregister();
}