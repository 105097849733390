import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import L from 'leaflet';
import 'leaflet.markercluster';

const MarkersCluster = ({ markerList }) => {
  const mcg = L.markerClusterGroup();
  const map = useMap();

  useEffect(() => {
    mcg.clearLayers();
    mcg.addLayers(markerList);
    map.addLayer(mcg);

    return () => {
      map.removeLayer(mcg);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default MarkersCluster;