import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import InputAdornment from '@material-ui/core/InputAdornment';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Recaptcha from '../../coraWebMComponents/Recaptcha';
import EmailIcon from '../../coraWebMComponents/dataDisplay/icons/EmailIcon';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Error from '../../coraWebMComponents/feedBack/Error';
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import withRouter from "../../coraWebMComponents/utils/withRouter";
import * as validator from '../../coraWebMComponents/utils/validator';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';

import routes from '../../routes';
import * as accountActions from '../../actions/accountActions';
import * as userActions from '../../actions/userActions';
import AccountPermitSuccessCaption from './AccountPermitSuccessCaption';
import AdminModeExitButton from '../adminMode/exitButton';

const styles = theme => ({
  ...getSharedStyles(theme),
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

class AccountPermitSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EMAIL: '',
      RCTOKEN: '',
      text: '',
      success: 0,
      errorTextEmail: '',
      errorTextRcToken: '',
      code: '',
    };
  };

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    const code = params.get("code");

    const text = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_VERIFY_EMAIL');
    this.setState({ text, code });
  };

  handleChange = (name, value) => {
    switch (name) {
      case 'EMAIL':
        this.setState({ EMAIL: value, errorTextEmail: value ? validator.isValidMail(value) : 'E-mail je povinný údaj!' });
        break;
      case 'RCTOKEN':
        this.setState({ RCTOKEN: value, errorTextRcToken: '' });
        break;
      default:
    }
  };

  handleRecaptcha = (token) => {
    this.handleChange('RCTOKEN', token);
  };

  isUserNameUnik = async (username) => {
    let exist = false;
    if (username.length > 0) {
      await this.props.dispatch(userActions.getUserByName(username || ''));
      exist = this.props.userByName !== null;
      if (!exist) {
        await this.props.dispatch(accountActions.getZiadostByName(username || ''));
        exist = this.props.ziadostByName !== null;
      }
    }
    return exist ? 'Používateľ s takýmto emailom už existuje!' : '';
  };

  handleBlurUserName = async (name, value) => {
    if (!this.state.errorTextEmail) {
      const errorTextEmail = await this.isUserNameUnik(value);
      this.setState({ errorTextEmail });
    }
  };

  handleSkip = () => {
    if (this.state.success === 0) {
      this.setState({ success: 2 });
    } else if (this.state.success === 2) {
      this.props.navigate({ pathname: routes.ACCOUNT_PERMIT });
    }

  };

  handleAdd = async () => {
    const RcToken = this.state.RCTOKEN;
    const Email = this.state.EMAIL;

    let errorTxtN_u = await this.isUserNameUnik(Email);
    if (!errorTxtN_u)
      errorTxtN_u = validator.isValidMail(Email);

    const errorTextEmail = Email ? errorTxtN_u : 'Prihlasovací e-mail je povinný údaj!';
    const errorTextRcToken = RcToken ? '' : 'Zaškrtnite položku: Nie som robot!';

    this.setState({ errorTextRcToken, errorTextEmail });

    if (!errorTextRcToken && !errorTextEmail) {
      this.setState({ success: 1 });

      await this.props.dispatch(accountActions.GetVerifyEmailM(Email));
      const lVerify = this.props.verifyEmail ? this.props.verifyEmail.L_VERIFY : false;

      if (lVerify) {
        if (this.state.code)
          this.props.navigate({ pathname: routes.ACCOUNT_PERMIT, search: `?iVer=${this.props.verifyEmail.I_VERIFY_EMAIL_GUID}&code=${this.state.code}` });
        else
          this.props.navigate({ pathname: routes.ACCOUNT_PERMIT, search: `?iVer=${this.props.verifyEmail.I_VERIFY_EMAIL_GUID}` });

      }
      else {
        await this.props.dispatch(accountActions.AddVerifyEmail(RcToken, Email, 0, this.state.code));
        this.setState({ success: 2 });
      }
    };
  };

  isMobile(width) {
    if (width === "xs" || width === "sm")
      return true;

    return false;
  }

  render() {
    const { classes, width } = this.props;
    return (
      <div className='container' style={{ marginTop: this.props.adminMode.isActive ? '30px' : 0 }}>
        <CircularProgress loading={this.state.success === 1} />
        <AdminModeExitButton />
        <Paper className={classNames(classes.paperCardNoAppbar, (width && !this.isMobile(width) && classes.mt4))} elevation={(width && this.isMobile(width)) ? 0 : 2} >
          <React.Fragment>
            <IconButton color="inherit" onClick={() => this.props.navigate(routes.LOGIN)} toolTip={this.props.translate("back")} toolTipPlace="right" style={{ float: 'left' }} className={classNames(classes.p0)} disableRipple disableHover>
              <ArrowBack variant="fab" style={{ fontSize: 30 }} />
            </IconButton>
            {this.props.adminMode.isActive && (
              <IconButton
                color="inherit"
                onClick={() => this.handleSkip()}
                style={{ float: 'right' }}
                disableRipple
                disableHover
              >
                <ArrowForward variant="fab" style={{ fontSize: 30 }} />
              </IconButton>
            )}
            <Typography variant="subtitle1" className={classNames(classes.mb4, classes.textCenter)}>Overenie e-mailu</Typography>
            {this.state.success < 2 &&
              <div >
                <TextField
                  type="email"
                  label="Prihlasovací e-mail"
                  fullwidth={true}
                  value={this.state.EMAIL}
                  errorText={this.state.errorTextEmail}
                  error={this.state.errorTextEmail ? true : false}
                  onChange={this.handleChange}
                  onBlur={this.handleBlurUserName}
                  name='EMAIL'
                  imputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
                <div className={classNames(classes.mt4)}>
                  <Recaptcha onChange={this.handleRecaptcha} />
                </div>
                <div className={classNames(classes.textCenter)}>
                  <Error text={this.state.errorTextRcToken} />
                </div>
                <AccountPermitSuccessCaption />
                <div className={classNames(classes.row, classes.justifyContentCenter)}>
                  <RaisedButton
                    className={classNames(classes.raisedButton)}
                    onClick={this.handleAdd}
                    disabled={!this.state.EMAIL || this.state.errorTextEmail ? true : false}
                  >
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#fff' }}>
                        Overiť
                      </Typography>
                      <ArrowForward
                        color='primary'
                        fontSize='large'
                      />
                    </div>
                  </RaisedButton>
                </div>
              </div>}

            {this.state.success === 2 &&
              <div>
                <div className='center'>
                  <img style={{ width: 200, height: 200 }} className={classNames(classes.mb4)} src={'assets/MessageSent.svg'} alt='VERIFIKÁCIA' />
                </div>
                <div className={classNames(classes.mt3, classes.textCenter)}>
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} >
                    BOL VÁM ODOSLANÝ <br />
                    VERIFIKAČNÝ E-MAIL
                  </Typography>
                </div>
                <div className={classNames(classes.mt4, classes.mb4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    KLIKNUTÍM NA LINK <br />
                    POTVRDÍTE SPRÁVNOSŤ VÁŠHO E-MAILU<br />
                    A MÔŽETE POKRAČOVAŤ V REGISTRÁCII.
                  </Typography>
                </div>
              </div>}
          </React.Fragment>
        </Paper>
      </div >
    );
  }
}


const mapStateToProps = (state) => {
  return {
    userByName: state.userByName.data,
    ziadostByName: state.ziadostByName.data,
    verifyEmail: state.verifyEmail.data,
    adminMode: state.adminMode
  };
};



export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(AccountPermitSuccess)))));