import React from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertFromHTML, convertToRaw, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.scss'

class EditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.value)))
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
    const htmlData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.onChange(htmlData);
  };

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        editorState={editorState}
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        editorStyle={{
          background: '#ffffff',
          overflow: 'auto',
          resize: 'vertical',
        }}
        toolbarStyle={{ margin: 0 }}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'list',
            'textAlign',
            'link',
            'history',
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    );
  }
}

EditorComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditorComponent;
