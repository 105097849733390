import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';

class DownloadIconComponent extends React.Component {
  render() {
    return (
      <DownloadIcon
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
      />
    );
  }
}

DownloadIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default DownloadIconComponent;
