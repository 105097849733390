/**  */
export class PaymentSettingsHandler {

  /** Konštruktor */
  constructor(settings) {
    this.settings = settings || { paymentGateways: [] };
  }

  /**  */
  get itemCount() { return this.settings.paymentGateways.length; }

  /**  */
  getPaymentGatewayHandler = (iPrj) => {

    const res = this.settings.paymentGateways.find(x => x.iPrj === iPrj) || { iPrj, minSum: null };
    return new PaymentGatewayHandler(res);
  };
}

export class PaymentGatewayHandler {

  /** Konštruktor */
  constructor(paymentGateway) {
    // už iba hodnota 1 (0 pre pôvodné riešenie sa už nepoužíva)
    this.payMethod = 1;
    this.iPrj = paymentGateway.iPrj;
    this.minSum = paymentGateway.minSum;
  }

  /**  */
  get isNotAvailable() { return typeof this.minSum && this.minSum === 0.00; }

  // /**  */
  // getPaymentGateway = () => {

  //   const res = this.settings.paymentGateways.find(x => x.iPrj === iPrj);
  //   return res;
  // }
}
