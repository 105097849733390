import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class ElectionIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		12,6 16,6 18,12 1,12 3,6 7,6 	"/>

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="9" x2="14" y2="9" />

          <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		1,12 1,18 18,18 18,12 	"/>

          <rect x="7" y="1" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" width="5" height="8" />
        </g>
      </SvgIcon>
    );
  }
}

ElectionIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default ElectionIconComponent;
