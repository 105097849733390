import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({ 
  recaptcha: {
    display: 'flex', 
    justifyContent: 'center', 
    width: '100%',
    marginTop: '10px'
  },
});

let _this;

window.onloadCallback = () => {
  window.grecaptcha.render('recaptcha', {
    'sitekey' : window.config.recaptcha.sitekey,
    'callback': _this.props.onChange
  });
}

class Recaptcha extends React.Component {

  componentDidMount() {
    _this = this;

    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=sk";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div id="recaptcha" className={this.props.classes.recaptcha} />
    );
  }
}

export default withStyles(styles)(Recaptcha);