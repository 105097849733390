import React from 'react';
import Description from '@mui/icons-material/Description';
import PropTypes from 'prop-types';

class DescriptionIconComponent extends React.Component {
  render() {
    return (
      <Description
        style={this.props.style}
      />
    )
  }
}

DescriptionIconComponent.propTypes = {
  style: PropTypes.object
}

export default DescriptionIconComponent;
