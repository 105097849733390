import actionTypes from '../../actions/actionTypes';

const _init = {
  I_ZIADOST: null,
  I_UZ: null,
  N_UZ: ''
};

const _initState = {
  isLoading: false,
  data: _init
};

const ziadostByName = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ZIADOST_BY_NAME_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_ZIADOST_BY_NAME_SUCCESS:
      return { isLoading: false, data: action.res ? action.res.Data : _init };

    case actionTypes.GET_ZIADOST_BY_NAME_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default ziadostByName;