import React from 'react';
import { merge } from "lodash";

import { LineChart } from '@mui/x-charts/LineChart';

import { getColorsAttribute } from "../charts/helper";

const LineChartComponent = props => {
  const { isMobile, className, _style,
    dataSet, series, layout = "vertical", width, height = 400, colors,
    labelAxisProps, valueAxisProps, leftAxis, rightAxis, bottomAxis, topAxis,
    marginProps, legendProps, gridProps, axisHighlightProps,
    ...otherProps } = props;

  const labelAxis = [];
  labelAxisProps.forEach(axis =>
    labelAxis.push(merge({
      valueFormatter: (val, context) => {
        return context.location === "tick" ? `${val}` : `${val}`;
      },
      scaleType: 'point',
      labelFontSize: "1em",
      categoryGapRatio: 0.4,
      barGapRatio: 0.05,
      hideTooltip: false,
    }, axis))
  );

  const valueAxis = [];
  valueAxisProps.forEach(axis =>
    valueAxis.push(merge({
      valueFormatter: (val, context) => {
        return context.location === "tick" ? `${val}` : `${val}`;
      },
      hideTooltip: false,
      labelFontSize: "1em",
      // labelStyle: { transform: 'translate(-250px, 200px) rotate(-90deg)' }, //posun nazvu Y osi
    }, axis))
  );

  var defaultSeries = series.map(x => merge({
    connectNulls: true,
    highlightScope: {
      highlighted: "series",
      faded: "global",
    },
  }, x));

  const margin = isMobile
    ? undefined
    : { top: 50, right: 30, bottom: 50, left: 50, ...marginProps };

  const chartSetting = merge({
    dataset: dataSet,
    series: defaultSeries,
    layout: layout,
    width: width,
    height: height,
    xAxis: labelAxis,
    yAxis: valueAxis,
    leftAxis: leftAxis,
    rightAxis: rightAxis,
    bottomAxis: bottomAxis,
    topAxis: topAxis,
    margin: margin,
    colors: getColorsAttribute(colors),
    grid: { horizontal: true, vertical: true, ...gridProps },
    axisHighlight: { x: "band", y: "line", ...axisHighlightProps }, // 'none', 'band', 'line'
    skipAnimation: false,
    disableAxisListener: false,
    tooltip: { trigger: 'axis' }, // "item"
    slotProps: {
      legend: merge({
        position: {
          vertical: 'top',
          horizontal: 'center', //ak je right, potom aj padding sa nastavuje cez right (padding left nereaguje)
        },
        direction: 'row',
        itemGap: 20,
        markGap: 5,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        itemMarkWidth: 16,
        itemMarkHeight: 24,
        labelStyle: {
          fontSize: "1rem",
          fill: "black",
        },
      }, legendProps),
    },
  }, otherProps);

  return (
    // <Box
    //   className={classes.chartContainer}
    //   style={style}
    //   width={"100%"}
    //   height={"100%"}
    // >
    <LineChart
      sx={className}
      {...chartSetting}
    />
    // </Box>
  );
};

LineChartComponent.propTypes = {};

export default LineChartComponent;