import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";

import { withStyles } from "@material-ui/core/styles";
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Typography } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save'

import IconButton from "../../coraWebMComponents/inputs/IconButton";
import Editor from "../../coraWebMComponents/inputs/Editor";
import Button from "../../coraWebMComponents/inputs/Button";
import { withLocalizationConsumer } from "../../coraWebMComponents/localization/withLocalization";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";

import {
  adminModeSetEditedData,
  adminModeSetCurrEdited,
  adminModeUpdateOrAdd,
} from "../../actions/adminModeActions";
import { IFrmTxt } from "../../constants/adminConsts";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  gridFlex: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
  },
});

const Caption = ({
  classes,
  locale,
  translateWithLocaleAsync
}) => {
  const { adminMode } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [textEdit, setTextEdit] = React.useState(false);
  const [text, setText] = React.useState("");
  const [openSaveDialog, setOpenSaveDialog] = React.useState(false);


  const ReactToHtml = (component) => {
    const HTML = ReactDOMServer.renderToStaticMarkup(component);
    const HTMLstring = HTML.toString();
    return HTMLstring;
  };

  const dmTextInit = useCallback(async () => {
    translateWithLocaleAsync(locale, "DM_VERIFY_EMAIL")
      .then((res) => {
        setText(res);
      });
  }, [locale, translateWithLocaleAsync]);


  useEffect(() => {
    !openSaveDialog && dmTextInit();
  }, [dmTextInit, openSaveDialog]);

  const handleCurrEdited = (value) => {
    dispatch(adminModeSetCurrEdited(value));
    dispatch(adminModeSetEditedData(null));
    setTextEdit(true);
  };

  const handleEditData = (data) => {
    dispatch(
      adminModeSetEditedData([
        {
          TxtFrm: data,
          locale: locale,
          N_KLUC: "DM_VERIFY_EMAIL",
        },
      ])
    );
  };

  const handleSaveSection = async () => {
    const { dataEdited } = adminMode;
    await dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
    await dispatch(adminModeSetCurrEdited(null));
    setOpenSaveDialog(false);
    setTextEdit(false);

  };

  return (
    <div className={classNames(classes.flexColumn, classes.alignItemsCenter)}>
      {text && textEdit && (
        <Editor
          value={ReactToHtml(text)}
          onChange={(value) => handleEditData(value)}
        />
      )}
      {!textEdit ? (
        <div className={classes.gridFlex}>
          {text.length > 0 &&
            <div className={classNames(classes.mb3, classes.mt2,)}>
              <Typography variant="caption" >
                {text}
              </Typography>
            </div>
          }
          {adminMode.isActive && (
            <IconButton
              className={classNames(classes.ml3)}
              color="inherit"
              onClick={() => handleCurrEdited(IFrmTxt)}
              toolTip="Editovat"
              toolTipPlace="right"
              disableHover={true}
            >
              <EditOutlined
                color="secondary"
                variant="fab"
                style={{ fontSize: 30 }}
              />
            </IconButton>
          )}
        </div>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{
            width: "200px",
          }}
          type="success"
          className={classNames(classes.w100, classes.textTransformNone)}
          onClick={() => setOpenSaveDialog(true)}
        >
          <SaveIcon className={classes.mr1} />
          Uložiť
        </Button>
      )}
      {adminMode.isActive && (
        <Dialog
          modal={false}
          open={openSaveDialog}
          onClose={null}
          contentText="Informácia: Potvrdením uložíte zmeny"
          title="Uloženie nastavení"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: () => setOpenSaveDialog(false),
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: handleSaveSection,
              keyboardFocused: true,
            },
          ]}
        />
      )}
    </div>
  );
};

export default withLocalizationConsumer(withStyles(styles)(Caption));
