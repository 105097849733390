import React from 'react';
import AlarmOn from '@mui/icons-material/AlarmOn';
import PropTypes from 'prop-types';

class AlarmOnIconComponent extends React.Component {
  render() {
    return (
      <AlarmOn
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
      />
    )
  }
}

AlarmOnIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string
}

export default AlarmOnIconComponent;