import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import BySquare from '../../coraWebMComponents/BySquare';
import withRouter from '../../coraWebMComponents/utils/withRouter';

import routes from '../../routes';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as parkingActions from '../../actions/parkingActions';
import * as taxesActions from '../../actions/taxesActions';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {},
  paperCard: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '60px',
    },
  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  },
  backToCardsLink: {
    marginLeft: '15px',
    padding: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      padding: '20px 0 20px 0',
    },
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

class Pay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payType: 0,
      suma: 0,
      iban: '',
      vs: '',
      ks: '',
      ss: '',
      txtInfo: '',
      recipient: '',
    };
  };

  componentDidMount = async () => {
    let payType = this.props.location.state ? this.props.location.state.payType : this.state.payType;

    // Parkovanie
    if (payType === 1) {
      await this.props.dispatch(ciselnikyActions.getIbanParking());

      await this.props.dispatch(ciselnikyActions.getPayRecipient(40030104));
      const recipient = this.props.payRecipient ? this.props.payRecipient.HODN : '';

      let iban = this.props.ibanParking.HODN;
      const suma = this.props.parkingCard.CENA;
      const vs = this.props.parkingCard.VS;
      const txtInfo = this.props.parkingCard.L_UHR === 1 || this.props.parkingCard.L_UHR_PB === 1 ? 'Parkovacia karta už bola uhradená!' : '';
      await this.props.dispatch(parkingActions.getBySquare(iban, vs, suma, recipient));

      if (this.props.parkingCard)
        this.setState({ suma, iban, vs, txtInfo, recipient, payType });
    }

    // Dane a poplatky
    if (payType === 2) {

      await this.props.dispatch(ciselnikyActions.getPayRecipientTaxes(999998031));
      const recipient = this.props.beneficiaryTaxes ? this.props.beneficiaryTaxes.HODN : '';
      this.setState({ recipient: recipient });

      let { totalAmount, waybill } = this.props.location.state;
      let iban, suma, vs, ss = '', txtInfo = '';
      if (waybill) {
        iban = waybill.iban;
        suma = waybill.suma;
        vs = waybill.vs;
      } else if (this.props.proceeding) {
        iban = this.props.proceeding.IBAN;
        suma = this.props.proceeding.PLATBA;
        if (totalAmount && suma !== totalAmount)
          suma = totalAmount;
        vs = this.props.proceeding.VS;
        ss = this.props.proceeding.SSY;
        txtInfo = this.props.proceeding.L_UHR === 1 ? 'Daň/poplatok už bol(a) uhradený(á)!' : '';
      }
      await this.props.dispatch(taxesActions.getTaxesBySquare(iban, vs, suma, recipient, ss));

      if (this.props.proceeding)
        this.setState({ suma: suma, iban: iban, vs: vs, txtInfo: txtInfo, recipient: recipient, ss: ss, payType: payType });

    }
  };

  getValue = () => {
    if (this.state.payType === 1)
      return this.props.bySquare;
    else if (this.state.payType === 2)
      return this.props.taxesBySquare;
    else return '';
  };

  handleGoHome = () => {
    // this.setState(_initState);

    if (this.state.payType === 1)
      this.props.navigate(routes.PARKING);
    if (this.state.payType === 2)
      this.props.navigate(routes.TAXES);

  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { classes, width } = this.props;
    let payType = this.props.location.state ? this.props.location.state.payType : this.state.payType;
    return (
      <div className='container' style={{ marginTop: '70px' }}>
        <CircularProgress loading={this.state.payType === 0} />
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2}>
          <React.Fragment>
            <div className={classNames(classes.mb4, classes.navigationBackButton)}>
              <Button
                color="inherit"
                aria-label="Späť"
                onClick={this.handleGoHome}
              >
                <ArrowBack
                  fontSize='default'
                  variant='fab'
                />
              </Button>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt4)} >
              <Typography variant="subtitle1" style={{ color: '#4caf50' }}>{this.state.txtInfo}</Typography>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentBetween, classes.mb2)}>
              <Typography variant="subtitle1">Platobné údaje</Typography>
            </div>
            {this.state.recipient &&
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <Typography variant="subheading">Príjemca</Typography>
                <Typography variant="body2">{this.state.recipient}</Typography>
              </div>}
            <div className={classNames(classes.row, classes.alignItemsCenter)} >
              <Typography variant="subheading">Suma</Typography>
              <Typography variant="button">{this.state.suma} €</Typography>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter)} >
              <Typography variant="subheading">IBAN</Typography>
              <Typography variant="button">{this.state.iban}</Typography>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, payType === 1 ? classes.mb4 : '')} >
              <Typography variant="subheading">Variabilný symbol</Typography>
              <Typography variant="button">{this.state.vs}</Typography>
            </div>
            {payType === 2 &&
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)} >
                <Typography variant="subheading">Špecifický symbol</Typography>
                <Typography variant="button">{this.state.ss}</Typography>
              </div>
            }
            <BySquare className={classes.flexColumn} value={this.getValue()} />
          </React.Fragment>
        </Paper>
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    parkingCard: state.parkingCard.data.Data,
    ibanParking: state.ibanParking.data.Data,
    payRecipient: state.payRecipient.data.Data,
    bySquare: state.bySquare.data,
    taxesBySquare: state.taxesBySquare.data,
    proceeding: state.proceeding.data,
    beneficiaryTaxes: state.payRecipientTaxes.data
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withWidth()(Pay))));