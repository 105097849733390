import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import * as convert from '../../coraWebMComponents/utils/convert';
import Button from '../../coraWebMComponents/inputs/Button';

const styles = theme => ({
  ...getSharedStyles(theme),
});

const Installment = (props) => {

  const { itemInstallment, index, classes, isSelected, setSelected } = props;

  const selectedStyles = () => isSelected ? { backgroundColor: "rgba(0, 0, 0, 0.2)" } : { backgroundColor: "white" };

  const handleDateChange = (item) => {
    if (item.L_DO_P_DNI && item.CISLO_SPLATKY === 1)
      return "do 15 dní odo dňa nadobudnutia právoplatnosti";
    else
      return convert.convertDate(item.D_POH);
  };

  return (
    <div
      key={itemInstallment.I_POH}
      style={{ transition: "0.2s ease-in-out", border: "1px solid rgba(0, 0, 0, 0.06)", borderRadius: "4px", padding: '1em', ...selectedStyles() }}
      className={classes.mb2}
    >
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb2)} >
        <Typography
          variant="body1"
          style={{ letterSpacing: '0.1em', fontWeight: 500 }}>
          {itemInstallment.CISLO_SPLATKY}. Splátka
        </Typography>
        <Typography
          variant="body2"
          className={classes.fontBold}
          style={{ letterSpacing: '0.1em' }}>
          {itemInstallment.PREDPIS.toFixed(2)}{' '}{itemInstallment.MENA === 'EUR' ? '€' : itemInstallment.MENA}
        </Typography>
      </div>
      <div className={classNames(classes.row, classes.alignSelfCenter)} >
        <Typography variant="body1">
          Dátum splatnosti
        </Typography>
        <Typography
          variant="body2"
          style={{ maxWidth: "12em", fontWeight: "normal" }}
          classes={{ root: classes.textRight }} >
          {handleDateChange(itemInstallment)}
        </Typography>
      </div>
      <div className={classNames(classes.row, classes.alignSelfCenter)} >
        <Typography variant="body1">
          Stav
        </Typography>
        <Typography
          variant="body2"
          style={{ maxWidth: "12em", color: `${(itemInstallment.LUhr ? "#4caf50" : "#f44336")}` }}
          classes={{ root: classes.textRight }} >
          {itemInstallment.LUhr ? "UHRADENÉ" : "NEUHRADENÉ"}
        </Typography>
      </div>
      {!itemInstallment.LUhr &&
        <Button
          style={{ width: '100%' }}
          variant="contained"
          color="secondary"
          type={isSelected ? "danger" : "blue"}
          onClick={() => setSelected(index)}
        >
          {isSelected ? "Zrušiť výber" : "Vybrať"}
        </Button>
      }
    </div>
  );
};

Installment.propTypes = {
  itemInstallment: PropTypes.object.isRequired,
};

export default withStyles(styles)(Installment);