import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '../../coraWebMComponents/inputs/IconButton';
import FlatButton from '../../coraWebMComponents/inputs/FlatButton';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Editor from "../../coraWebMComponents/inputs/Editor";

import * as adminModeUtils from '../../utils/adminModeUtils.js';
import { Transition } from './support';
import * as userActions from '../../actions/userActions';
import {
  adminModeSetCurrEdited,
  adminModeSetEditedData,
} from "../../actions/adminModeActions";
import { IFrmBefTxt, } from "../../constants/adminConsts";

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

/**
 * Na editaciu textu nad formularom v objekte
 *
 * @param {*} { params, onClose, befTxt, ...props }
 * @return {*} 
 */
const AdminModeSpFrmTxt = ({ params, onClose, befTxt, classes, reloadSchema, ...props }) => {
  const [spFrm, setSpFrm] = useState(befTxt);

  const handleChangeEditor = useCallback((data) => {
    setSpFrm((prev) => ({ ...prev, TxtFrm: data }));
    props.dispatch(adminModeSetEditedData({ ...befTxt, TxtFrm: data }));
  }, [befTxt, props]);

  useEffect(() => {
    props.dispatch(adminModeSetCurrEdited(IFrmBefTxt));
    props.dispatch(adminModeSetEditedData(befTxt));

    return () => {
      props.dispatch(adminModeSetCurrEdited(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSaveClick = async () => {
    const { dataEdited, _currEdited } = props.adminMode;

    try {
      const data = await adminModeUtils.updateOrAddSpFrmTxt(dataEdited);
      // const data = await adminModeUtils.updateOrAddSpFrmTxt(spFrm);
      await reloadSchema();
      props.dispatch(adminModeSetEditedData(data));
      props.dispatch(adminModeSetCurrEdited(null));
      props.showMsg(data.UserMessage);
    } catch (e) {
      console.error(e);
      props.showErrorMsg('Error: Chyba pri komunikácii s databázou');
    }
    onClose();
  };

  const nadpisFrm = spFrm?.TxtFrm ?? "";

  return (
    <Dialog
      open={params.isOpen}
      onClose={onClose}
      scroll='paper'
      maxWidth={'md'}
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.root}>
        <Typography variant="h6">{params.title}</Typography>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classNames(classes.mb3, classes.mt3)}>
          <Editor
            onChange={handleChangeEditor}
            value={nadpisFrm}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <FlatButton
          key='Cancel'
          label='Zrušiť'
          color='primary'
          onClick={onClose}
        >
          {`Zrušiť`}
        </FlatButton>
        <FlatButton
          key='Save'
          label='Uložiť'
          color='secondary'
          onClick={handleSaveClick}
        >
          Uložiť
        </FlatButton>
      </DialogActions>
    </Dialog>
  );
};

AdminModeSpFrmTxt.propTypes = {
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  befTxt: PropTypes.object.isRequired,
  reloadSchema: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    adminMode: state.adminMode,
  };
};

const mapDispatchToProps = dispatch => ({
  showMsg: (msg) => { dispatch(userActions.showMsg(msg)); },
  showErrorMsg: (errorMsg) => { dispatch(userActions.showErrorMsg(errorMsg)); },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminModeSpFrmTxt));
