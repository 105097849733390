import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PropTypes from 'prop-types';

class CheckCircleOutlineIconComponent extends React.Component {
  render() {
    return (
      <CheckCircleOutlineIcon
        style={this.props.style}
        className={this.props.className}
      />
    );
  }
}

CheckCircleOutlineIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default CheckCircleOutlineIconComponent;
