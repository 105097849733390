import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class OpenDataIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="9.5" cy="2" r="0.5" />

          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="16.5" cy="4" r="0.5" />

          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="2.5" cy="4" r="0.5" />

          <ellipse fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="9.5" cy="10.5" rx="3.5" ry="1.5" />

          <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="9.5" y1="8" x2="9.5" y2="3" />
          <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="2.5,5 
  3.5,9 6.3,9.9 	"/>
          <polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="16.5,5 
  15.5,9 12.7,9.9 	"/>

          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="6.5" cy="5" r="0.5" />

          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="12.5" cy="5" r="0.5" />

          <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="7" y1="5.6" x2="8.2" y2="8.3" />

          <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="12.2" y1="5.6" x2="11" y2="8.3" />

          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="2.5" cy="12" r="0.5" />

          <circle fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="16.5" cy="12" r="0.5" />

          <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="2" y1="12.5" x2="6" y2="11.5" />

          <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="13" y1="11.5" x2="16" y2="12.5" />
          <path fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
  M6,10v6.1c0,0.8,1.6,1.6,3.5,1.6s3.5-0.6,3.5-1.4V10"/>
        </g>
      </SvgIcon>
    );
  }
}

OpenDataIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default OpenDataIconComponent;
