import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from '@mui/material/Box';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import DescriptionIcon from '../../coraWebMComponents/dataDisplay/icons/DescriptionIcon';

import { getDetailTitle } from './support';

const styles = theme => ({
  ...getSharedStyles(theme),
  titleText: {
    textAlign: 'left',
    paddingRigth: theme.spacing.unit,
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    minWidth: '150px',
  },
  valueText: {
    textAlign: 'left',
    paddingLeft: theme.spacing.unit,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class MobileGridItemComponent extends React.Component {

  render() {
    const { classes, columns, rowData, rowTitle } = this.props;
    const itemTitle = getDetailTitle(rowTitle, rowData);
    return (
      <Paper elevation={2} className={classNames(classes.cardRoot, classes.mb3)}>
        <Box className={this.props.classes.cardContent}>
          <Box className={classNames(classes.row, classes.alignItemsCenter, classes.mb2)}>
            <DescriptionIcon style={{ width: "35", height: "40" }}></DescriptionIcon>
            <Box className={classNames(classes.ml2)}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>{itemTitle}</Typography>
            </Box>
            {this.props.createOptionsButton && this.props.createOptionsButton(rowData)}
          </Box>

          {columns.filter(col => col.name !== 'colOptions').map(col => (
            <Box className={classNames(classes.row, classes.alignItemsCenter, classes.mt2)} key={`col_${col.field}`}>
              <Typography variant="body2" className={classNames(classes.titleText)}>{col.title}</Typography>
              <Typography variant="body1" className={classNames(classes.valueText)}>{rowData[col.field]}</Typography>
            </Box>
          ))}
        </Box>
      </Paper>
    );
  }
}

MobileGridItemComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired,
  rowTitle: PropTypes.string.isRequired,
  pkValue: PropTypes.number,
  createOptionsButton: PropTypes.func
};

export default withStyles(styles)(MobileGridItemComponent);
