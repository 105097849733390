import React from 'react';
import CreditCard from '@mui/icons-material/CreditCard';
import PropTypes from 'prop-types';

class CreditCardIconComponent extends React.Component {
  render() {
    return (
      <CreditCard
        style={this.props.style}
      />
    )
  }
}

CreditCardIconComponent.propTypes = {
  style: PropTypes.object
}

export default CreditCardIconComponent;
