import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';

/**
 * Titulok k zoznamu ZS
 *
 * @param {*} { classes }
 * @return {*} 
 */
const ZstZsTitle = ({ classes }) => {
  return (
    <>
      <Typography variant={"body1"}>
        Výber služby
      </Typography>
      <Typography variant={"caption"} className={classNames(classes.textCaption)}>
        Elektronické služby konkrétnej životnej situácie sú zoskupené v nasledujúcich agendách. Po zvolení agendy, ktorá prislúcha Vašej požiadavke, sa zobrazia dostupné služby.
      </Typography>
    </>
  );
};

ZstZsTitle.propTypes = {
  classes: PropTypes.object,
};

export default ZstZsTitle;