import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  isLoaded: false,
  data: [],
  total: 0
};

/**
 * Zivotne situacie - zivotne situacie spolu so svojimi sluzbami
 *
 * @param {*} [state=_initState]
 * @param {*} action
 * @return {*} 
 */
const zstZsTree = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ZST_ZS_TREE_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ZST_ZS_TREE_SUCCESS:
      let { isLoading, isLoaded, data, total } = _initState;
      if (Boolean(action.res.Data)) {
        isLoaded = true;
        data = action.res.Data.Items;
        total = action.res.Data.TotalRecords;
      }
      return { isLoading, isLoaded, data, total };

    case actionTypes.GET_ZST_ZS_TREE_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default zstZsTree;