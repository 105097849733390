import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import TextField from '../../coraWebMComponents/inputs/TextField';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import * as validator from '../../coraWebMComponents/utils/validator';
import withRouter from '../../coraWebMComponents/utils/withRouter';

import * as userActions from '../../actions/userActions';
import * as accountActions from '../../actions/accountActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';

const styles = theme => ({
  ...getSharedStyles(theme),
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

const _initState = {
  EMAIL: '',
  TEL: '',
  HESLO: '',
  HESLO2: '',
  ZRUS: 0,
  errorTextEmail: '',
  errorTextTel: '',
  errorTextHeslo: '',
  errorTextHeslo2: '',
  loading: true,
  done: false,
  emailOld: '',
  telOld: ''
};

class AccountPermit extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
  }

  componentDidMount = async () => {
    await this.props.dispatch(accountActions.getPerson(this.props.user.i_o));
    await this.props.dispatch(ciselnikyActions.getPasswordPattern());
    await this.props.dispatch(ciselnikyActions.getPasswordErrMsg());

    this.setState({
      TEL: this.props.person.TEL,
      telOld: this.props.person.TEL,
      EMAIL: this.props.person.EMAIL,
      emailOld: this.props.person.EMAIL,
      loading: false
    });
  };

  handleChange = (name, value) => {
    switch (name) {
      case 'EMAIL':
        this.setState({ errorTextEmail: value ? validator.isValidMail(value) : 'E-mail je povinný údaj!' });
        break;
      case 'TEL':
        this.setState({ errorTextTel: value ? validator.isValidPhone(value) : 'Telefón je povinný údaj!' });
        break;
      case 'HESLO':
        this.setState({ errorTextHeslo: value ? this.isValidPasswordStrong(value) : (this.state.EMAIL === this.state.emailOld ? '' : 'Zmena E- mail vyžaduje zadanie hesla!'), errorTextHeslo2: '', HESLO2: '' });
        break;
      case 'HESLO2':
        if (this.state.HESLO)
          this.setState({ errorTextHeslo2: value ? this.isValidPassword(this.state.HESLO, value) : 'Potvrdenie hesla je povinný údaj!' });
        else {
          this.setState({ errorTextHeslo2: '' });
          value = '';
        }

        break;
      default:
    }
    this.setState({ [name]: value });
  };

  isValidPassword = (heslo, heslo2) => {
    let errorString = '';

    if (heslo !== heslo2)
      errorString += 'Potvrdenie hesla je nesprávne!';

    return errorString;
  };

  isValidPasswordStrong = (psw) => {
    let errorString = '';
    let passwordPattern = new RegExp(this.props.passwordPattern.HODN.trim(), "g");

    if (!(passwordPattern).test(psw))
      errorString = this.props.passwordErrMsg.HODN.trim();

    return errorString;
  };

  isUserNameUnik = async (mail) => {
    let exist = false;
    if (mail.length > 0 && mail !== this.state.emailOld) {
      await this.props.dispatch(userActions.getUserByName(mail || ''));
      exist = this.props.userByName !== null;
      if (!exist) {
        await this.props.dispatch(accountActions.getZiadostByName(mail || ''));
        exist = this.props.ziadostByName !== null;
      }
    }
    return exist ? 'Používateľ s takýmto emailom už existuje!' : '';
  };

  handleBlurEmail = async (name, value) => {
    if (!this.state.errorTextEmail) {
      const errorTextEmail = await this.isUserNameUnik(value);
      this.setState({ errorTextEmail });
    }
  };

  handleUpd = async () => {

    let errTextHeslo = '';
    let errTextHeslo2 = '';

    const Email = this.state.EMAIL;
    const Tel = this.state.TEL;
    const Heslo = this.state.HESLO;
    const errorTextTel = this.state.errorTextTel;
    const errorTextEmail = this.state.errorTextEmail ? this.state.errorTextEmail : await this.isUserNameUnik(Email);

    if (Heslo || (Email !== this.state.emailOld)) {
      errTextHeslo = Heslo ? this.state.errorTextHeslo : 'Zmena vyžaduje zadanie hesla!';
      errTextHeslo2 = this.state.HESLO2 ? this.isValidPassword(Heslo, this.state.HESLO2) : 'Potvrdenie hesla je povinný údaj!';
    }

    const errorTextHeslo = errTextHeslo;
    const errorTextHeslo2 = errTextHeslo2;

    this.setState({ errorTextEmail, errorTextTel, errorTextHeslo, errorTextHeslo2 });

    if (!errorTextEmail &&
      !errorTextTel &&
      !errorTextHeslo &&
      !errorTextHeslo2) {

      this.setState({ loading: true });
      const res = await this.props.dispatch(userActions.UserModify(Email, Heslo, Tel));
      this.setState({ done: res, loading: false });

    }
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { classes, width } = this.props;
    return (
      <div className='container w-margin'>
        <CircularProgress loading={this.state.loading} />
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2} >
          <React.Fragment>
            <div className={classNames(classes.row, classes.navigationBackButton, classes.alignItemsCenter, classes.justifyContentStart, classes.mb2)}>
              <IconButton
                color="inherit"
                onClick={() => { this.props.navigate(-1); }}
                className={classNames(classes.p1)}
                disableRipple
                // disableHover
                toolTip="Späť"
                toolTipPlace="right"
              >
                <ArrowBack variant="fab" style={{ fontSize: 30 }} />
              </IconButton>
            </div>

            {!this.state.done &&
              <div >
                <Typography variant="subtitle2" className={classNames(classes.textCenter)}>
                  Zmena hesla
                </Typography>
                <div >
                  <TextField
                    label="Heslo"
                    type="password"
                    fullwidth={true}
                    value={this.state.HESLO}
                    errorText={this.state.errorTextHeslo}
                    error={this.state.errorTextHeslo ? true : false}
                    onChange={this.handleChange}
                    name='HESLO'
                     variant="standard"
                  />
                </div>
                <div >
                  <TextField
                    label="Potvrdenie hesla"
                    type="password"
                    fullwidth={true}
                    value={this.state.HESLO2}
                    errorText={this.state.errorTextHeslo2}
                    error={this.state.errorTextHeslo2 ? true : false}
                    onChange={this.handleChange}
                    name='HESLO2'
                    className={classNames(classes.mt2)}
                     variant="standard"
                  />
                </div>
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt4, classes.mb3)}>
                  Kontaktné údaje
                </Typography>
                <div>
                  <TextField
                    label="Prihlasovací a kontaktný e-mail"
                    type="email"
                    fullwidth={true}
                    value={this.state.EMAIL}
                    errorText={this.state.errorTextEmail}
                    error={this.state.errorTextEmail ? true : false}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurEmail}
                    name='EMAIL'
                     variant="standard"
                  />
                </div>
                <div >
                  <TextField
                    label="Telefón"
                    fullwidth={true}
                    value={this.state.TEL}
                    errorText={this.state.errorTextTel}
                    error={this.state.errorTextTel ? true : false}
                    onChange={this.handleChange}
                    name='TEL'
                    className={classNames(classes.mt2)}
                     variant="standard"
                  />
                </div>

                <div className={classNames(classes.row, classes.justifyContentCenter, classes.mt4)}>
                  <RaisedButton
                    disabled={this.state.EMAIL === this.state.emailOld && this.state.TEL === this.state.telOld && !this.state.HESLO}
                    className={classNames(classes.raisedButton)}
                    onClick={this.handleUpd}
                  >
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#fff' }}>
                        Odoslať
                      </Typography>
                      <ArrowForward
                        fontSize='large'
                      />
                    </div>
                  </RaisedButton>
                </div>
              </div>
            }

            {this.state.done &&
              <div>
                <div className='center'>
                  <img style={{ width: 140, height: 140, marginBottom: '10%' }} src={'assets/check_green.svg'} alt='ŽIADOSŤ ODOSLANÁ' />
                </div>
                <div className='center'>
                  <Typography style={{ color: 'green', align: 'center', fontWeight: 'bold' }}>ZMENA VAŠÍCH ÚDAJOV BOLA</Typography>
                </div>
                <div className='center'>
                  <Typography style={{ color: 'green', align: 'center', fontWeight: 'bold', marginBottom: '10%' }}> ÚSPEŠNE ODOSLANÁ</Typography>
                </div>
                <div className='center'>
                  <Typography style={{ align: 'center' }}>ZMENA SA PREJAVÍ </Typography>
                </div>
                <div className='center'>
                  <Typography style={{ align: 'center' }}>PO ĎALŠOM PRIHLÁSENÍ</Typography>
                </div>
              </div>
            }
          </React.Fragment>
        </Paper>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    person: state.person.data,
    userByName: state.userByName.data,
    ziadostByName: state.ziadostByName.data,
    passwordPattern: state.passwordPattern.data.Data,
    passwordErrMsg: state.passwordErrMsg.data.Data,
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(withWidth()(AccountPermit))));