import actionTypes from '../../actions/actionTypes';

const errorMsg = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ERROR_MSG:
      return action.errorMsg;

    case actionTypes.HIDE_ERROR_MSG:
      return null;

    default:
      return state;
  }
};

export default errorMsg;