import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import ClearIcon from "../../coraWebMComponents/dataDisplay/icons/ClearIcon";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import getSharedStyles from "../sharedStyles";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  appBar: {
    top: 0,
    bottom: "auto",
    width: "100%",
  },
  draw: {
    width: "100%",
    alignItems: "left",
  },
  drawerPaper: {
    width: "85%",
    maxWidth: "400px",
    minWidth: "310px",
    alignItems: "left",
    backgroundColor: theme.palette.background.default,
  },
  closeSidebarBtn: {
    padding: "7px",
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
    outline: "none",
    cursor: "pointer",
    position: "absolute",
    right: '5px',
    top: '22px'
  },
  closeSidebarBtnIcon: {
    color: theme.palette.secondary.main,
  },
  tabIndicator: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    borderRadius: '7px',
  },
  tabsRoot: {
    width: "50%",
    height: '60px'
  },
  tabsWrapperRoot: {
    backgroundColor: 'white',
    //color: theme.palette.secondary.main,
    borderRadius: '7px',
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.17)",
    WebkitBoxShadow: "0px 0px 4px 0px rgba(0,0,0,0.17)",
    MozBoxShadow: "0px 0px 4px 0px rgba(0,0,0,0.17)"
  },
  tabSelected: {
    color: "white",
    position: "relative",
    zIndex: 1,
  },
  logoRow: {
    padding: "10px 0 10px 5px",
  },
});

/**
 * AppBarComponent component
 * @module
 * @param {bool} [tabsVisible] - Whether the tabs are visible
 * @param {element} [appLogo] - Logo element
 * @param {element} [toolbarEl] - Toolbar element
 * @param {element} [drawerEl1] - Drawer element 1
 * @param {element} [drawerEl2] - Drawer element 2
 * @param {string} [tabLabel1] - Tab label 1
 * @param {string} [tabLabel2] - Tab label 2
 * @param {number} [tabIndex] - Current tab index
 * @param {bool} open - Whether the drawer is open
 * @param {func} onChangeTabs - Tab change handler
 * @param {func} onChangeIndex - Index change handler
 * @param {func} onClose - Close handler
 * @param {func} onOpen - Open handler
 */

class AppBarComponent extends Component {
  handleCloseDrawer = () => {
    this.props.onClose();
  };

  render() {
    const { classes, theme } = this.props;
    const views = [this.props.drawerEl1, this.props.drawerEl2];
    return (
      <div>
        <AppBar position="fixed" className={classes.appBar} color="secondary">
          <Toolbar>{this.props.toolbarEl}</Toolbar>
        </AppBar>
        <SwipeableDrawer
          className={classes.draw}
          classes={{ paper: classes.drawerPaper }}
          anchor="left"
          docked="false"
          open={this.props.open}
          color="secondary"
          onClose={this.props.onClose}
          onOpen={this.props.onOpen}
        >
          <div className={classNames(classes.logoRow, classes.row, classes.justifyContentCenter, classes.alignItemsCenter)}>
            <div className={classNames(classes.pt3, classes.pb3)}>{this.props.appLogo}</div>
            <button onClick={this.handleCloseDrawer} className={classNames(classes.closeSidebarBtn)}>
              <ClearIcon className={classes.closeSidebarBtnIcon}></ClearIcon>
            </button>
          </div>
          <div className={classNames(classes.pl3, classes.pr3, classes.pb3)}>
            {this.props.homeButton && this.props.homeButton}
          </div>
          <div className={classNames(classes.pl3, classes.pr3)}>
            {this.props.tabsVisible && (
              <Tabs
                classes={{
                  root: classes.tabsWrapperRoot,
                  indicator: this.props.indicatorClasses ? this.props.indicatorClasses : classes.tabIndicator,
                }}
                value={this.props.tabIndex}
                onChange={this.props.onChangeTabs}
              >
                <Tab key={0} label={this.props.tabLabel1} classes={{ root: classes.tabsRoot, selected: classes.tabSelected }} />
                <Tab key={1} label={this.props.tabLabel2} classes={{ root: classes.tabsRoot, selected: classes.tabSelected }} />
              </Tabs>
            )}
          </div>
          <div className={classNames(classes.pl3, classes.pr3)}>
            <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={this.props.tabIndex} onChangeIndex={this.props.onChangeIndex} disabled>
              {views}
            </SwipeableViews>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

AppBarComponent.propTypes = {
  tabsVisible: PropTypes.bool,
  appLogo: PropTypes.element,
  toolbarEl: PropTypes.element,
  drawerEl1: PropTypes.element,
  drawerEl2: PropTypes.element,
  tabLabel1: PropTypes.string,
  tabLabel2: PropTypes.string,
  open: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number,
  onChangeTabs: PropTypes.func.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  homeButton: PropTypes.element,
};

export default withStyles(styles, { withTheme: true })(AppBarComponent);
