import React, { useState } from "react";
import PropTypes from "prop-types";

import withWidth from "@material-ui/core/withWidth";

import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import ChartBtn from "./ChartBtn";
import Chart from "./Chart";
import ChartOptionsButton from "./ChartOptionsButton";

/**
 * Vygeneruje tlacidlo a k nemu menu s polozkamy pre grafy
 *
 * @param {object} props
 */
const ChartOptions = ({ _classes, schema, isMobile, loadChartData }) => {
  const [openChartDetail, setOpenChartDetail] = useState(false);
  const [activeChartId, setActiveChartId] = useState(null);
  const [chartData, setChartData] = useState(null);

  /** Udalosť Otvorenie */
  const handleDialogOpen = async (activeChartId) => {
    setOpenChartDetail(true);
    setActiveChartId(activeChartId);
    const res = await loadChartData();
    setChartData(res);
  };

  const handleDialogClose = () => {
    setOpenChartDetail(false);
    setActiveChartId(null);
    setChartData(null);
  };

  if (schema.Charts?.length > 0) {
    return (
      <>
        <CircularProgress loading={openChartDetail} />
        {!isMobile && (
          <ChartBtn
            onClick={handleDialogOpen}
            charts={schema.Charts}
          />
        )}

        {isMobile && (
          <ChartOptionsButton
            onClick={handleDialogOpen}
            charts={schema.Charts}
          />
        )}

        {openChartDetail && chartData && (
          <Chart
            params={{ activeChartId }}
            onClose={handleDialogClose}
            chartParams={schema.Charts[activeChartId]}
            data={chartData}
            isMobile={isMobile}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

ChartOptions.propTypes = {
  schema: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loadChartData: PropTypes.func.isRequired
};

export default withWidth()(ChartOptions);
