import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class PublicationIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <rect x="2" y="9" fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" width="15" height="9" />
          <polyline fill="none" strokeLinejoin="round" strokeMiterlimit="10" points="3,9 9.5,1 16,9 	" />
          <circle fill="#9E9E9E" cx="9.5" cy="3.5" r="0.5" />
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="12.5" x2="12" y2="12.5" />
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="14.5" x2="14" y2="14.5" />
        </g>
      </SvgIcon>
    );
  }
}

PublicationIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default PublicationIconComponent;
