import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class TowingIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <circle fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" cx="14.5" cy="14.5" r="1.5" />

          <circle fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" cx="3.5" cy="14.5" r="1.5" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="7" y1="14" x2="11" y2="14" />
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="-0.4,12.1 3,10 
  12,10 12,5 15,5 18,7.3 18,14 	"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="8" x2="15" y2="9" />
        </g>
      </SvgIcon>
    );
  }
}

TowingIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default TowingIconComponent;
