import React from 'react';
import People from '@mui/icons-material/People';
import PropTypes from 'prop-types';

class PeopleIconComponent extends React.Component {
  render() {
    return (
      <People
        style={this.props.style}
      />
    )
  }
}

PeopleIconComponent.propTypes = {
  style: PropTypes.object
}

export default PeopleIconComponent;
