import actionTypes from '../../../actions/publicZone/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
};

const listOrgContractBill = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_ORG_CONTRACT_BILL_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_ORG_CONTRACT_BILL_SUCCESS:
      return { isLoading: false, data: action.res.Data, total: action.res.Data.length };

    case actionTypes.GET_LIST_ORG_CONTRACT_BILL_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default listOrgContractBill;