import React from 'react';
import Autorenew from '@mui/icons-material/Autorenew';
import PropTypes from 'prop-types';

class AutorenewIconComponent extends React.Component {
  render() {
    return (
      <Autorenew
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    )
  }
}

AutorenewIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default AutorenewIconComponent;