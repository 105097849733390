import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { isNil } from "lodash";

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import PdfIcon from '../../coraWebMComponents/dataDisplay/icons/PdfIcon';
import Button from '../../coraWebMComponents/inputs/Button';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import PaymentGateway from '../../coraWebMComponents/PaymentGateway';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import * as storage from '../../coraWebMComponents/utils/storage';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import * as requests from '../../coraWebMComponents/utils/requests';

import * as userActions from '../../actions/userActions';
import routes from '../../routes';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {},
  paperCard: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '60px',
    },
  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  },
  backToCardsLink: {
    marginLeft: '15px',
    padding: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      padding: '20px 0 20px 0',
    },
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

const PAYMENT_WAYBILL_KEY = 'PaymentWaybill';
let _vs = "";
let _transactionId = "";
let _waybill = null;
let isOk = true;

class PayReceived extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _vs = params.get("VS");
    _transactionId = params.get("transactionId");

    let payType = 1;      //parkovacia karta
    isOk = true;
    let status = 0;

    if (_transactionId) {

      // nacitanie sprievodky zo storage a odstranenie zo storage
      _waybill = storage.getItem(PAYMENT_WAYBILL_KEY);
      storage.removeItem(PAYMENT_WAYBILL_KEY);
      if (null != _waybill) {

        payType = _waybill.payType;
        if (_transactionId === _waybill.transactionId) {
          // OK
        } else {
          // chyba - sem by sa to nemalo dostat
        }
      } else {
        isOk = false;
        status = 4;
      }
    }

    this.state = { status, payType, isLoad: false, parkingMessages: { parkingSuccess: null, parkingVerify: null, parkingFailure: null } };
  };

  /** navrat na povodnu stranku */
  handleBack = () => {
    let searchValue = routes.PRELOGIN;
    if (isOk) {
      if (this.state.payType === 1 && this.props.isAuth)
        searchValue = routes.PARKING;

      else if (this.state.payType === 2)
        searchValue = routes.PZ_PARKING_SHORT;

      else if (this.state.payType === 3)
        searchValue = routes.TAXES;
    }
    this.props.navigate(searchValue);
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  handleVerify = (res) => {
    this.setState({ status: res });
    if (res > 1)
      console.error('PaymentGateway', false);
  };

  handleReport = async (iDocPark) => {
    this.setState({ isLoad: true });

    try {
      const data = await requests.getBlob(`/api/parking/invoice/${iDocPark}`);
      saveAs(data, `parkingTicket.pdf`);
      this.props.dispatch(userActions.showMsg("Sťahovanie súboru bolo úspešne dokončené."));
    }
    catch (error) {
      console.error(error);
      this.props.dispatch(userActions.showErrorMsg("Sťahovanie súboru zlyhalo."));
    }

    this.setState({ isLoad: false });
  };

  componentDidMount = async () => {

    if (_waybill.language !== "SK") {
      const lang = _waybill.language === "DE" ? "de-DE" : _waybill.language === "US" ? "en-US" : "sk-SK";
      await this.props.dispatch(userActions.setLocale(lang));
    }

    let parkingSuccess = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_BESTERON_RES_OK');
    let parkingVerify = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_BESTERON_RES_NOT_CONFIRM');
    let parkingFailure = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_BESTERON_RES_ER');

    if (isNil(parkingSuccess) || (typeof parkingSuccess === "string" && parkingSuccess.length < 1)) {
      parkingSuccess = this.props.translate('payment.parkingSuccess');
    }
    if (isNil(parkingVerify) || (typeof parkingVerify === "string" && parkingVerify.length < 1)) {
      parkingVerify = this.props.translate('payment.parkingVerify');
    }
    if (isNil(parkingFailure) || (typeof parkingFailure === "string" && parkingFailure.length < 1)) {
      parkingFailure = this.props.translate('payment.parkingFailure');
    }
    this.setState({ parkingMessages: { parkingSuccess, parkingVerify, parkingFailure } });
  };

  render() {

    const { classes, width } = this.props;

    // nastavenie url pre overenie platby
    const url = this.state.payType === 3 ? "/api/taxes/payment-verification" : "/api/parking/payment-verification";

    return (
      <div className='container' style={{ marginTop: '70px' }}>
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2}>
          <CircularProgress loading={this.state.status === 0 ? true : false} />
          <div className={classNames(classes.mb4, classes.navigationBackButton)}>
            <IconButton
              className={classNames(classes.p0)}
              color="inherit"
              onClick={this.handleBack}
              toolTip={this.props.translate('back')}
              toolTipPlace="right"
              disableHover
              disableRipple
            >
              <ArrowBack
                variant='fab'
                style={{ fontSize: 30 }}
              />
            </IconButton>
          </div>
          {isOk &&
            <PaymentGateway
              onVerify={this.handleVerify}
              url={url}
              waybill={_waybill}
            />
          }
          {this.state.status === 0 &&
            <div className={classNames(classes.mt6, classes.textCenter)}>
              <Typography className={classNames(classes.textCenter, classes.fontBold)} >
                {this.props.translate('payment.progress')}
              </Typography>
            </div>
          }
          {this.state.status === 1 &&
            <div>
              <div className='center'>
                <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/check_green.svg'} alt='PLATBA ÚSPEŠNÁ' />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                {this.state.payType === 1 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#008000' }}>
                    Platba za parkovaciu kartu prebehla úspešne.
                  </Typography>
                }
                {this.state.payType === 2 &&
                  <React.Fragment>
                    <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#008000' }}>
                      {this.state.parkingMessages.parkingSuccess}
                    </Typography>
                    <Paper className={classNames(this.props.classes.ml4, this.props.classes.mr4, this.props.classes.mt4)} style={{ borderRadius: '12px' }} elevation={3}>
                      <Button
                        onClick={() => this.handleReport(_waybill.iPk)}
                        style={{ borderRadius: '12px' }}
                        className={classNames(classes.w100)}
                      >
                        <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.ml3, this.props.classes.mr3)} >
                          <Typography variant="body2" className={classNames(this.props.classes.mt2, this.props.classes.mb2)}>{this.props.translate('payment.confirmationOfPayment')}</Typography>
                          <PdfIcon className={classNames(this.props.classes.mt2, this.props.classes.mb2)} style={{ color: '#d50000' }} />
                        </div>
                      </Button>
                    </Paper >
                  </React.Fragment>
                }
                {this.state.payType === 3 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#008000' }}>
                    Platba za dane a poplatky prebehla úspešne.
                  </Typography>
                }
              </div>
            </div>
          }
          {(this.state.status === 2 || this.state.status === 3) &&
            <div>
              <div className='center'>
                <InfoIcon style={{ width: 140, height: 140, color: '#ef6c00' }} />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                {this.state.payType === 1 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#ef6c00' }}>
                    Overenie platby za parkovaciu kartu sa nepodarilo!
                  </Typography>
                }
                {this.state.payType === 2 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#ef6c00' }}>
                    {this.state.parkingMessages.parkingVerify}
                  </Typography>
                }
                {this.state.payType === 3 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#ef6c00' }}>
                    Overenie platby za dane a poplatky sa nepodarilo!
                  </Typography>
                }
              </div>
            </div>
          }
          {this.state.status === 4 &&
            <div>
              <div className='center'>
                <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/failure_red.svg'} alt='PLATBA NEÚSPEŠNÁ' />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                {this.state.payType === 1 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#FF3333' }}>
                    Platba za parkovaciu kartu sa nepodarila!
                  </Typography>
                }
                {this.state.payType === 2 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#FF3333' }}>
                    {this.state.parkingMessages.parkingFailure}
                  </Typography>
                }
                {this.state.payType === 3 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#FF3333' }}>
                    Platba za dane a poplatky sa nepodarila!
                  </Typography>
                }
              </div>
            </div>
          }
        </Paper>
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(PayReceived)))));
