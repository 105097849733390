import actionTypes from '../../../actions/publicZone/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
};

const listRequestsGrid = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_REQUEST_GRID_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_REQUEST_GRID_SUCCESS:
      return { isLoading: false, data: action.res.Data.Items, total: action.res.Data.TotalRecords };

    case actionTypes.GET_LIST_REQUEST_GRID_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default listRequestsGrid;