import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class CitizensIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M16.5,5c0,1.7-0.3,3-2,3
		s-2-1.3-2-3s0.3-3,2-3S16.5,3.3,16.5,5z"/>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M9.5,7.5
		C9.5,8.3,8.8,9,8,9S6.5,8.3,6.5,7.5c0,0,0-1.1,0-1.1C6.6,5.6,7.2,5,8,5c0.8,0,1.4,0.6,1.5,1.4C9.5,6.4,9.5,7.5,9.5,7.5z"/>
          <path fill="#9E9E9E" d="M4.5,10c0,0.6-0.4,1-1,1s-1-0.4-1-1c0,0,0-1,0-1c0-0.5,0.5-1,1-1c0.5,0,1,0.5,1,1C4.5,9,4.5,10,4.5,10z" />
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M12.5,19v-5c0-1.7,0-3,2-3
		s2,1.4,2,3V19"/>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M9.5,18v-4.5
		C9.5,12.7,8.8,12,8,12s-1.5,0.7-1.5,1.5V18"/>
          <circle fill="#9E9E9E" cx="3.5" cy="13" r="1" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="3.5" y1="17" x2="3.5" y2="13" />
        </g>
      </SvgIcon>
    );
  }
}

CitizensIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default CitizensIconComponent;
