import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem";
import { InputAdornment } from "@mui/material";
import { IconButton as IconButtonPassword } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";

import TextField from '../../coraWebMComponents/inputs/TextField';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Error from '../../coraWebMComponents/feedBack/Error';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import ButtonLink from '../../coraWebMComponents/inputs/ButtonLink';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Switch from '../../coraWebMComponents/inputs/Switch';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import PersonIcon from "../../coraWebMComponents/dataDisplay/icons/PersonIcon";
import PeopleIcon from "../../coraWebMComponents/dataDisplay/icons/PeopleIcon";
import withRouter from '../../coraWebMComponents/utils/withRouter';
import * as storage from "../../coraWebMComponents/utils/storage";

import routes from '../../routes';
import * as userActions from '../../actions/userActions';
import PulseInfoButton from "../info/PulseInfoButton";
import AdminModeExitButton from "../adminMode/exitButton";
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as accountActions from '../../actions/accountActions';

const styles = theme => ({
  ...getSharedStyles(theme),
  loginContainer: {
    width: "100%",
    padding: "20px",
    maxWidth: "400px"
  },
  currentCity: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    letterSpacing: '0.25rem',
    fontSize: '17px',
    textTransform: 'capitalize',
    textAlign: 'center',
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '19px',
      letterSpacing: '0.32rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 0 20px 0',
      fontSize: '21px',
      letterSpacing: '0.4rem',
    },
  },
  appLogo: {
    width: '150px',
    height: '150px',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      height: '200px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '250px',
      height: '250px',
    }
  },
  loginRow: {
    [theme.breakpoints.up('lg')]: {
      minHeight: '100vh',
      alignItems: 'center'
    }
  },
  loginInput: {
    '& input, & div': {
      background: '#fff'
    }
  }
});

const PASSWORD_SHOW_TITLE = "Zobraz heslo";
const PASSWORD_HIDE_TITLE = "Skry heslo";

class Login extends React.Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem('username');
    const urlParams = new URLSearchParams(this.props.location.search);

    this.state = {
      openAlert: false,
      errorText: '',
      errorTextUser: '',
      errorTextPwd: '',
      user: username || '',
      pwd: '',
      remember: username !== null,
      code: urlParams.get("code"),
      isLoad: true,
      openChosenPeople: false,
      showPassword: false
    };
  }

  handleTogglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  componentDidMount = async () => {
    const { code } = this.state;
    if (code) {
      await this.props.dispatch(userActions.verifyCode(code));
      this.afterLogin();
    }

    if (!this.props.cityOfSetup)
      await this.props.dispatch(ciselnikyActions.getCityOfSetup());

    if (!this.props.egovHandlerUrl)
      await this.props.dispatch(ciselnikyActions.getEgovHandlerUrl());

    this.setState({ isLoad: false });

    if (this.props.versionInfo.length === 0) {
      this.props.dispatch(ciselnikyActions.getVersionInfo());
    }
  };

  handleOpenAlert = () => {
    const username = this.state.user.trim();
    if (username.length > 0)
      this.setState({ openAlert: true });
    else
      this.setState({ errorTextUser: 'Je nutné zadať prihlasovací e-mail.' });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  clearErrors = () => {
    this.setState({ errorText: '', errorTextUser: '', errorTextPwd: '' });
  };

  handleLogin = async () => {
    var username = this.state.user.trim();
    var pwd = this.state.pwd;

    if (!username) {
      this.setState({ errorTextUser: 'Je nutné zadať prihlasovací e-mail.' });
    }
    else {
      this.setState({ errorTextUser: '' });
    }

    if (!pwd) {
      this.setState({ errorTextPwd: 'Je nutné zadať heslo.' });
    }
    else {
      this.setState({ errorTextPwd: '' });
    }

    this.setState({ errorText: '', isLoad: true });
    if (username && pwd) {

      if (this.state.remember) {
        localStorage.setItem('username', username);
      }
      else {
        localStorage.removeItem('username');
      }
      await this.props.dispatch(userActions.login(username, pwd));
      this.afterLogin();
    }
    this.setState({ isLoad: false });
  };

  handleLoginEid = () => {
    const cfg = storage.getLoginConfig()?.eID ?? { disabled: true, accessUrl: null };
    if (!cfg.disabled && cfg.accessUrl) {
      const returnUrl = encodeURIComponent(window.location.origin + '/#/prihlasenieEid');
      const redirectUrl = `${cfg.accessUrl}/UpvsForClientHttpHandler.ashx?returnUrl=${returnUrl}`;
      window.open(redirectUrl, '_parent').focus();
    }
  };

  afterLogin = async () => {
    if (this.props.isAuth) {
      await this.props.dispatch(accountActions.getListPeople());
      await this.props.dispatch(accountActions.getPerson(this.props.prihlaseny.i_o));
      if (this.props.listPeople.length > 1) {
        this.setState({ openChosenPeople: true });
      }
      else {
        const person = this.props.listPeople[0];
        const isAlive = !(person?.D_UMR && person?.I_O_RO_STAV === 5);
        if (!isAlive) {
          this.showDeadPopUp();
          return;
        }
        this.props.navigate(this.state.code ? routes.ACCOUNT_MODIFY : routes.HOME);
      }
    }
    else {
      this.setState({ errorText: 'Nesprávne prihlasovacie údaje.', isLoad: false });
    }
  };

  handleChange = (name, value) => {
    if (name === 'user') {
      value = value.toLowerCase();
      this.setState({ errorTextUser: value ? '' : 'Je nutné zadať prihlasovací e-mail.' });
    }

    this.setState({ [name]: value });
  };

  handleGoHomePeople = async (i_o) => {
    await this.props.dispatch(accountActions.getPerson(i_o));
    this.props.navigate(this.state.code ? routes.ACCOUNT_MODIFY : routes.HOME);
  };

  handleClickLogo = () => {
    this.props.navigate(routes.PRELOGIN);
  };

  handleCreateCode = async () => {
    this.handleCloseAlert();
    const username = this.state.user.trim();
    if (username.length > 0) {
      this.props.dispatch(userActions.createCode(username));
      this.clearErrors();
    } else {
      this.setState({ errorTextUser: 'Je nutné zadať prihlasovací e-mail.' });
    }
  };

  handlePermit = () => {
    this.props.navigate(routes.ACCOUNT_PERMIT_SUCCESS);
  };
  handleGoPrelogin = () => {
    this.props.navigate(routes.PRELOGIN);
  };

  handleChangeRemember = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  showDeadPopUp = () => {
    this.props.dispatch(userActions.showErrorMsg("Nie je možné prihlásenie pre mŕtvu osobu"));
  };

  render() {
    const { classes, cityOfSetup } = this.props;
    const cfg = storage.getLoginConfig()?.eID ?? { disabled: true, accessUrl: null };
    const showEidButton = !cfg.disabled && cfg.accessUrl;
    const fMelements =
      <div className={classNames(classes.mb3)}>
        <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Výber osoby</Typography>
        {this.props.listPeople
          .filter((x) => x.L_ZASTUPUJUCI)
          .map((x) => (
            <MenuItem key={x.I_O_ZASTUP.toString()} onClick={(_event) => this.handleGoHomePeople(x.I_O_ZASTUP)}>
              <PersonIcon />
              <div className={classNames(classes.ml2, classes.mr3)}>
                <Typography>{x.ZASTUPOVANY}</Typography>
              </div>
            </MenuItem>
          ))}

        {this.props.listPeople
          .filter((x) => !x.L_ZASTUPUJUCI)
          .map((x) => {
            const isDead = x.D_UMR && x.I_O_RO_STAV === 5;
            const onClick = isDead ? this.showDeadPopUp : this.handleGoHomePeople;

            return (
              <MenuItem key={x.I_O_ZASTUP.toString()} onClick={() => onClick(x.I_O_ZASTUP)}>
                <PeopleIcon />
                <div className={classNames(classes.ml2, classes.mr3)}>
                  <Typography
                    style={isDead ? {
                      color: 'red',
                      fontWeight: 'bold'
                    } : null}
                  >{x.ZASTUPOVANY}</Typography>
                </div>
              </MenuItem>
            );
          })
        }

      </div>;
    return (
      <div className={classNames(classes.row, classes.justifyContentCenter, classes.loginRow)}>
        <CircularProgress loading={this.props.loading || this.state.isLoad} />
        <AdminModeExitButton />
        <div className={classNames(classes.loginContainer)}>
          <IconButton color="inherit" onClick={this.handleGoPrelogin} toolTip={this.props.translate("back")} toolTipPlace="right" className={classNames(classes.p0, classes.navigationBackButton)} disableRipple disableHover>
            <ArrowBack variant="fab" style={{ fontSize: 30 }} />
          </IconButton>
          <div className='center'>
            <img className={classNames(classes.appLogo)} src={'assets/datamesta_logo_login.svg'} alt='datamesta' onClick={this.handleClickLogo} />
          </div>
          <div className='center'>
            <Typography variant="subtitle1" className={classNames(classes.currentCity)}>{cityOfSetup?.HODN || ''}</Typography>
          </div>
          {this.props.isAuth && this.props.isActive && <div className='center'>
            <Typography variant="subtitle1" style={{ color: 'red' }}>{'Užívateľ už je prihlásený'}</Typography>
          </div>}
          <TextField
            label="E-mail"
            type="email"
            fullwidth={true}
            errorText={this.state.errorTextUser}
            error={this.state.errorTextUser ? true : false}
            onChange={this.handleChange}
            name='user'
            value={this.state.user}
            variant="outlined"
            style={{ marginTop: "30px" }}
            classes={{ root: classes.loginInput }}
            disabled={this.props.isAuth}
          />
          <TextField
            label="Heslo"
            type={this.state.showPassword ? "text" : "password"}
            fullwidth={true}
            errorText={this.state.errorTextPwd}
            error={this.state.errorTextPwd ? true : false}
            onChange={this.handleChange}
            name='pwd'
            value={this.state.pwd}
            variant="outlined"
            style={{ marginTop: "10px" }}
            classes={{ root: classes.loginInput }}
            disabled={this.props.isAuth}

            onKeypress={(event) => {
              if (event.key === 'Enter')
                this.handleLogin();
            }}
            imputProps={{
              endAdornment: (
                <InputAdornment position="end"    >
                  <IconButtonPassword
                    classes={{ root: classes.loginInput }}
                    aria-label={this.state.showPassword ? PASSWORD_HIDE_TITLE : PASSWORD_SHOW_TITLE}
                    title={this.state.showPassword ? PASSWORD_HIDE_TITLE : PASSWORD_SHOW_TITLE}
                    onClick={this.handleTogglePassword}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButtonPassword>
                </InputAdornment>
              ),
            }}
          />
          <RaisedButton
            fullwidth={true}
            onClick={this.handleLogin}
            disabled={this.props.loading || this.state.isLoad || this.props.isAuth}
            style={{ height: "60px" }}

          >
            {'Prihlásiť'}
          </RaisedButton>
          <Error text={this.state.errorText} />
          <div className={classNames(classes.row, classes.fullwidth, classes.mt4)}>
            <ButtonLink
              color='secondary'
              variant="body1"
              onClick={this.handleOpenAlert}
              className={classNames(classes.textItalic)}
            >
              {'Zabudli ste heslo?'}
            </ButtonLink>
            <ButtonLink
              color='secondary'
              variant="body1"
              onClick={this.handlePermit}
              className={classNames(classes.fontBold, classes.textItalic)}

            >
              {<u>Registrácia</u>}
            </ButtonLink>
          </div>
          <div className={classNames(classes.row, classes.justifyContentCenter, classes.mt2)}>
            <Switch
              color='default'
              checked={this.state.remember}
              onChange={this.handleChangeRemember('remember')}
              value='remember'
              label='Zapamätať prihlásenie'
            />
          </div>
          {showEidButton &&
            <>
              <div className={classNames(classes.row, classes.justifyContentCenter, classes.mt2, classes.mb3)}>
                <Typography variant="subtitle2" color='primary' className={classNames(classes.fontBold, classes.textItalic)}>alebo</Typography>
              </div>
              <RaisedButton
                fullwidth={true}
                onClick={this.handleLoginEid}
                disabled={this.props.loading}
                style={{ height: "60px", backgroundColor: '#F5F5F5', textTransform: 'none' }}
                variant="outlined"
              >
                <img src={'assets/SVK_flag.svg'} alt='Prihlásenie eID' className={classNames(classes.mr4)} />
                {'PRIHLÁSENIE eID'}
              </RaisedButton>
            </>
          }
          <PulseInfoButton type="Login" N_KLUC={"DM_NEWS"} currEdited={"infoText"} />
        </div>

        <Dialog
          open={this.state.openAlert}
          onClose={this.handleCloseAlert}
          contentText='Odoslať odkaz na jednorázové prihlásenie?'
          title='Otázka'
          actions={[
            {
              label: 'Nie',
              color: 'primary',
              onClick: this.handleCloseAlert,
              keyboardFocused: true
            },
            {
              label: 'Áno',
              color: 'primary',
              onClick: this.handleCreateCode,
              keyboardFocused: false
            }
          ]}
        />
        <FormModal
          open={this.state.openChosenPeople}
          elements={fMelements}
        />

      </div >

    );
  }
}

// Wrapping the InfoButton component with React.forwardRef to support ref
const ForwardedLogin = React.forwardRef((props, ref) => (
  <Login {...props} ref={ref} />
));

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
    loading: state.user.isLoading,
    prihlaseny: state.user.data,
    cityOfSetup: state.cityOfSetup.data.Data,
    egovHandlerUrl: state.egovHandlerUrl.data.Data,
    listPeople: state.listPeople.data,
    isActive: state.adminMode.isActive,
    versionInfo: state.versionInfo.data,
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(ForwardedLogin))));
