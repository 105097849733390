import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import PaymentGateway from '../../coraWebMComponents/PaymentGateway';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import withRouter from '../../coraWebMComponents/utils/withRouter';

import routes from '../../routes';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {},
  paperCard: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '30px',
    },
  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  },
  backToCardsLink: {
    marginLeft: '15px',
    padding: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      padding: '20px 0 20px 0',
    },
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

class Gateway extends React.Component {

  handleGoHome = () => {
    this.props.navigate(-1);
  };

  /** navrat na povodnu stranku */
  handleBack = () => {

    let searchValue = routes.PRELOGIN;
    let payType = 1;      //parkovacia karta

    const { waybill, VS } = this.props.location.state;
    if (null != waybill) {// riesenie CG HD 70888

      payType = waybill.payType;

    } else {  // povodne riesenie

      if (VS) {

        if (VS.substring(0, 1) === "9") {
          payType = 2;        //parkovaci listok
        } else if (VS.substring(0, 1) === "8") {
          payType = 3;        //poplatky a dane
        } else {
          payType = 1;        //parkovacia karta
        }
      }
    }

    if (payType === 1 && this.props.isAuth)
      searchValue = routes.PARKING;

    else if (payType === 2)
      searchValue = routes.PZ_PARKING_SHORT;

    else if (payType === 3)
      searchValue = routes.TAXES;

    this.props.navigate(searchValue);
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { classes, width } = this.props;

    if (!this.props.location.state) {
      return null;

    }
    const { waybill } = this.props.location.state;
    const payMethod = waybill?.payMethod ?? 0;

    return (
      <div className='container' style={{ marginTop: (width && this.isMobile(width)) ? '50px' : '70px' }}>
        <CircularProgress loading={payMethod === 1} />
        <div className={classNames(classes.mb4, classes.ml4, classes.navigationBackButton)}>
          <IconButton
            className={classNames(classes.p0)}
            color="inherit"
            onClick={this.handleBack}
            toolTip={this.props.translate('back')}
            toolTipPlace="right"
            disableHover
            disableRipple
          >
            <ArrowBack
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
        </div>
        {!(width && this.isMobile(width)) &&
          <Paper
            className={classes.paperCard}
            elevation={2}
          >
            <PaymentGateway
              className={classes.flexColumn}
              vs={this.props.location.state.VS}
              amount={this.props.location.state.CENA}
              lang={this.props.location.state.LANG ? this.props.location.state.LANG : 'SK'}
              email={this.props.location.state.EMAIL}
              onBack={this.handleGoHome}
              waybill={this.props.location.state.waybill}
              payData={this.props.location.state.payData}
            />
          </Paper>
        }
        {(width && this.isMobile(width)) &&
          <PaymentGateway
            className={classes.flexColumn}
            vs={this.props.location.state.VS}
            amount={this.props.location.state.CENA}
            lang={this.props.location.state.LANG ? this.props.location.state.LANG : 'SK'}
            email={this.props.location.state.EMAIL}
            onBack={this.handleGoHome}
            waybill={this.props.location.state.waybill}
            payData={this.props.location.state.payData}
          />
        }
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(Gateway)))));