import actionTypes from './actionTypes';
import * as rozpocetUtils from '../utils/rozpocetUtils';

const getInitProgRozpRequest = () => ({
  type: actionTypes.GET_INIT_PROG_ROZP_REQUEST
});

const getInitProgRozpSuccess = (res) => ({
  type: actionTypes.GET_INIT_PROG_ROZP_SUCCESS,
  res
});

const getInitProgRozpFailure = (error) => ({
  type: actionTypes.GET_INIT_PROG_ROZP_FAILURE,
  error
});

/**
 * Získanie konfiguračných metadát pre Programový rozpočet
 */
export const getInitProgRozp = () => {
  return async (dispatch) => {
    try {
      dispatch(getInitProgRozpRequest());
      let res = await rozpocetUtils.getProgRozpInit();
      dispatch(getInitProgRozpSuccess(res));
    } catch (error) {
      dispatch(getInitProgRozpFailure(error));
    }
  };
};

const getListProgRozpRequest = () => ({
  type: actionTypes.GET_LIST_PROG_ROZP_REQUEST
});

const getListProgRozpSuccess = () => ({
  type: actionTypes.GET_LIST_PROG_ROZP_SUCCESS,
});

const getListProgRozpFailure = (error) => ({
  type: actionTypes.GET_LIST_PROG_ROZP_FAILURE,
  error
});

/**
 * Získanie zoznamu rozpočtových položiek
 * @param {number} orgId ID organizácie
 * @param {number} rok Rok
 */
export const getListProgRozp = (orgId, rok) => {
  return async (dispatch) => {
    try {
      dispatch(getListProgRozpRequest());
      let res = await rozpocetUtils.getProgRozpList(orgId, rok);
      // INFO: data pre rozpocet nejdu cez Redux
      // dispatch(getListProgRozpSuccess(res));
      dispatch(getListProgRozpSuccess());
      return res;
    } catch (error) {
      dispatch(getListProgRozpFailure(error));
    }
  };
};
