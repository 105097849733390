import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
};

const listValidParkingCard = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_VALID_PARKING_CARD_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_VALID_PARKING_CARD_SUCCESS:
      return { isLoading: false, data: action.res?.Data?.Items, total: action.res?.Data?.TotalRecords ?? 0 };

    case actionTypes.GET_LIST_VALID_PARKING_CARD_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default listValidParkingCard;