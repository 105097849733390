import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useNavigate } from "react-router";

import EditIcon from "@mui/icons-material/Edit";
import { withStyles } from "@material-ui/core/styles";

import Button from "../../coraWebMComponents/inputs/Button";
import * as storage from "../../coraWebMComponents/utils/storage"
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";

import { adminModeSetActiveState, adminModeReset, } from "../../actions/adminModeActions";

const styles = (theme) => ({
  ...getSharedStyles(theme),
});

const AdminExitButton = ({ classes }) => {
  const navigate = useNavigate();
  const isActive = useSelector((state) => state.adminMode.isActive);
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch();

  const setAdminModeActive = (isActive) => {
    dispatch(adminModeSetActiveState(isActive));
    storage.setItem("adminMode", isActive)
    setOpenDialog(false);
    if (!isActive) {
      dispatch(adminModeReset());
      navigate("/");
    }
  };

  return (
    <>
      {isActive && (
        <Button
          variant="contained"
          color="secondary"
          type="danger"
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            width: "220px",
            margin: "24px",
          }}
          className={classNames(classes.w100, classes.textTransformNone)}
          onClick={() => setOpenDialog(true)}
          startIcon={<EditIcon />}
        >
          Ukončiť režim Správca
        </Button>
      )}
      <Dialog
        modal={false}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        contentText={
          "Informácia: Potvrdením budete odhlásený ako správca a všetky neuložené zmeny budú zahodené."
        }
        title="Administrácia portálu (ukončenie)"
        actions={[
          {
            label: "Zostať prihlásený",
            color: "primary",
            onClick: () => setOpenDialog(false),
            keyboardFocused: false,
          },
          {
            label: "Odhlásiť",
            color: "secondary",
            onClick: () => setAdminModeActive(false),
            keyboardFocused: true,
          },
        ]}
      />
    </>
  );
};

export default withStyles(styles)(AdminExitButton);
