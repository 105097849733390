import { useEffect, useState, useRef } from "react";

/**
 * Vrati predoslu value 
 * @param {*} value 
 * @returns 
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

/**
 * Ohandluje klik mimo komponentu
 * @returns 
 */
const useClickAway = () => {
  const [active, setActive] = useState(false);
  const clickAwayRef = useRef(null);

  function toggle(value) {
    if (value) {
      setActive(value);
    } else {
      setActive(!active);
    }
  }

  const handleClick = (e) => {
    if (clickAwayRef.current && !clickAwayRef.current.contains(e.target)) {
      setActive(false);
    }
  }

  useEffect(() => {
    if (active) document.addEventListener("mousedown", handleClick);
    else document.removeEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [active]);
  return { clickAwayRef, active, setActive, toggle };
}

/**
 * Vykona jednorazovo callback pri prvom rendery komponnety
 * @param {*} callback 
 */
const useDidMount = (callback) => {
  const didRun = useRef(false);

  useEffect(() => {
    if (!didRun.current) {
      callback();
      didRun.current = true;
    }
  }, [callback]);
}

/**
 * Vrati TRUE ak bezi prvy render komponentu, inac FALSE pri druhom a dalsom rendery
 * @returns 
 */
const useFirstRender = () => {
  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;
  return firstRender;
}


export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export { usePrevious, useClickAway, useDidMount, useFirstRender };
