import * as React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import classNames from 'classnames';
import Typography from '../dataDisplay/Typography';

class RadioButton extends React.Component {
  render() {
    return (
      <div className={classNames(this.props.horizontal && this.props.classes.row)} style={{ justifyContent: 'center' }}>
        {this.props.inputData.map(x =>
          <div key={x.name} className={classNames(!this.props.horizontal && this.props.classes.row)}>
            <Typography variant="body1" style={{ display: "flex", justifyContent: "center"}}>{x.name}</Typography>
            <Radio
              style={this.props.radioButtonStyle}
              checked={this.props.value === x.id}
              value={x.id}
              onChange={(e) => this.props.onChange(this.props.name, e.target.value)}
              name={x.name}
              disabled={this.props.disabled}
            />
          </div>
        )
        }
      </div>
    );
  }
}

RadioButton.propTypes = {
  value: PropTypes.string,
  inputData: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  radioButtonStyle: PropTypes.object,
}

export default RadioButton;