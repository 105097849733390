import * as requests from '../coraWebMComponents/utils/requests';

const buildAccountPermitUrl = (id = '') => {
  return `/api/account/accountPermit/${id}`;
};

export const addAccountPermit = async (accountPermit) => {
  const url = buildAccountPermitUrl();
  try {
    return await requests.post(url, accountPermit);
  }
  catch (error) {
    throw error;
  }
};


const buildAccountPersonUrl = (id = '') => {
  return `/api/account/accountPerson/${id}`;
};

export const addAccountPerson = async (accountPerson) => {
  const url = buildAccountPersonUrl();
  try {
    return await requests.post(url, accountPerson);
  }
  catch (error) {
    throw error;
  }
};

export const cancelAccountPerson = async (id) => {
  const url = buildAccountPersonUrl(id);
  try {
    await requests.cancel(url);
  }
  catch (error) {
    throw error;
  }
};

export const getZiadostByName = async (name) => {
  try {
    const response = await requests.get(`/api/account/subExist?un=${name}`);
    return response;
  }
  catch (error) {
    throw error;
  }
};

export const getPeopleByRc = async (icoRc) => {
  try {
    const response = await requests.get(`/api/account/sub?bn=${icoRc}`);
    return response;
  }
  catch (error) {
    throw error;
  }
};

export const getListPeople = async () => {
  try {
    const response = await requests.get(`/api/account/people`);
    if (response.Data === null)
      return { Data: { Items: [], TotalRecords: 0 } };

    return response;
  }
  catch (error) {
    throw error;
  }
};

const buildGetPersonUrl = (i_o) => {
  return `/api/account/person?i_o=${i_o}`;
};

export const getPerson = async (i_o) => {
  try {
    let buildedRequest = buildGetPersonUrl(i_o);
    return await requests.get(buildedRequest);
  }
  catch (error) {
    throw error;
  }
};

// const buildAccountModifyUrl = (id = '') => {
//   return `/api/account/accountModify/${id}`;
// }

// export const accountModify = async (accountModify) => {
//   const url = buildAccountModifyUrl();
//   try {
//     return await requests.put(url, accountModify);
//   }
//   catch (error) {
//     throw error;
//   }
// }

// export const setPermitVerify = async (id) => {
//   try {
//     let body = {};
//     return await requests.put(`/api/account/accountPermitVerify/${id}`, body);
//   }
//   catch (error) {
//     throw error;
//   }
// }

const buildVerifyEmailUrl = (id = '') => {
  return `/api/account/verifyEmail/${id}`;
};


export const getVerifyEmail = async (iVer) => {
  try {
    let buildedRequest = buildVerifyEmailUrl(iVer);
    return await requests.get(buildedRequest);
  }
  catch (error) {
    throw error;
  }
};

export const getVerifyEmailM = async (mail) => {
  try {
    return await requests.get(`/api/account/verifyEmail?mail=${mail}`);
  }
  catch (error) {
    throw error;
  }
};

export const addVerifyEmail = async (RcToken, Email, iVerify, code) => {
  const url = buildVerifyEmailUrl();
  try {
    let body = {
      I_VERIFY_EMAIL: iVerify,
      EMAIL: Email,
      RCTOKEN: RcToken,
      CODE: code
    };
    return await requests.post(url, body);
  }
  catch (error) {
    throw error;
  }
};

export const updVerifyEmail = async (iVer) => {
  const url = buildVerifyEmailUrl(iVer);
  try {
    return await requests.put(url);
  }
  catch (error) {
    throw error;
  }
};

export const quickActionAdd = async (iSpeedAkc) => {
  try {
    return await requests.post(`/api/account/quickActionAdd/${iSpeedAkc}`);
  }
  catch (error) {
    throw error;
  }
};

export const quickActionDel = async (iSpeedAkc) => {
  try {
    return await requests.post(`/api/account/quickActionDel/${iSpeedAkc}`);
  }
  catch (error) {
    throw error;
  }
};

export const getActiveQuickActions = async () => {
  try {
    return await requests.get(`/api/account/activeQuickActions`);
  }
  catch (error) {
    throw error;
  }
};

export const quickActionEnabled = async (iSpeedAkc) => {
  try {
    return await requests.get(`/api/account/quickActionEnabled/${iSpeedAkc}`);
  }
  catch (error) {
    throw error;
  }
};