import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class EuroCardComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={this.props.style}
        viewBox="0 0 65 48"
        className={this.props.className}
      >
        <g transform="translate(-111 -280)">
          <g className="a" transform="translate(111 280)" style={{ fill: "#fff", stroke: "#323232", strokeWidth: "2px" }}>
            <rect className="d" width="65" height="48" rx="5" style={{ stroke: "none" }} />
            <rect className="e" x="1" y="1" width="63" height="46" rx="4" style={{ fill: "none" }} />
          </g>
          <path className="b" d="M15,18.5c-2.51,0-4.68-1.42-5.76-3.5H15l1-2H8.58c-0.05-0.33-0.08-0.66-0.08-1s0.03-0.67,0.08-1H15l1-2H9.24 C10.32,6.92,12.5,5.5,15,5.5c1.61,0,3.09,0.59,4.23,1.57L21,5.3C19.41,3.87,17.3,3,15,3c-3.92,0-7.24,2.51-8.48,6H3l-1,2h4.06 C6.02,11.33,6,11.66,6,12s0.02,0.67,0.06,1H3l-1,2h4.52c1.24,3.49,4.56,6,8.48,6c2.31,0,4.41-0.87,6-2.3l-1.78-1.77 C18.09,17.91,16.62,18.5,15,18.5z" transform="translate(119 291.128)" style={{ fill: "#323232" }} />
          <line className="c" x2="14.587" transform="translate(154.5 293.5)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
          <line className="c" x2="14.587" transform="translate(154.5 300.706)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
          <line className="c" x2="14.587" transform="translate(154.5 308.812)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
          <line className="c" x1="8" transform="translate(161.087 316.018)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
        </g>
      </SvgIcon>
    );
  }
}


EuroCardComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default EuroCardComponent;
