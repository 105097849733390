import React, { Component } from 'react';

const modalBackgroundStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000000,
};

const modalContentStyle = {
  position: 'relative',
  padding: '30px',
  background: 'white',
  borderRadius: '8px',
  maxWidth: '80%',
  maxHeight: '80%',
  overflow: 'auto'
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  border: 'none',
  background: 'none',
  fontSize: '1.5em',
  "&:hover": {
    color: 'red'
  }
};

class ImageModal extends Component {
  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { show, image, onClose } = this.props;
    if (!show) return null;
    console.log(image);
    return (
      <div style={modalBackgroundStyle} onClick={onClose}>
        <div style={modalContentStyle} onClick={this.stopPropagation}>
          <button style={closeButtonStyle} onClick={onClose}>X</button>
          <img src={image} alt="Modal Content" style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    );
  }
}

export default ImageModal;
