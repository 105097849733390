import React, { useState } from 'react';
import classNames from 'classnames';
import { AutoSizer } from 'react-virtualized';

import Box from '@mui/material/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import FlatButton from '../../coraWebMComponents/inputs/FlatButton';
import MoreVertIcon from "../../coraWebMComponents/dataDisplay/icons/MoreVertIcon";

import VirtualizedTable from './VirtualizedTable';
import { Transition } from './support';
import { getTotalText, getLoadedText } from './RecordCountInfo';

const styles = (theme) => ({
  ...getSharedStyles(theme),
  container: {
    display: "flex",
  },
  riRoot: {
    display: "flex",
    alignItems: "center",
  },
  riBtn: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
  },
  riIcon: {},
  rim: {},
  rimItem: {},
  rimItemIcon: {},
  rimText: {
    fontSize: theme.palette.secondary.fontSize,
  },
  requiredColor: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    boxSizing: "border-box"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  data1: {
    width: '100%',
    height: '200px',
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: "border-box"
  },
  dialogTitle: {
    fontSize: 24,
  },
  helpBoxWrapper: {
    backgroundColor: theme.palette.grey[200],
    boxSizing: "border-box",
    borderRadius: "0.3rem",
  },
  helpBoxTitle: {
  },
  helpBoxInfoIcon: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[400]

  },
  helpBoxItem: {
    marginBottom: '-0.2rem',
  },
  helpBoxLegenda: {
    width: "2.5rem",
    marginBottom: '-0.2rem',
  },
  helpBoxGraph: {
    height: '0.3rem',
  },
  rciRoot: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    alignSelf: "center",
  },
  rciTotal: {
    justifyContent: "flex-end",
    alignItems: "center",
    // fontFamily: theme.typography.fontFamily,
    // fontSize: theme.typography.fontSize,
  },
});

function DevDialogComponent({ classes, schema, frmData, isOpen, onClose, loadMoreData, devFilter, setDevFilter }) {

  const columns = [
    {
      name: 'Id',
      header: 'IFrmPod',
      width: 72,
      cell: rowData => rowData['Id'],
      cellProps: { align: 'center' }
    },
    {
      name: 'IFrm',
      header: 'IFrm',
      width: 100,
      cell: rowData => rowData['IFrm'],
      cellProps: { align: 'center' }
    },
    {
      name: 'Identif',
      header: 'Identif',
      width: 150,
      cell: rowData => rowData['Identif'],
      cellProps: { align: 'left' }
    },
    {
      name: 'Name',
      header: 'Name',
      width: 300,
      cell: rowData => rowData['Name'],
      cellProps: { align: 'left' }
    },
    {
      name: 'JoinCount',
      header: 'JoinCount',
      width: 100,
      cell: rowData => rowData['joinCount'],
      cellProps: { align: 'rigth' }
    },
    {
      name: 'rowCount',
      header: 'rowCount',
      width: 100,
      cell: rowData => rowData['rowCount'],
      cellProps: { align: 'rigth' }
    }
  ];

  columns.push({
    name: 'chkFlt',
    header: "",
    cell: rowData => (
      <Checkbox
        checked={devFilter[rowData.fldName]}
        onChange={handleChange(rowData.fldName)} />
    ),
    width: 72,
    cellProps: { style: { paddingRight: 0 } },
  });

  function handleChange(fldName) {
    return event => {
      if (event.target.type === 'checkbox') {
        setDevFilter(fldName, event.target.checked);
      }
    };
  }

  const { data, total } = frmData;
  const totalText = getTotalText(total);
  const loadedText = getLoadedText(data.length);

  const rows = (() => {
    const res = schema.Frms.map(x => {
      const fldName = `FrmPod${x.Id}`;
      const joinCount = x.Joins.length;
      const rowCount = data.filter(z => z[fldName]?.total > 0).length;
      return ({ ...x, fldName, joinCount, rowCount });
    });

    return res;
  })();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll='paper' maxWidth={'md'} aria-labelledby="scroll-dialog-title" fullWidth={true}
      fullScreen={false}
      TransitionComponent={Transition}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.root}>
        <Typography variant="h6">Info</Typography>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classNames(classes.rciRoot)}>
          <Box className={classNames(classes.rciTotal, classes.row)}>
            <Typography variant={"caption"}>{`Celkom\xA0`}</Typography>
            <Typography variant={"caption"} fontWeight="bold">{total}</Typography>
            <Typography variant={"caption"}>{`\xA0${totalText}, z toho\xA0`}</Typography>
            <Typography variant={"caption"} fontWeight="bold">{data.length}</Typography>
            <Typography variant={"caption"}>{`\xA0${loadedText}.`}</Typography>
          </Box>
        </Box>
        <Box className={classNames(classes.data1)}>
          <AutoSizer>
            {({ width, height }) => (
              <VirtualizedTable
                data={rows}
                columns={columns}
                width={width}
                height={height}
                includeHeaders={true}
                fixedRowCount={1}
                rowHeight={40}
              />
            )}
          </AutoSizer>
        </Box>
      </DialogContent>

      <DialogActions>
        <FlatButton key='Cancel' label='Zrušiť' color='primary' onClick={onClose}>
          Zrušiť
        </FlatButton>
        <FlatButton key='Save' label='Načítaj ďalšie záznamy' color='secondary' onClick={() => loadMoreData()} disabled={!frmData.hasMore} />
      </DialogActions>
    </Dialog>
  );
}

function DevButtonComponent({ classes, schema, frmData, loadMoreData, devFilter, setDevFilter }) {

  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <div className={classNames(classes.riRoot)}>
        <IconButton
          className={classNames(classes.riBtn, classes.requiredColor)}
          onClick={() => setShowDialog(true)}
          color="inherit"
          toolTip="Dev"
          toolTipPlace="left"
        >
          <MoreVertIcon className={classNames(classes.riIcon)} />
        </IconButton>
      </div>

      <DevDialog
        classes={classes}
        schema={schema}
        frmData={frmData}
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        loadMoreData={loadMoreData}
        devFilter={devFilter}
        setDevFilter={setDevFilter}
      />
    </>
  );
}

const DevDialog = withStyles(styles)(DevDialogComponent);

const DevButton = withStyles(styles)(DevButtonComponent);

export function createDevButton(primaryField, schema, frmData, devProps) {
  return (
    <DevButton
      primaryField={primaryField}
      schema={schema}
      frmData={frmData}
      loadMoreData={devProps.loadMoreData}
      devFilter={devProps.devFilter}
      setDevFilter={devProps.setDevFilter}
    />
  );
}

export const getInitDevFlt = (frmPodList) => {
  const res = {};
  frmPodList.forEach(x => {
    const fldName = `FrmPod${x.Id}`;
    res[fldName] = false;
  });
  return res;
};
