import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: 0
}

const maxHoursOneTicket = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_MAX_HOURS_ONE_TICKET_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_MAX_HOURS_ONE_TICKET_SUCCESS:
      const HODN = Number(action.res.Data.HODN) || 0;
      return { isLoading: false, data: HODN }

    case actionTypes.GET_MAX_HOURS_ONE_TICKET_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default maxHoursOneTicket;