import { pieArcLabelClasses } from '@mui/x-charts/PieChart';

// Default styl pre kontainer obalujuci graf
export const chartContainerStyleDefault = {
  backgroundColor: "#FAFAFA",
  border: '1px dotted #BDBDBD',
  margin: '0.4rem',
  // width: 'calc(100% - 0.8rem)',
  // height: 600,
};

// Default MUI styl pre BAR chart
export const barChartClassNameDefault = {
  p: 0,
};
// Default MUI styl pre LINE chart
export const lineChartClassNameDefault = {
  p: 0,
  '& .MuiLineElement-root': { // styl ciary vseobecne
    strokeDasharray: '0', // prerusovana ciara '<dlzka>, <medzera>'
    strokeWidth: 2, // hrubka ciary
  },
};

// Default MUI styl pre PIE chart
export const pieChartClassNameDefault = {
  p: 0,
  [`& .${pieArcLabelClasses.root}`]: { // pismo v kolaci
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    // fill: 'white',
    fontSize: "0.8rem",
  },
};