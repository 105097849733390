import actionTypes from './actionTypes';
import * as publicUtils from '../../utils/publicZone/publicUtils';

const addParkingShortRequest = () => ({
  type: actionTypes.ADD_PARKING_SHORT_REQUEST
});

const addParkingShortSuccess = (res) => ({
  type: actionTypes.ADD_PARKING_SHORT_SUCCESS,
  res
});

const addParkingShortFailure = (error) => ({
  type: actionTypes.ADD_PARKING_SHORT_FAILURE,
  error
});

export const addParkingShort = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addParkingShortRequest());
      let res = await publicUtils.addParkingShort(data);
      const iDocPark = res.Data.I_DOC_PARK;
      dispatch(addParkingShortSuccess(res));
      return iDocPark;
    } catch (error) {
      dispatch(addParkingShortFailure(error));
      return -1;
    }
  }
}

const getListCardTypeEShopSuccess = (res) => ({
  type: actionTypes.GET_LIST_CARD_TYPE_ESHOP_SUCCESS,
  res
});

const getListCardTypeEShopRequest = () => ({
  type: actionTypes.GET_LIST_CARD_TYPE_ESHOP_REQUEST
});

const getListCardTypeEShopFailure = (error) => ({
  type: actionTypes.GET_LIST_CARD_TYPE_ESHOP_FAILURE,
  error
});

export const getListCardTypeEShop = (iOtyp) => {
  return async (dispatch) => {
    try {
      dispatch(getListCardTypeEShopRequest());
      let res = await publicUtils.getListCardTypeEShop(iOtyp);
      dispatch(getListCardTypeEShopSuccess(res));
    } catch (error) {
      dispatch(getListCardTypeEShopFailure(error));
      console.error(error);
    }
  }
}


const addParkCardRequest = () => ({
  type: actionTypes.ADD_PARK_CARD_REQUEST
});

const addParkCardSuccess = (res) => ({
  type: actionTypes.ADD_PARK_CARD_SUCCESS,
  res
});

const addParkCardFailure = (error) => ({
  type: actionTypes.ADD_PARK_CARD_FAILURE,
  error
});

export const addParkCard = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addParkCardRequest());
      let res = await publicUtils.addParkCard(data);
      let parkingCard = null;
      if (res && res.Data)
        parkingCard = res.Data;
      dispatch(addParkCardSuccess(res));
      return parkingCard;
    } catch (error) {
      dispatch(addParkCardFailure(error));
      return null;
    }
  }
}

const getTicketPriceSumsSuccess = (res) => ({
  type: actionTypes.GET_TICKET_PRICE_SUMS_SUCCESS,
  res
});

const getTicketPriceSumsRequest = () => ({
  type: actionTypes.GET_TICKET_PRICE_SUMS_REQUEST
});

const getTicketPriceSumsFailure = (error) => ({
  type: actionTypes.GET_TICKET_PRICE_SUMS_FAILURE,
  error
});

export const getTicketPriceSums = (iZona, ecv) => {
  return async (dispatch) => {
    try {
      dispatch(getTicketPriceSumsRequest());
      let res = await publicUtils.getTicketPriceSums(iZona, ecv);
      dispatch(getTicketPriceSumsSuccess(res));
    } catch (error) {
      dispatch(getTicketPriceSumsFailure(error))
    }
  }
}

export const getTicketPriceSumsOverlap = (iZona, ecv, dOd, dDo) => {
  return async (dispatch) => {
    try {
      dispatch(getTicketPriceSumsRequest());
      let res = await publicUtils.getTicketPriceSumsOverlap(iZona, ecv, dOd, dDo);
      dispatch(getTicketPriceSumsSuccess(res));
    } catch (error) {
      dispatch(getTicketPriceSumsFailure(error))
    }
  }
}

const getTicketTimeSumsSuccess = (res) => ({
  type: actionTypes.GET_TICKET_TIME_SUMS_SUCCESS,
  res
});

const getTicketTimeSumsRequest = () => ({
  type: actionTypes.GET_TICKET_TIME_SUMS_REQUEST
});

const getTicketTimeSumsFailure = (error) => ({
  type: actionTypes.GET_TICKET_TIME_SUMS_FAILURE,
  error
});

export const getTicketTimeSums = (iZona, ecv) => {
  return async (dispatch) => {
    try {
      dispatch(getTicketTimeSumsRequest());
      let res = await publicUtils.getTicketTimeSums(iZona, ecv);
      dispatch(getTicketTimeSumsSuccess(res));
    } catch (error) {
      dispatch(getTicketTimeSumsFailure(error))
    }
  }
}