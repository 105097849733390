import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class GraveIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M13,14V5.5
		C13,3.6,11.4,2,9.5,2S6,3.6,6,5.5V14"/>
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="8" y1="7.5" x2="11" y2="7.5" />
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="9.5" y1="6" x2="9.5" y2="11" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="3" y1="15" x2="16" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="1" y1="18" x2="18" y2="18" />
        </g>
      </SvgIcon>
    );
  }
}

GraveIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default GraveIconComponent;
