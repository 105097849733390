import actionTypes from '../../actions/actionTypes';
import * as storage from '../../coraWebMComponents/utils/storage';

export const PERSON_KEY = 'datamesta_person';

const _initState = {
  isLoading: false,
  data: storage.getItem(PERSON_KEY)
};

const person = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERSON_REQUEST:
      storage.removeItem(PERSON_KEY);
      return { data: state.data, isLoading: true };

    case actionTypes.GET_PERSON_SUCCESS:
      storage.setItem(PERSON_KEY, action.res.Data);
      return { isLoading: false, data: action.res ? action.res.Data : storage.getItem(PERSON_KEY) };

    case actionTypes.GET_PERSON_FAILURE:
      storage.removeItem(PERSON_KEY);
      return _initState;

    default:
      return state;
  }
};

export default person;