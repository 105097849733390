const SUBSIDIES_DAYS_REMAINING = 3;

const C_STAV = [
  {
    I_STAV: 1,
    N_STAV: 'Nehodnotené žiadosti',
    color: '#f00203', //red
    printedValue: 'Nehodnotená'
  },
  {
    I_STAV: 2,
    N_STAV: 'Hodnotené žiadosti',
    color: '#4e9117', //green
    printedValue: 'Hodnotená'
  },
  {
    I_STAV: 3,
    N_STAV: 'Čiastočne hodnotené žiadosti',
    color: '#ff5124', //orange
    printedValue: 'Čiastočne hodnotená'
  }
];

export { SUBSIDIES_DAYS_REMAINING, C_STAV };
