import React from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from "react-dom/server";
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import EditOutlined from '@mui/icons-material/EditOutlined';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Recaptcha from '../../coraWebMComponents/Recaptcha';
import EmailIcon from '../../coraWebMComponents/dataDisplay/icons/EmailIcon';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Error from '../../coraWebMComponents/feedBack/Error';
import Editor from "../../coraWebMComponents/inputs/Editor";
import { isValidMail } from '../../coraWebMComponents/utils/validator';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import Dialog from "../../coraWebMComponents/feedBack/Dialog";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import withRouter from '../../coraWebMComponents/utils/withRouter';

import routes from '../../routes';
import * as accountActions from '../../actions/accountActions';
import { adminModeSetEditedData, adminModeUpdateOrAdd } from "../../actions/adminModeActions";
import { IFrmTxt } from "../../constants/adminConsts";

const styles = theme => ({
  ...getSharedStyles(theme),
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  },
  gridFlex: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
  },
});

class ParkCardVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EMAIL: '',
      RCTOKEN: '',
      text: '',
      success: 0,
      errorTextEmail: '',
      errorTextRcToken: '',
      isEditActive: false,
      openSaveDialog: false,
    };
  };

  componentDidMount = async () => {
    if (this.props.location.state?.adminModeActive)
      this.setState({ success: 2 });
    const txt = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PZ_VERIFY_EMAIL');
    this.setState({ text: txt });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.adminMode.isActive !== this.props.adminMode.isActive) {
      this.setState({ isEditActive: false });
    }
  };
  ReactToHtml = (component) => {
    const HTML = ReactDOMServer.renderToStaticMarkup(component);
    const HTMLstring = HTML.toString();
    return HTMLstring;
  };

  handleChange = (name, value) => {
    switch (name) {
      case 'EMAIL':
        this.setState({ EMAIL: value, errorTextEmail: value ? isValidMail(value) : 'E-mail je povinný údaj!' });
        break;
      case 'RCTOKEN':
        this.setState({ RCTOKEN: value, errorTextRcToken: '' });
        break;
      default:
    }
  };

  handleRecaptcha = (token) => {
    this.handleChange('RCTOKEN', token);
  };

  handleAdd = async () => {
    const RcToken = this.state.RCTOKEN;
    const Email = this.state.EMAIL;
    const errorTextEmail = Email ? isValidMail(Email) : 'E-mail je povinný údaj!';
    const errorTextRcToken = RcToken ? '' : 'Zaškrtnite položku: Nie som robot!';

    this.setState({ errorTextRcToken, errorTextEmail });

    if (!errorTextRcToken && !errorTextEmail) {
      this.setState({ success: 1 });

      await this.props.dispatch(accountActions.GetVerifyEmailM(Email));
      const lVerify = this.props.verifyEmail ? this.props.verifyEmail.L_VERIFY : false;

      if (lVerify)
        this.props.navigate({ pathname: routes.PZ_PARKING_CARD, search: `?iVer=${this.props.verifyEmail.I_VERIFY_EMAIL_GUID}` });
      else {
        await this.props.dispatch(accountActions.AddVerifyEmail(RcToken, Email, 1, ''));
        this.setState({ success: 2 });
      }
    };
  };

  isMobile(width) {
    if (width === "xs" || width === "sm")
      return true;

    return false;
  }

  closeDialog = () => {
    this.setState({ openSaveDialog: false });
  };

  handleEditData = (data) => {
    this.props.dispatch(
      adminModeSetEditedData([{
        TxtFrm: data,
        ID: 4004003,
        locale: this.props.locale,
        N_KLUC: "DM_PZ_VERIFY_EMAIL",
        IFrmTxt: 32,
      }
      ])
    );
  };

  handleSaveSection = async () => {
    const { dataEdited } = this.props.adminMode;
    await this.props.dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
    this.setState({ isEditActive: false });
    const txt = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PZ_VERIFY_EMAIL');
    this.setState({ text: txt });
    this.closeDialog();
  };

  handleSkip = () => {
    if (this.state.success === 0) {
      this.setState({ success: 2 });
    } else if (this.state.success === 2) {
      this.props.navigate({ pathname: routes.PZ_PARKING_CARD });
    }
  };
  handleBack = () => {
    if (this.state.success === 0) {
      this.props.navigate(routes.PRELOGIN);
    } else if (this.state.success === 2) {
      if (this.props.location.state?.adminModeActive) {
        this.props.location.state.adminModeActive = false;
      }
      this.setState({ success: 0 });
    }
  };
  render() {
    const { classes, width } = this.props;
    return (
      <div className='container' style={{ marginTop: '70px' }}>
        <CircularProgress loading={this.state.success === 1} />
        <Paper className={classNames(classes.paperCardNoAppbar, (width && !this.isMobile(width) && classes.mt4))} elevation={(width && this.isMobile(width)) ? 0 : 2} >
          <Button
            color="inherit"
            className={classNames(classes.navigationBackButton)}
            aria-label="Prihlásenie"
            onClick={this.handleBack}
          >
            <ArrowBack
              fontSize='default'
              variant='fab'
            />
          </Button>
          {this.props.adminMode.isActive &&
            <Button
              style={{ float: 'right' }}
              onClick={this.handleSkip}
            >
              <ArrowForward
                fontSize='default'
                variant='fab'

              />
            </Button>
          }

          <Typography variant="subtitle1" className={classNames(classes.mb4, classes.textCenter)}>Overenie e-mailu</Typography>
          {this.state.success < 2 &&
            <div >
              <TextField
                type="email"
                label="E-mail"
                fullwidth={true}
                value={this.state.EMAIL}
                errorText={this.state.errorTextEmail}
                error={this.state.errorTextEmail ? true : false}
                onChange={this.handleChange}
                name='EMAIL'
                variant="standard"
                imputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {!this.props.location?.state?.adminModeActive &&
                <div className={classNames(classes.mt4)}>
                  <Recaptcha onChange={this.handleRecaptcha} />
                </div>
              }

              <div className={classNames(classes.textCenter)}>
                <Error text={this.state.errorTextRcToken} />
              </div>

              <div className={classNames(classes.flexColumn, classes.alignItemsCenter)}>
                {this.props.adminMode.isActive &&
                  this.state.isEditActive &&
                  this.state.text.length > 0 &&
                  <Editor
                    onChange={this.handleEditData}
                    value={this.ReactToHtml(this.state.text)}
                  ></Editor>
                }
                <div className={classes.gridFlex}>
                  {!this.state.isEditActive && this.state.text.length > 0 &&
                    <div className={classNames(classes.mb3, classes.mt2,)}>
                      <Typography variant="caption" >
                        {this.state.text}
                      </Typography>
                    </div>
                  }
                  {!this.state.isEditActive && this.props.adminMode.isActive && (
                    <IconButton
                      className={classNames(classes.ml3)}
                      color="inherit"
                      onClick={() => this.setState({ isEditActive: true })}
                      toolTip="Editovat"
                      toolTipPlace="right"
                      disableHover={true}
                    >
                      <EditOutlined
                        color="secondary"
                        variant="fab"
                        style={{ fontSize: 30 }}
                      />
                    </IconButton>
                  )}

                </div>
                {this.props.adminMode.isActive &&
                  this.state.isEditActive &&
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      width: "200px",
                    }}
                    type="success"
                    className={classNames(classes.w100, classes.textTransformNone)}
                    onClick={() => this.setState({ openSaveDialog: true })}
                  >
                    <SaveIcon className={classes.mr1} />
                    Uložiť
                  </Button>
                }
              </div>

              <div className={classNames(classes.row, classes.justifyContentCenter)}>
                <RaisedButton
                  className={classNames(classes.raisedButton)}
                  onClick={this.handleAdd}
                  disabled={!this.state.EMAIL || this.state.errorTextEmail}
                >
                  <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                    <Typography variant="button" style={{ color: '#fff' }}>
                      Overiť
                    </Typography>
                    <ArrowForward
                      fontSize='large'
                    />
                  </div>
                </RaisedButton>
              </div>
            </div>}

          {this.state.success === 2 &&
            <div>
              <div className='center'>
                <img style={{ width: 200, height: 200 }} className={classNames(classes.mb4)} src={'assets/MessageSent.svg'} alt='VERIFIKÁCIA' />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                <Typography className={classNames(classes.textCenter, classes.fontBold)} >
                  BOL VÁM ODOSLANÝ <br />
                  VERIFIKAČNÝ E-MAIL
                </Typography>
              </div>
              <div className={classNames(classes.mt4, classes.mb4)}>
                <Typography className={classNames(classes.textCenter)}>
                  KLIKNUTÍM NA LINK <br />
                  POTVRDÍTE SPRÁVNOSŤ VÁŠHO E-MAILU<br />
                  A MÔŽETE SI ZAKÚPIŤ PARKOVACIU KARTU.
                </Typography>
              </div>
            </div>}
        </Paper>
        <Dialog
          modal={false}
          open={this.state.openSaveDialog}
          onClose={this.handleCloseDialog}
          contentText="Informácia: Potvrdením uložíte zmeny"
          title="Uloženie nastavení"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: this.closeDialog,
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: this.handleSaveSection,
              keyboardFocused: true,
            },
          ]}
        />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verifyEmail: state.verifyEmail.data,
    adminMode: state.adminMode
  };
};



export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(ParkCardVerify)))));