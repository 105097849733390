const metaGridReducer = async (action, data, state, prevState) => {
  switch (action) {
    case "ADD_ROWS":

      const { frmData } = state;
      const { actualData } = data;

      if (frmData.total !== actualData.total) {
        frmData.total = actualData.total;
      }
      if (actualData.items.length > 0) {
        frmData.data = frmData.data.concat(actualData.items);
      }
      frmData.hasMore = frmData.total > 0 && frmData.total > frmData.data.length;
      frmData.isLoading = false;
      return frmData;
    case "FAKE":
      const schema = data.obj.self.state.schema;
      console.debug(`FrmPod.Count=${schema.Frms.length},hasPreState=${undefined !== prevState}`);
      return state;
    default:
      return state;
  }
};

export default metaGridReducer;
