import React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';

class CancelOutlinedIconComponent extends React.Component {
  render() {
    return (
      <CancelOutlinedIcon
        style={this.props.style}
        className={this.props.className}
      />
    );
  }
}

CancelOutlinedIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default CancelOutlinedIconComponent;
