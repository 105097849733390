import React from 'react';
import * as requests from './requests';

/**
 * Vráti zabalený komponent platobnej brány
 * @module
 * @param {element} WrappedComponent - Zabaľovaný komponent
 */
const WithPaymentGateway = (WrappedComponent) => {
  class HOC extends React.Component {

    /** Asynchronná funkcia na vytvorenie platby */
    paymentIntent = async (data) => {

      const url = `/api/payment-gateway/payment-intent`;

      try {
        let res = await requests.post(url, data);
        return res.Data;
      }
      catch (error) {
        throw error;
      }

    };

    /** Asynchronná funkcia na overenie platby */
    paymentVerification = async (url, data) => {

      try {
        let res = await requests.post(url, data);
        return res.Data;
      }
      catch (error) {
        throw error;
      }

    };

    /**
     * Pôvodné riešenie - Asynchronná funkcia na získanie podpisu pre vytvorenie platby
     * @function sign
     * @param {string} amnt Suma, ktorú klient prevedie na účet príjemcu
     * @param {string} vs Variabilný symbol
     * @param {string} lang Jazyk
     * @param {string} email Email
     * @returns {Object} Metadáta pre vytvorenie platby
     */
    sign = async (amnt, vs, lang, email) => {

      const url = `/api/payment-gateway/sign?amnt=${amnt}&vs=${vs}&lang=${lang}&email=${email}`;

      try {
        let res = await requests.get(url);
        return res;
      }
      catch (error) {
        throw error;
      }
    }

    /**
     * Pôvodné riešenie - Asynchronná funkcia na overenie platby.
     * @function isReceived
     * @param {string} url Url adressa pre overenie platby
     * @param {string} vs Variabilný symbol pre overenie platby
     * @returns {Object} Informácie o platbe
     */
    isReceived = async (url, vs) => {
      const u = `${url}?vs=${vs}`;

      try {
        let res = await requests.get(u);
        return res.Data;
      }
      catch (error) {
        throw error;
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          // overenie platby
          paymentIntent={this.paymentIntent}
          // overenie platby
          paymentVerification={this.paymentVerification}
          // pôvodné riešenie - získanie podpisu pre vytvorenie platby
          sign={this.sign}
          // pôvodné riešenie - overenie platby
          isReceived={this.isReceived}
        />
      );
    }
  }

  return HOC;
};

export default WithPaymentGateway;