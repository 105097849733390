import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: null
}

const visiblePlanButton = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_VISIBLE_PLAN_BUTTON_REQUEST:
      return { data: false, isLoading: true };

    case actionTypes.GET_VISIBLE_PLAN_BUTTON_SUCCESS:
      return { isLoading: false, data: action.res?.Data?.HODN === '1' ? true : false }

    case actionTypes.GET_VISIBLE_PLAN_BUTTON_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default visiblePlanButton