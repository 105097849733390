import React from 'react';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';

/**
 * Menu component
 * @module
 * @param {string} [id] - Id of menu
 * @param {object} [anchorEl] - DOM element used to set the position of the menu
 * @param {object} [anchorOrigin] - This is the point on the anchor where the menu's anchorEl will attach to
 * @param {object} [transformOrigin] - This is the point on the menu which will attach to the anchor's origin.
 * @param {bool} [open] - Whether the menu is open
 * @param {func} [onClose] - Close handler function
 */
class MenuComponent extends React.Component {
  render() {
    return (
      <Menu
        id={this.props.id}
        anchorEl={this.props.anchorEl}
        anchorOrigin={this.props.anchorOrigin}
        transformOrigin={this.props.transformOrigin}
        open={this.props.open}
        onClose={this.props.onClose}
        classes={this.props.classes}
      >
        {this.props.children}
      </Menu>
    )
  }
}

MenuComponent.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object,
}

export default MenuComponent;