import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";

import PersonIcon from "../../coraWebMComponents/dataDisplay/icons/PersonIcon";
import PeopleIcon from "../../coraWebMComponents/dataDisplay/icons/PeopleIcon";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";
import MoreVertIcon from "../../coraWebMComponents/dataDisplay/icons/MoreVertIcon";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import AddBlackIcon from "../../coraWebMComponents/dataDisplay/icons/AddBlackIcon";
import CancelRoundedIcon from "../../coraWebMComponents/dataDisplay/icons/CancelRoundedIcon";
import ClearIcon from "../../coraWebMComponents/dataDisplay/icons/ClearIcon";
import * as storage from "../../coraWebMComponents/utils/storage";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Button from "../../coraWebMComponents/inputs/Button";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import EmailIcon from '../../coraWebMComponents/dataDisplay/icons/EmailIcon';
import CheckCircleIcon from "../../coraWebMComponents/dataDisplay/icons/CheckCircleIcon";
import FormModal from '../../coraWebMComponents/inputs/FormModal';

import * as userActions from '../../actions/userActions';
import * as accountActions from '../../actions/accountActions';
import { adminModeSetActiveState, adminModeReset } from '../../actions/adminModeActions';

const styles = (theme) => ({
  ...getSharedStyles(theme),
  currentPersonMenuitem: {
    height: "auto",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  menuPaper: {
    minWidth: "300px!important",
  },
  closeSidebarBtn: {
    padding: "7px",
    backgroundColor: "transparent",
    border: 0,
    boxShadow: "none",
    outline: "none",
    cursor: "pointer",
    position: "absolute",
    right: '15px',
    top: '22px'
  },
  closeSidebarBtnIcon: {
    color: theme.palette.secondary.main,
  },
});

let _iOsoby = null, _pf = '';

/**
 * MenuAppBar component
 * @module
 * @param {func} onLogout - Logout handler
 * @param {func} [onGoHome] - Go home handler
 * @param {func} [onGoHomePeople] - Go home handler (with selected person)
 * @param {func} [onAddPerson] - Add person to account handler
 * @param {func} [onCancelPerson] - Remove person from account handler
 * @param {func} [onModifyAccount] - Modify account handler
 * @param {array} [listPeople] - List of represented people
 * @param {object} [person] - Person object
 * @param {object} [user] - User object
 */
class MenuAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorEl2: null,
      io: this.props.person?.I_O || this.props.user?.i_o,
      openDialog: false,
      openChangePerson: false,
      isLoad: false,
      openManagePeople: false,
      openAdminDialog: false,
      openLogoutAdminDialog: false,
    };
  }

  componentDidMount = () => {
    if (!(this.props.listPeople.length > 0)) {
      this.props.dispatch(accountActions.getListPeople());
    }
  };

  handleOpenAdminDialog = () => {
    this.setState({ openAdminDialog: true, anchorEl: null });
  };

  handleOpenLogoutAdminDialog = () => {
    this.setState({ openLogoutAdminDialog: true, anchorEl: null });
  };

  setAdminModeActive = (isActive) => {
    this.props.adminModeSetActiveState(isActive);
    storage.setItem("adminMode", isActive)
    this.handleCloseDialog();
    if (!isActive) {
      this.props.adminModeReset();
    }
    //this.props.history.push('/uvod');
  };

  handleOpenDialog = (iOsoby) => {
    this.setState({ openDialog: true, anchorEl: null });
    _iOsoby = iOsoby;
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false, openChangePerson: false, openManagePeople: false, openAdminDialog: false, openLogoutAdminDialog: false });
    _iOsoby = null;
    _pf = '';
  };

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu2 = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleClose2 = () => {
    this.setState({ anchorEl2: null });
  };

  handleCancelPerson = async () => {
    this.setState({ openDialog: false, openManagePeople: false, isLoad: true });
    await this.props.onCancelPerson(_iOsoby);
    _iOsoby = null;
    _pf = '';
    this.setState({ io: this.props.user.i_o, isLoad: false });
  };

  handleGoHome = (iOs, name) => {
    _iOsoby = iOs;
    _pf = name;
    if (this.props.listPeople.length > 1)
      this.setState({ openChangePerson: true });
    else
      this.goHome();

  };

  goHome = () => {
    this.handleClose();
    this.props.onGoHome(_iOsoby);
    this.setState({ io: _iOsoby, openChangePerson: false });

    _iOsoby = null;
    _pf = '';
  };

  handleModifyAccount = () => {
    this.handleClose2();
    this.handleClose();
    this.props.onModifyAccount();
  };

  handleAddPerson = () => {
    this.handleCloseDialog();
    this.handleClose();
    this.props.onAddPerson();
  };

  handleAddPersonEid = () => {
    this.handleCloseDialog();
    this.handleClose();
    this.props.onAddPersonEid();
  };

  handleManagePerson = () => {
    this.handleClose();
    this.setState({ openManagePeople: true });
  };

  showDeadPopUp = () => {
    this.props.dispatch(userActions.showErrorMsg("Nie je možné prihlásenie pre mŕtvu osobu"));
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { anchorEl2 } = this.state;
    const open2 = Boolean(anchorEl2);
    const { classes } = this.props;

    const fMelements =
      <div className={classNames(classes.mb2)}>
        <div className={classNames(classes.row, classes.w100, classes.mb2)}>
          <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Osoby k účtu</Typography>
          <button onClick={this.handleCloseDialog} className={classNames(classes.closeSidebarBtn)}>
            <ClearIcon className={classes.closeSidebarBtnIcon} />
          </button>
        </div>
        {this.props.listPeople
          .filter((x) => !x.L_ZASTUPUJUCI)
          .map((x) => (
            <MenuItem key={x.I_O_ZASTUP.toString()} onClick={() => this.handleOpenDialog(x.I_O_ZASTUP)}>
              <div className={classNames(classes.row, classes.w100, classes.mb2, classes.mr1)}>
                <div className={classNames(classes.row)}>
                  <PeopleIcon />
                  <Typography className={classNames(classes.ml2)} >{x.ZASTUPOVANY}</Typography>
                </div>
                <CancelRoundedIcon style={{ fill: "#ff0000" }} />
              </div>
            </MenuItem>
          ))}

        <div>
          <div className={classNames(classes.dFlex)}>
            <Button
              variant="contained"
              color="secondary"
              type="success"
              className={classNames(classes.w100, classes.mt4, classes.mb3, classes.ml3, classes.mr3, classes.textTransformNone)}
              onClick={this.handleAddPerson}
            >
              <AddBlackIcon className={classes.mr1} />
              Pridať zastupovanú osobu
            </Button>
          </div>
          {this.props.egovHandlerUrl?.HODN &&
            <div className={classNames(classes.dFlex)}>
              <Button
                variant="contained"
                color="secondary"
                type="success"
                className={classNames(classes.w100, classes.mt1, classes.mb2, classes.ml3, classes.mr3, classes.textTransformNone)}
                onClick={this.handleAddPersonEid}
              >
                <AddBlackIcon className={classes.mr1} />
                Pridať zastupovanú osobu
                <img src={'assets/SVK_flag.svg'} alt='Prihlásenie eID' className={classNames(classes.ml2, classes.mr2)} />
                eID
              </Button>
            </div>
          }
        </div>
      </div>;
    return (
      <div>
        <CircularProgress loading={this.state.isLoad} />

        {
          !this.props.adminMode.isActive
            ?
            <IconButton aria-owns={open ? "menu-appbar" : undefined} aria-haspopup="true" onClick={this.handleMenu} color="inherit" toolTip="Moje konto">
              <PersonIcon />
            </IconButton>
            :
            <Button
              variant="contained"
              color="secondary"
              type="danger"
              className={classNames(classes.w100, classes.textTransformNone)}
              onClick={this.handleOpenLogoutAdminDialog}
              style={{ width: "240px", position: "absolute", top: 0, right: 0 }}
              startIcon={<EditIcon />}
            >
              Ukončiť režim Správca
            </Button>
        }

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          classes={{ paper: classes.menuPaper }}
        >
          <Typography variant="body1" color='primary' style={{ fontStyle: 'italic' }} className={classNames(classes.mt1, classes.textCenter)}>
            Účet
          </Typography>

          <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml3, classes.mr3)}>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart)}>
              <EmailIcon />
              <Typography className={classNames(classes.ml2)} style={{ fontSize: 14 }}>{this.props.user.n_uz.trim()}</Typography>
            </div>
            <div >
              <IconButton
                aria-owns={open2 ? "menu-appbar2" : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu2}
                color="inherit"
                toolTip="Zobraziť viac"
                toolTipPlace="bottom"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar2"
                anchorEl={anchorEl2}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open2}
                onClose={this.handleClose2}
              >
                <MenuItem key={"2"} onClick={this.handleModifyAccount}>
                  <EditIcon style={{ marginRight: "7px" }} />
                  <Typography>Zmena údajov</Typography>
                </MenuItem>
                <MenuItem key={"3"} onClick={this.props.onLogout}>
                  <img src={"assets/login.svg"} alt="Odhlásiť sa" style={{ marginRight: "7px" }} />
                  <Typography>Odhlásiť sa</Typography>
                </MenuItem>
              </Menu>
            </div>
          </div>
          <hr className={classNames(classes.separator, classes.w100)} />

          {/* PR:REZIM SPRAVCA */}
          {(this.props.user.i_uro === 10 || this.props.user.i_uro === 20) &&
            <div>
              <Typography variant="body1" color='primary' style={{ fontStyle: 'italic' }} className={classNames(classes.mt1, classes.textCenter)}>
                Správca portálu
              </Typography>
              <MenuItem onClick={() => this.handleOpenAdminDialog()}>
                <div className={classNames(classes.row, classes.w100, classes.alignSelfCenter)}>
                  <div className={classNames(classes.row, classes.alignSelfCenter)}>
                    <EditIcon />
                    <Typography className={classNames(classes.ml2)} variant="body1">
                      Režim správca
                    </Typography>
                  </div>
                </div>
              </MenuItem>
              <hr className={classNames(classes.separator, classes.w100)} />
            </div>
          }

          <Typography variant="body1" color='primary' style={{ fontStyle: 'italic' }} className={classNames(classes.mt2, classes.textCenter)}>
            Osoba
          </Typography>
          {this.props.listPeople
            .filter((x) => x.L_ZASTUPUJUCI)
            .map((x) => {
              const isDead = x.D_UMR && x.I_O_RO_STAV === 5;
              const onClick = isDead ? this.showDeadPopUp : this.handleGoHome;

              return (
                <MenuItem key={x.I_O_ZASTUP.toString()} onClick={() => onClick(x.I_O_ZASTUP, x.ZASTUPOVANY)}>
                  <div className={classNames(classes.row, classes.w100, classes.alignSelfCenter)}>
                    <div className={classNames(classes.row, classes.alignSelfCenter)}>
                      <PersonIcon style={{ fill: this.state.io === x.I_O_ZASTUP ? "#66bb6a" : "" }} />
                      <Typography
                        className={classNames(classes.ml2)} variant="body1" style={{ color: this.state.io === x.I_O_ZASTUP ? "#66bb6a" : isDead ? "red" : "", fontWeight: this.state.io === x.I_O_ZASTUP || isDead ? "bold" : "" }}>
                        {x.ZASTUPOVANY}
                      </Typography>
                    </div>
                    <div>
                      {this.state.io === x.I_O_ZASTUP && <CheckCircleIcon className={classNames(classes.mr3, classes.mt1)} style={{ fill: "#66bb6a" }} />}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          {this.props.listPeople
            .filter((x) => !x.L_ZASTUPUJUCI)
            .map((x) => {
              const isDead = x.D_UMR && x.I_O_RO_STAV === 5;
              const onClick = isDead ? this.showDeadPopUp : this.handleGoHome;

              return (
                <MenuItem key={x.I_O_ZASTUP.toString()} onClick={() => onClick(x.I_O_ZASTUP, x.ZASTUPOVANY)}>
                  <div className={classNames(classes.row, classes.w100, classes.alignSelfCenter)}>
                    <div className={classNames(classes.row, classes.alignSelfCenter)}>
                      <PeopleIcon style={{ fill: this.state.io === x.I_O_ZASTUP ? "#66bb6a" : "" }} />
                      <Typography
                        className={classNames(classes.ml2)} variant="body1" style={{ color: this.state.io === x.I_O_ZASTUP ? "#66bb6a" : isDead ? "red" : "", fontWeight: this.state.io === x.I_O_ZASTUP || isDead ? "bold" : "" }}>
                        {x.ZASTUPOVANY}
                      </Typography>
                    </div>
                    <div>
                      {this.state.io === x.I_O_ZASTUP && <CheckCircleIcon className={classNames(classes.mr3, classes.mt1)} style={{ fill: "#66bb6a" }} />}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          {this.props.listPeople.length > 1 &&
            <div className={classNames(classes.dFlex)}>
              <Button
                color="secondary"
                className={classNames(classes.w100, classes.mt3, classes.mb1, classes.ml2, classes.mr3, classes.justifyContentStart)}
                onClick={this.handleManagePerson}
                style={{ backgroundColor: '#F5F5F5', textTransform: 'none', paddingLeft: '7px' }}
                variant="outlined"
              >
                <img src={"assets/manage_accounts_black_24dp.svg"} alt="Spravovať osoby" style={{ marginRight: "7px" }} />
                Spravovať osoby
              </Button>
            </div>}

          {this.props.listPeople.length === 1 && (
            <div>
              <div className={classNames(classes.dFlex)}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="success"
                  className={classNames(classes.w100, classes.mt3, classes.mb3, classes.ml3, classes.mr3, classes.textTransformNone)}
                  onClick={this.handleAddPerson}
                >
                  <AddBlackIcon className={classes.mr1} />
                  Pridať zastupovanú osobu
                </Button>
              </div>
              {this.props.egovHandlerUrl?.HODN &&
                <div className={classNames(classes.dFlex)}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="success"
                    className={classNames(classes.w100, classes.mt1, classes.mb2, classes.ml3, classes.mr3, classes.textTransformNone)}
                    onClick={this.handleAddPersonEid}
                  >
                    <AddBlackIcon className={classes.mr1} />
                    Pridať zastupovanú osobu
                    <img src={'assets/SVK_flag.svg'} alt='Prihlásenie eID' className={classNames(classes.ml2, classes.mr2)} />
                    eID
                  </Button>
                </div>
              }
            </div>
          )}
        </Menu>

        <Dialog
          modal={false}
          open={this.state.openAdminDialog}
          onClose={this.handleCloseDialog}
          contentText={'Informácia: Potvrdením budete prihlásený ako správca'}
          title="Administrácia portálu"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: () => this.setAdminModeActive(true),
              keyboardFocused: true,
            },
          ]}
        />

        <Dialog
          modal={false}
          open={this.state.openLogoutAdminDialog}
          onClose={this.handleCloseDialog}
          contentText={'Informácia: Potvrdením budete odhlásený ako správca a všetky zmeny budú zahodené.'}
          title="Administrácia portálu (ukončenie)"
          actions={[
            {
              label: "Zostať prihlásený",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: false,
            },
            {
              label: "Odhlásiť",
              color: "secondary",
              onClick: () => this.setAdminModeActive(false),
              keyboardFocused: true,
            },
          ]}
        />

        <Dialog
          modal={false}
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          contentText="Upozornenie: Na opätovné pridanie vybranej osoby bude potrebné vyplniť žiadosť o pridelenie osoby k Vášmu účtu a bude podliehať schvaľovaniu."
          title="Zrušiť zastupovanie osoby?"
          titleClasses={{ root: classes.dialogWarningText }}
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: true,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: this.handleCancelPerson,
              keyboardFocused: false,
            },
          ]}
        />

        <Dialog
          modal={false}
          open={this.state.openChangePerson}
          onClose={this.handleCloseDialog}
          contentText={`Informácia: Potvrdením budete zastupovať osobu:<b> ${_pf}</b>`}
          title="Zmeniť zastupovanú osobu?"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: this.goHome,
              keyboardFocused: true,
            },
          ]}
        />
        <FormModal
          open={this.state.openManagePeople}
          elements={fMelements}
        />
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onGoHome: PropTypes.func,
  onGoHomePeople: PropTypes.func,
  onAddPerson: PropTypes.func,
  onAddPersonEid: PropTypes.func,
  onCancelPerson: PropTypes.func,
  onModifyAccount: PropTypes.func,
  listPeople: PropTypes.array,
  person: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    adminMode: state.adminMode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  adminModeSetActiveState: (isActive) => dispatch(adminModeSetActiveState(isActive)),
  adminModeReset: () => dispatch(adminModeReset()),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuAppBar));
