import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0,
  //changedId: []
};

const listSubsidyRatingDetail = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_SUBSIDY_RATING_DETAIL_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_SUBSIDY_RATING_DETAIL_SUCCESS:
      return { ...state, isLoading: false, data: action.res.Data.Items, total: action.res.Data.TotalRecords };

    case actionTypes.GET_LIST_SUBSIDY_RATING_DETAIL_FAILURE:
      return _initState;

    case actionTypes.UPD_SUBSIDY_RATING_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.UPD_SUBSIDY_RATING_SUCCESS:
      return { ...state, isLoading: false };

    case actionTypes.UPD_SUBSIDY_RATING_FAILURE:
      return { ...state, isLoading: false };

    case actionTypes.CHANGE_SUBSIDY_RATING:
      return {
        ...state,
        data: state.data.map(d => {
          if (d.I_HOD === action.id) return { ...d, HOD: action.value };
          else return { ...d };
        }),
        //changedId: state.changedId.indexOf(action.id) === -1 ? [...state.changedId, action.id] : [...state.changedId]
      };

    default:
      return state;
  }
};

export default listSubsidyRatingDetail;