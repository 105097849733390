import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Typography from '../../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../../coraWebMComponents/sharedStyles';
import Button from '../../../coraWebMComponents/inputs/Button';
import ButtonFab from '../../../coraWebMComponents/inputs/ButtonFab';
import { withLocalizationConsumer } from '../../../coraWebMComponents/localization/withLocalization';
import storage from '../../../coraWebMComponents/utils/withStorage';
import Paper from '../../../coraWebMComponents/surfaces/Paper';
import withMeta from "../../../coraWebMComponents/utils/withMeta";
import withRouter from '../../../coraWebMComponents/utils/withRouter';
import { addOffset } from '../../../coraWebMComponents/utils/convert';
import EditIcon from "../../../coraWebMComponents/dataDisplay/icons/EditIcon";

import * as userActions from '../../../actions/userActions';
import * as publicUtils from '../../../utils/publicZone/publicUtils';
import * as parkingUtils from '../../../utils/parkingUtils';
import routes from '../../../routes';
import {
  getInfo1,
  getCurrentZoneTime,
  getInfo2,
  getNowParkingData,
  getMinutesFromTimeString,
  getTimeMin,
  getTotalMinutesOfDay,
  generPayName,
  getPlannedParkingData,
  getTimeString,
  ZoneTimeList, // eslint-disable-line no-unused-vars
  TicketTimeSums, // eslint-disable-line no-unused-vars
  TicketPriceSums, // eslint-disable-line no-unused-vars
} from '../../../utils/publicZone/parkingTicketUtils';

const styles = theme => ({
  ...getSharedStyles(theme),
  payBtn: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px'
  },
  setBtn: {
    borderRadius: '30px',
    height: '55px'
  },
  paper: {
    borderRadius: '30px'
  },
  timeControlHolder: {
    borderRadius: '30px',
    border: '1px solid #e1e1e1'
  }
});

/**
 * @param {Object} params - Parametre useNowParking
 * @param {function(Object): void} params.dispatch
 * @param {function(): void} params.getActiveParkCard - Funkcia, ktorá vráti aktívnu parkovaciu kartu
 * @param {TicketPriceSums} params.ticketPriceSums - Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {TicketTimeSums} params.ticketTimeSums - Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @param {function(string): string} params.translate
 * @param {ZoneTimeList} params.zoneTimeList - Zoznam údajov o parkovacej zóne
 */
const NowParking = ({
  dispatch,
  getActiveParkCard,
  ticketPriceSums,
  ticketTimeSums,
  translate,
  zoneTimeList,
  handleOpenPaymentMessagesDialog,
  ...props }) => {
  const [cMinJ, setCMinJ] = useState(0);
  const [time, setTime] = useState({ hours: 0, minutes: 0 });
  const [price, setPrice] = useState(0);
  const [dateOfParking, setDateOfParking] = useState(null);

  const zoneTime = useRef(null);

  useEffect(() => {
    if (!zoneTimeList) return;

    zoneTime.current = getCurrentZoneTime(zoneTimeList);
    const dateOfParking = new Date(zoneTime.current.DATUM);

    const { hours, minutes, price, currentCMinJ } = getNowParkingData(0, zoneTimeList, ticketTimeSums, ticketPriceSums);
    setCMinJ(currentCMinJ);
    setTime({ hours, minutes });
    setPrice(price);
    setDateOfParking(dateOfParking);
  }, [zoneTimeList, ticketPriceSums, ticketTimeSums]);

  const handleAddTime = (buttonId) => {
    let totalMinutes = time.minutes + (time.hours * 60);

    switch (buttonId) {
      case 1:
        totalMinutes = totalMinutes - cMinJ;
        break;
      case 2:
        totalMinutes = totalMinutes + cMinJ;
        break;
      case 3:
        totalMinutes = totalMinutes - 60;
        break;
      case 4:
        totalMinutes = totalMinutes + 60;
        break;
      case 5:
        totalMinutes = totalMinutes + 1440;
        break;
      default:
    }

    const maxMinutesOneTicket = props.maxHoursOneTicket * 60;

    if (maxMinutesOneTicket !== 0 && totalMinutes > maxMinutesOneTicket) {
      totalMinutes = maxMinutesOneTicket;
      maxHoursOneTicketWarning();
    }

    const { hours, minutes, price, currentCMinJ } = getNowParkingData(totalMinutes, zoneTimeList, ticketTimeSums, ticketPriceSums);

    setTime({ hours, minutes });
    setCMinJ(currentCMinJ);
    setPrice(price);
  };

  const addParking = async (isFree) => {
    const isValid = props.validateForm();

    if (isValid) {
      if (props.hasValidParkingCard()) return;

      const card = getActiveParkCard();
      if (!card && isFree) {
        dispatch(userActions.showErrorMsg("Pre zadané EČV nie je možné bezplatné parkovanie."));
        return;
      }
      props.setIsLoading(true);

      const totalMinutes = time.minutes + (time.hours * 60);
      const timeMin = getTimeMin(zoneTime.current);
      const timeFrom = new Date() < timeMin ? timeMin : new Date();
      const timeTo = getNowParkingData(totalMinutes, zoneTimeList, ticketTimeSums, ticketPriceSums).timeTo;

      const previousTicketDateTimeTo = await publicUtils.checkParkingTicket(
        props.iZone,
        props.ECV,
        getTotalMinutesOfDay(timeFrom),
        getTotalMinutesOfDay(timeTo),
        addOffset(dateOfParking).toJSON(),
        addOffset(dateOfParking).toJSON(),
      );

      if (previousTicketDateTimeTo) {
        if (timeTo <= previousTicketDateTimeTo) {
          props.handleOpenNotify(`${translate('parkingShort.dialogContextNotify1')} ${previousTicketDateTimeTo?.toLocaleString("sk-SK")} ${translate('parkingShort.dialogContextNotify2')}`);
        } else {
          if (isFree) {
            props.handleOpenNotify(`${translate('parkingShort.dialogContextNotify1')}  ${previousTicketDateTimeTo?.toLocaleString("sk-SK")}`);
          } else {
            props.handleOpenExtend(`${translate('parkingShort.dialogContextCheck1')} ${previousTicketDateTimeTo?.toLocaleString("sk-SK")} ${translate('parkingShort.dialogContextCheck2')}`, () => addParkingChecked(previousTicketDateTimeTo));
          }
        }
        props.setIsLoading(false);
      } else {
        addParkingChecked(previousTicketDateTimeTo, isFree);
      }
    }
  };

  const addParkingChecked = (previousTicketDateTimeTo, isFree) => {
    props.handleCloseExtend();

    props.storage.setItemLocal('DM_EMAIL', props.email);
    props.storage.setItemLocal('DM_ECV', props.ECV);

    const totalMinutes = time.minutes + (time.hours * 60);
    let { timeTo, price } = getNowParkingData(totalMinutes, zoneTimeList, ticketTimeSums, ticketPriceSums);

    const timeMin = getTimeMin(zoneTime.current);
    let dateTimeFrom = new Date() < timeMin ? timeMin : new Date();
    let dateTimeTo = timeTo;

    /* Ak existuje uz platny listok vo vybranom casovom intervale, tak nastavime 
    zaciatok na koniec predchadzajuceho listka a vypocitame novu cenu */
    if (previousTicketDateTimeTo) {
      dateTimeFrom = new Date(previousTicketDateTimeTo);

      const timeData = {
        minutesFrom: getTotalMinutesOfDay(dateTimeFrom),
        minutesTo: getTotalMinutesOfDay(dateTimeTo),
        dateFrom: new Date(dateOfParking),
        dateTo: new Date(dateOfParking)
      };
      /* Pouzijeme getPlannedParkingData, lebo zaciatok uz nie je aktualny cas */
      price = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums).totalPrice;
    }

    /* Uvedenie dat do formatu pre spracovanie paymentGateway */
    dateTimeFrom.setSeconds(0);
    dateTimeTo.setSeconds(0);

    const ticketData = {
      ECV: props.ECV,
      I_ZONA: props.iZone,
      EMAIL: props.email,
      LANGUAGE: props.language,
      D_OD: addOffset(dateTimeFrom),
      D_DO: addOffset(dateTimeTo),
      CENA: price
    };

    const minutesFrom = getTotalMinutesOfDay(dateTimeFrom);
    const minutesTo = getTotalMinutesOfDay(dateTimeTo);
    const name = generPayName(ticketData.ECV, dateTimeFrom, minutesFrom, minutesTo, translate);
    const payData = { items: [], totalAmount: 0, totalAmountDec: 0, currencyCode: 'EUR' };
    const amountDec = Number(ticketData.CENA);
    payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;
    const amount = Math.round(amountDec * 100);
    payData.totalAmount = Number(payData.totalAmount) + amount;
    payData.items.push({ name, amount, amountDec });

    if (isFree) {
      addFreeTicket(ticketData);
    } else {
      props.dispatchTicket(payData, ticketData);
    }
  };

  const addFreeTicket = async (ticketData) => {
    const card = getActiveParkCard();
    const data = {
      ...ticketData,
      CENA: 0,
    };

    await parkingUtils.addParkingShortFree(card.I_KARTA_TYP, data).then(response => {
      if (response.Code === "201.000") {
        handleFreeParkingResponse(response);
      } else {
        dispatch(userActions.showErrorMsg("Nastala neočakávaná chyba pri pridávaní bezplatného parkovania"));
      }
    });

    props.setIsLoading(false);
  };

  const handleFreeParkingResponse = (response) => {
    const { ResponseCode, Data } = response.Data;

    switch (ResponseCode) {
      case 0:
        const difference = getMinutesFromTimeString(Data?.D_DO) - getMinutesFromTimeString(Data?.D_OD);
        const timeString = getTimeString(difference);
        dispatch(userActions.showMsg(`Bezplatné parkovanie bolo úspešne pridané na dobu` + timeString));
        props.navigate({ pathname: routes.PARKING_TICKET });
        break;
      case 1:
        dispatch(userActions.showErrorMsg("Prekročený povolený čas bezplatného parkovania."));
        break;
      case 2:
        dispatch(userActions.showErrorMsg("Prekročený počet bezplatných parkovaní."));
        break;
      case 3:
        dispatch(userActions.showErrorMsg("Nemáte oprávnenie na bezplatné parkovanie"));
        break;
      default:
        dispatch(userActions.showErrorMsg("Nastala neočakávaná chyba pri pridávaní bezplatného parkovania"));
    }
  };

  const getInfoTexts = () => {
    let info1 = '', info2 = '';

    if (zoneTime.current !== null) {
      info1 = getInfo1(zoneTime.current, translate);
    }

    if (zoneTimeList !== null && dateOfParking !== null) {
      const timeTo = getNowParkingData(time.minutes + (time.hours * 60), zoneTimeList, ticketTimeSums, ticketPriceSums).timeTo;
      info2 = getInfo2(zoneTimeList, ticketPriceSums, dateOfParking, timeTo, translate);
    }

    return [info1, info2];
  };

  const getFormattedMinutes = () => `${time.hours.toString().padStart(2, 0)}:${time.minutes.toString().padStart(2, 0)}`;

  const getFormattedPrice = () => `${price.toFixed(2)}`.replace('.', ',');

  const maxHoursOneTicketWarning = () => dispatch(userActions.showErrorMsg("Dosiahnutý maximálny čas pre jedno parkovanie."));

  const { classes } = props;

  return (
    <div>
      {/*INFO TEXT 1*/}
      <div className={classes.textCenter}>
        <Typography variant="caption" className={classNames(classes.textCenter)}  >{getInfoTexts()[0]}</Typography>
      </div>
      {/*DOBA PARKOVANIA*/}
      <Typography variant="subtitle1" className={classNames(classes.mb2, classes.mt3, classes.textCenter)} >{translate('parkingShort.parkingTime')}</Typography>
      {/*VYKLIKAVATKO MINUTY*/}
      <div className={classNames(classes.timeControlHolder, classes.mb3)}>
        <div className={classNames(classes.row, classes.alignItemsCenter)} >
          <ButtonFab
            disabled={cMinJ === 0}
            style={{ backgroundColor: '#ffffff' }}
            onClick={_event => handleAddTime(1)}
            size='small'
          >
            <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
          </ButtonFab>
          <Typography variant="body1" style={{ textAlign: 'center' }} >{cMinJ} min.</Typography>
          <ButtonFab
            disabled={cMinJ === 0}
            style={{ backgroundColor: '#ffffff' }}
            onClick={_event => handleAddTime(2)}
            size='small'
          >
            <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
          </ButtonFab>
        </div>
      </div>
      {/*VYKLIKAVATKO HODINY*/}
      {cMinJ !== 60 &&
        <div className={classNames(classes.timeControlHolder, classes.mb3)}>
          <div className={classNames(classes.row, classes.alignItemsCenter)} >
            <ButtonFab
              disabled={cMinJ === 0}
              style={{ backgroundColor: '#ffffff' }}
              onClick={_event => handleAddTime(3)}
              size='small'
            >
              <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
            </ButtonFab>
            <Typography variant="body1" style={{ textAlign: 'center' }} >1 {translate('hour')}</Typography>
            <ButtonFab
              disabled={cMinJ === 0}
              style={{ backgroundColor: '#ffffff' }}
              onClick={_event => handleAddTime(4)}
              size="small"
            >
              <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
            </ButtonFab>
          </div>
        </div>}
      {/*BUTTON CELY DEN*/}
      <Paper elevation={3} className={classNames(classes.paper, classes.mb3)}>
        <div className={classNames(classes.row, classes.alignItemsCenter)} >
          <Button
            disabled={cMinJ === 0}
            variant="contained"
            style={{ backgroundColor: '#ffffff' }}
            className={classNames(classes.w100, classes.setBtn)}
            onClick={_event => handleAddTime(5)}
            size='large'
          >
            {translate('wholeDay')}
          </Button>
        </div>
      </Paper>
      {/*CELKOVY CAS HH:MM*/}
      <Typography variant="h4" style={{ textAlign: 'center', fontWeight: 'bold' }}>{getFormattedMinutes()}</Typography>
      {/*INFO TEXT 2*/}
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <Typography variant="caption" className={classNames(classes.textCenter, classes.mb4,)}>{getInfoTexts()[1]}</Typography>
      </div>
      {/*BUTTON PLATBA */}
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}  >
        <Button
          disabled={time.hours === 0 && time.minutes === 0}
          variant="contained"
          color="secondary"
          size="large"
          className={classNames(classes.payBtn, classes.w100)}
          onClick={() => addParking(false)}
        >
          {translate('pay')}   {getFormattedPrice()} €
        </Button>

        {props.adminMode?.isActive &&
          <ButtonFab
            toolTip="Nastavenie oznamov o výsledku platby"
            toolTipPlace="right-start"
            style={{ backgroundColor: '#ffffff', flexShrink: 0, margin: '0 10px 0 10px' }}
            className={classNames(classes.adminButton)}
            size="small"
            onClick={handleOpenPaymentMessagesDialog}
          >
            <EditIcon />
          </ButtonFab>

          // </ToolTip>
        }

      </div>
      {/*BUTTON BEZPLATNE PARKOVANIE */}
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}  >
        {props.isAuth &&
          props.iZone !== 0 &&
          props.activeParkingCards?.length > 0 &&
          !props.noFreeParkingZoneList?.includes(props.iZone) &&
          <Button
            disabled={props.ECV === ''}
            variant="contained"
            color="secondary"
            size="large"
            type="blue"
            className={classNames(classes.setBtn, classes.w100)}
            onClick={() => addParking(true)}
          >
            {translate('parkingMap.free')}
          </Button>}
      </div>
    </div>);
};

const mapStateToProps = (state) => {
  return {
    zoneTimeList: state.zoneTimeList.data.Data?.Items,
    ticketPriceSums: state.ticketPriceSums.data,
    ticketTimeSums: state.ticketTimeSums.data,
    isAuth: state.user.data !== null,
    maxHoursOneTicket: state.maxHoursOneTicket.data,
    activeParkingCards: state.listActiveParkingCard.data,
    noFreeParkingZoneList: state.notAvailableFreeParking.data,
    adminMode: state.adminMode,
  };
};

export default withRouter(withMeta(storage(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(NowParking)))))));