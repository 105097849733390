import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
}

const listCountries = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_COUNTRIES_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_COUNTRIES_SUCCESS:
      return { isLoading: false, data: action.res.Data.Items, total: action.res.Data.TotalRecords }

    case actionTypes.GET_LIST_COUNTRIES_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default listCountries;