import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ParkingCardIcon from '../../coraWebMComponents/dataDisplay/icons/ParkingCard';
import ScheludeIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeIcon';
import ScheludeTwoToneIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeTwoToneIcon';
import TimerIcon from '../../coraWebMComponents/dataDisplay/icons/TimerIcon';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import Button from '../../coraWebMComponents/inputs/Button';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import DateRangeIcon from '../../coraWebMComponents/dataDisplay/icons/DateRangeIcon';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import { isValidEcv } from '../../coraWebMComponents/utils/validator';
import { addOffset } from '../../coraWebMComponents/utils/convert';
import TextField from '../../coraWebMComponents/inputs/TextField';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import * as parkingActions from '../../actions/parkingActions';
import * as userActions from '../../actions/userActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import TimeDialog from '../parking/TimeDialog';
import { DateWithoutSeconds } from '../parking/CreditDrawList';

const styles = theme => ({
  ...getSharedStyles(theme),
  addButton: {
    borderRadius: '7px'
  },
  formButton: {
    margin: 0,
    borderRadius: '0px',
  },
  dialogZIndex: {
    zIndex: "1301 !important",
  },
});
let _zoz_i_zona = [], _zonyLength = 0, _kvota;

const initialState = {
  isLoad: false,
  ECV: "",
  I_ZONA: 0,
  I_KARTA: null,
  I_KARTA_AKTUAL: null,
  I_KREDIT: null,
  I_KARTA_SELECTED: 0,
  I_KREDIT_SELECTED: 0,
  DATE: new DateWithoutSeconds(),
  TIME_FROM: new DateWithoutSeconds(),
  TIME_TO: null,
  D_OD: null,
  D_DO: null,
  C_MIN_J: 0.25,
  showInfo: false,
  infoText1: "Pre vybratý deň a zónu je parkovanie",
  infoText2: "bezplatné.",
  infoText3: "",
  errorTextZona: "",
  errorTextEcv: "",
  errorTextKvota: "",
  errorTextCerpanie: "",
  errorTextMinInterval: "",
  errorTextDuplicita: "",
  maxDate: new DateWithoutSeconds("2050-01-01"),
  timeMin: null,
  timeMax: null,
  listZone: [],
  noDataZone: false,
  kredit: null,
  listValidParkingCard: [],
  listParkingCredit: [],
  karta: null,
  isOpen: false,
};

class QuickCreditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  init = async () => {
    this.setState({ isLoad: true });
    await this.props.dispatch(parkingActions.getListValidParkingCard(this.props.person.I_O));

    if (this.props.listValidParkingCardCount === 0) {
      this.props.dispatch(userActions.showErrorMsg("Zastupovaná osoba nemá žiadnu parkovaciu kartu s priradeným kreditom"));
      this.props.handleClose();
      return;
    }

    const listValidParkingCard = this.props.listValidParkingCard.map(x => {
      return { ...x, LABEL: (x.ECV ? x.ECV + " - " : "") + (x.C_KARTA ?? "") };
    });
    await this.props.dispatch(ciselnikyActions.getListZone());
    await this.props.dispatch(ciselnikyActions.getZoneTimeMaxDate(1));

    if (this.props.listValidParkingCard.length === 1) {
      await this.handleChangeField("I_KARTA_SELECTED", this.props.listValidParkingCard[0].I_KARTA);
    }

    this.setState({ isLoad: false, listValidParkingCard, isOpen: true });
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen === true) {
        await this.init();
      } else {
        this.setState(initialState);
      }
    }
  };

  handleChange = async (name, value) => {
    if (name === "I_KARTA_SELECTED" || name === "I_KREDIT_SELECTED" || name === "I_ZONA" || name === "DATE") {
      this.setState({ isLoad: true });
    }
    await this.handleChangeField(name, value);
    this.setState({ isLoad: false });
  };

  handleChangeField = async (name, value) => {
    switch (name) {
      case "ECV":
        value = value.toUpperCase();
        value = value.trim();
        this.setState({
          errorTextEcv: value
            ? isValidEcv(value)
            : "Evidenčné číslo vozidla je povinný údaj!",
        });
        break;
      case "I_ZONA":
        const kredit = this.props.listParkingCredit.find(x => x.I_KREDIT === this.state.I_KREDIT_SELECTED);
        const ecv = kredit.I_KREDIT_TYP === 1 ? this.state.karta.ECV : this.state.ECV;
        const datum = await this.setTimeInterval(this.state.DATE, value);

        this.setState({
          ECV: ecv,
          DATE: datum,
        });
        break;
      case "DATE":
        value = this.validateDate(value);
        value = await this.setTimeInterval(value, this.state.I_ZONA);
        break;
      case "TIME_FROM":
        let newTimeTo = new DateWithoutSeconds(value);
        if (newTimeTo.getHours() === 23 && newTimeTo.getMinutes() > 59 - this.state.C_MIN_J * 60) {
          newTimeTo.setMinutes(59);
          newTimeTo.setHours(23);
        }
        else {
          newTimeTo.setMinutes(newTimeTo.getMinutes() + this.state.C_MIN_J * 60);
        }
        newTimeTo = this.validateTimeMinMax(newTimeTo);
        this.setState({ TIME_TO: newTimeTo });
        break;
      case "TIME_TO":
        if (value <= this.state.TIME_FROM) {
          value = new DateWithoutSeconds(this.state.TIME_FROM);
        }
        break;
      case "I_KARTA_SELECTED":
        const karta = this.props.listValidParkingCard.find(x => x.I_KARTA === value);
        await this.props.dispatch(parkingActions.getListParkingCredit(karta.I_KARTA_TYP, karta.I_KARTA_AKTUAL));
        const listParkingCredit = this.props.listParkingCredit.map(x => {
          return { ...x, LABEL: x.N_KREDIT_TYP + " " + (x.C_KREDIT - x.C_CERP_SPOLU) + "/" + x.C_KREDIT + " hod." };
        });

        await new Promise((resolve) => {
          this.setState({
            I_KARTA_SELECTED: value,
            listParkingCredit,
            karta
          }, async () => {
            if (this.props.listParkingCredit.length === 1) {
              await this.handleChangeField("I_KREDIT_SELECTED", this.props.listParkingCredit[0].I_KREDIT);
            }
            resolve();
          });
        });
        return;
      case "I_KREDIT_SELECTED":
        if (this.props.zoneTimeMaxDate) {
          var zony;
          const karta = this.state.karta;
          const kredit = this.props.listParkingCredit.find(x => x.I_KREDIT === value);
          await this.props.dispatch(parkingActions.getListCreditDraw(karta.I_KARTA_AKTUAL, value));

          if (karta.ZOZ_I_ZONA === null)
            zony = this.props.listZone;
          else {
            _zoz_i_zona = karta
              ? karta.ZOZ_I_ZONA.split(",")
              : [];
            zony = this.props.listZone.filter((x) => this.zoneFilter(x.I_ZONA, kredit));
          }
          _zonyLength = zony.length;

          _kvota = kredit.C_KREDIT;

          if (this.props.listCreditDraw.length > 0)
            _kvota = _kvota - this.props.listCreditDraw[0].C_CERP_SPOLU;

          await new Promise((resolve) => {
            this.setState({
              maxDate: new DateWithoutSeconds(this.props.zoneTimeMaxDate.DATUM),
              C_MIN_J: kredit ? kredit.C_MIN_J : 0.25,
              I_KARTA: karta.I_KARTA,
              ECV: kredit.I_KREDIT_TYP === 1 ? karta.ECV : "",
              I_KREDIT: kredit.I_KREDIT,
              listZone: zony,
              kredit: kredit,
              I_KREDIT_SELECTED: value,
            },
              async () => {
                if (_zonyLength === 1) {
                  await this.handleChangeField("I_ZONA", zony[0].I_ZONA);
                }
                resolve();
              }
            );
          });
          return;
        } else this.setState({ noDataZone: true });
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  zoneFilter(value, kredit) {
    const iKreditTyp = kredit ? kredit.I_KREDIT_TYP : 1;
    if (iKreditTyp === 1) return _zoz_i_zona.indexOf(value.toString()) < 0;

    if (iKreditTyp === 2) return _zoz_i_zona.indexOf(value.toString()) >= 0;

    return true;
  }

  validateDate = (date) => {
    const today = new DateWithoutSeconds();

    if (!date) date = today;
    else {
      if (date < today) date = today;

      if (date > this.state.maxDate) date = this.state.maxDate;
    }
    return date;
  };

  validateTimeMinMax = (time) => {
    const hodinyMin = this.getHodiny(this.state.timeMin);
    const minutyMin = this.getMinuty(this.state.timeMin);
    const hodinyMax = this.getHodiny(this.state.timeMax);
    const minutyMax = this.getMinuty(this.state.timeMax);
    const rok = time.getFullYear();
    const mesiac = time.getMonth();
    const den = time.getDate();
    const hodiny = time.getHours();
    const minuty = time.getMinutes();

    time = new DateWithoutSeconds(rok, mesiac, den, hodiny, minuty);
    const timeMin = new DateWithoutSeconds(rok, mesiac, den, hodinyMin, minutyMin);
    const timeMax = new DateWithoutSeconds(rok, mesiac, den, hodinyMax, minutyMax);

    if (time < timeMin) time = timeMin;

    if (time > timeMax) time = timeMax;

    return time;
  };

  getHodiny = (time) => {
    const pos = time.indexOf(":");
    return Number(time.substring(0, pos));
  };

  getMinuty = (time) => {
    const pos = time.indexOf(":");
    return Number(time.substring(pos + 1));
  };

  setTimeInterval = async (date, iZona) => {
    if (iZona) {
      await this.props.dispatch(ciselnikyActions.getZoneTime(date, iZona, 1));
      var haveDate = false;

      if (
        this.props.zoneTime !== null &&
        this.props.zoneTime.D_OD &&
        this.props.zoneTime.D_DO
      )
        haveDate = true;

      if (haveDate) {
        const rok = date.getFullYear();
        const mesiac = date.getMonth();
        const den = date.getDate();
        const hodiny = this.getHodiny(this.props.zoneTime.D_OD);
        const minuty = this.getMinuty(this.props.zoneTime.D_OD);
        date = new DateWithoutSeconds(rok, mesiac, den, hodiny, minuty);

        if (date < new DateWithoutSeconds()) date = new DateWithoutSeconds();
      }

      this.setState({
        showInfo: true,
        timeMin: haveDate ? this.props.zoneTime.D_OD : null,
        timeMax: haveDate ? this.props.zoneTime.D_DO : null,
        infoText2: haveDate
          ? `spoplatnené od ${this.props.zoneTime.D_OD} do ${this.props.zoneTime.D_DO} hod.`
          : "bezplatné.",
        infoText3: haveDate
          ? `Minimálny čas čerpania je ${this.state.C_MIN_J * 60} min.`
          : "",
        TIME_FROM: date,
        TIME_TO: date,
        errorTextZona: "",
        errorTextEcv: "",
        errorTextKvota: "",
        errorTextCerpanie: "",
        errorTextMinInterval: "",
        errorTextDuplicita: "",
      });
    } else
      this.setState({
        showInfo: false,
        timeMin: null,
        timeMax: null,
        TIME_FROM: null,
        TIME_TO: null,
        errorTextZona: "",
        errorTextEcv: "",
        errorTextKvota: "",
        errorTextCerpanie: "",
        errorTextMinInterval: "",
        errorTextDuplicita: "",
      });

    return date;
  };

  validateDuplicitous = (ecv, time_od, time_do) => {
    let ret = false;
    this.props.listCreditDraw.forEach(getDuplicitous);

    function getDuplicitous(value) {
      if (ecv === value.ECV) {
        if (new DateWithoutSeconds(value.D_OD) <= time_od && new DateWithoutSeconds(value.D_DO) > time_od)
          ret = true;

        if (new DateWithoutSeconds(value.D_OD) < time_do && new DateWithoutSeconds(value.D_DO) >= time_do)
          ret = true;

        if (new DateWithoutSeconds(value.D_OD) >= time_od && new DateWithoutSeconds(value.D_DO) <= time_do)
          ret = true;
      }
    }
    return ret;
  };

  AddDraw = async () => {
    let TIME_FROM = new DateWithoutSeconds(this.state.TIME_FROM);
    let TIME_TO = new DateWithoutSeconds(this.state.TIME_TO);
    const Ecv = this.state.ECV;
    const Ikredit = this.state.I_KREDIT;
    const Izona = this.state.I_ZONA;
    const cerpanie = (TIME_TO - TIME_FROM) / 3600000;

    if (TIME_FROM < new DateWithoutSeconds()) {
      TIME_FROM = new DateWithoutSeconds();
      TIME_TO = new DateWithoutSeconds();
      TIME_TO.setMinutes(TIME_TO.getMinutes() + cerpanie * 60);
      this.setState({ TIME_FROM: TIME_FROM, TIME_TO: TIME_TO });
    }

    const errorTextZona = Izona ? "" : "Zóna je povinný údaj!";
    const errorTextEcv = Ecv ? "" : "Evidené číslo vozidla je povinný údaj!";
    const errorTextKvota =
      _kvota < cerpanie ? "Prekročenie zostatku kreditu!" : "";
    const errorTextCerpanie = cerpanie <= 0 ? "Čas od a čas do sú zhodné!" : "";
    const errorTextMinInterval =
      cerpanie < this.state.C_MIN_J && cerpanie > 0
        ? `Minimálne povolené čerpanie je ${this.state.C_MIN_J} hod.`
        : "";
    const errorTextDuplicita = this.validateDuplicitous(
      Ecv,
      TIME_FROM,
      TIME_TO
    )
      ? "Pre zadané EČV a časový interval už existuje parkovanie!"
      : "";
    this.setState({
      errorTextZona,
      errorTextEcv,
      errorTextKvota,
      errorTextCerpanie,
      errorTextMinInterval,
      errorTextDuplicita,
    });

    if (
      !errorTextZona &&
      !errorTextEcv &&
      !errorTextKvota &&
      !errorTextCerpanie &&
      !errorTextMinInterval &&
      !errorTextDuplicita
    ) {
      this.setState({ isLoad: true });
      const karta = this.state.karta;
      const data = {
        ...this.state,
        D_OD: addOffset(TIME_FROM),
        D_DO: addOffset(TIME_TO),
        I_KARTA_AKTUAL: karta.I_KARTA_AKTUAL
      };
      const res = await this.props.dispatch(parkingActions.addDraw(data));

      if (res) {
        _kvota = _kvota - cerpanie;
        await this.props.dispatch(
          parkingActions.getListCreditDraw(karta.I_KARTA_AKTUAL, Ikredit)
        );
        await this.props.dispatch(
          parkingActions.getListParkingCredit(
            karta.I_KARTA_TYP,
            karta.I_KARTA_AKTUAL
          )
        );
      }

      this.setState({ isLoad: false });
      this.props.handleClose();

      if (res === -651924) {
        this.props.dispatch(
          userActions.showErrorMsg("Čerpanie kreditu pre EČV v zóne a danom čase už existuje.")
        );
      } else if (res) {
        this.props.dispatch(
          userActions.showMsg("Čerpanie kreditu bolo zaevidované")
        );
        this.setState({ ECV: "", I_ZONA: 0, TIME_TO: null, TIME_FROM: new DateWithoutSeconds(), DATE: new DateWithoutSeconds() });
      } else
        this.props.dispatch(
          userActions.showErrorMsg("Čerpanie kreditu sa nepodarilo zaevidovať.")
        );
    }
  };

  getTimeDialogContents = () => {
    const { showTimeDialog } = this.state;
    const result = { showTimeDialog, dateTitle: null, dateValue: null, timeTitle: null, timeValue: "-", captionOdDo: null, captionCena: null, btnMinutes: null, btnHours: null };

    switch (showTimeDialog) {
      case 1:
        result.dateTitle = "Dátum od";
        result.timeTitle = "Čas od";
        result.timeValue = this.state.TIME_FROM.getHours().toString().padStart(2, "0") + ":" + this.state.TIME_FROM.getMinutes().toString().padStart(2, "0");
        break;
      case 2:
        result.dateTitle = "Dátum do";
        result.timeTitle = "Čas do";
        result.timeValue = this.state.TIME_TO ? this.state.TIME_TO.getHours().toString().padStart(2, "0") + ":" + this.state.TIME_TO.getMinutes().toString().padStart(2, "0") : "-";
        break;
      default:
        return result;
    }

    result.dateValue = this.state.DATE.toLocaleDateString('en-GB').replace(/\//g, '.');
    result.btnMinutes = `${this.state.C_MIN_J * 60} min.`;
    result.btnHours = `1 ${this.props.translate("hour")}.`;
    return result;
  };

  handleShowTimeDialog = (value) => {
    this.setState({ showTimeDialog: value });
  };

  handleModifyTime = async (isToTime, addHour) => {
    let time = new DateWithoutSeconds(isToTime ? this.state.TIME_TO : this.state.TIME_FROM);
    switch (addHour) {
      case 1:
        if (time.getHours() === 0 && time.getMinutes() < this.state.C_MIN_J * 60) {
          time.setMinutes(0);
          time.setHours(0);
        } else {
          time.setMinutes(time.getMinutes() - this.state.C_MIN_J * 60);
        }
        break;
      case 2:
        if (time.getHours() === 23 && time.getMinutes() > 59 - this.state.C_MIN_J * 60) {
          time.setMinutes(59);
          time.setHours(23);
        } else {
          time.setMinutes(time.getMinutes() + this.state.C_MIN_J * 60);
        }
        break;
      case 3:
        if (time.getHours() === 0) {
          time.setMinutes(0);
          time.setHours(0);
        } else {
          time.setMinutes(time.getMinutes() - 60);
        }
        break;
      case 4:
        if (time.getHours() === 23) {
          time.setMinutes(59);
          time.setHours(23);
        } else {
          time.setMinutes(time.getMinutes() + 60);
        }
        break;
      default:
    }
    if (time < new DateWithoutSeconds()) {
      time = new DateWithoutSeconds();
      if (isToTime && time > this.state.TIME_FROM) {
        this.setState({ TIME_FROM: time });
      }
    }
    time = this.validateTimeMinMax(time);
    this.handleChange(isToTime ? "TIME_TO" : "TIME_FROM", time);
    this.setState({ errorTextCerpanie: "", errorTextMinInterval: "", errorTextDuplicita: "" });
  };

  handleWholeDay = () => {
    const today = new DateWithoutSeconds();

    let timeFrom = new DateWithoutSeconds();
    let timeTo = new DateWithoutSeconds();

    timeFrom.setFullYear(this.state.DATE.getFullYear());
    timeFrom.setDate(this.state.DATE.getDate());
    timeFrom.setMonth(this.state.DATE.getMonth());
    timeTo.setFullYear(this.state.DATE.getFullYear());
    timeTo.setDate(this.state.DATE.getDate());
    timeTo.setMonth(this.state.DATE.getMonth());

    if (today.getDate() !== this.state.DATE.getDate()) {
      timeFrom.setHours(0);
      timeFrom.setMinutes(0);
    }

    timeTo.setHours(23);
    timeTo.setMinutes(59);
    timeFrom = this.validateTimeMinMax(timeFrom);
    timeTo = this.validateTimeMinMax(timeTo);
    this.setState({ TIME_FROM: timeFrom, TIME_TO: timeTo });
  };

  shouldBeDisabled = () => {
    const isToday = (this.state.DATE.getMonth() === new DateWithoutSeconds().getMonth()) && (this.state.DATE.getDate() === new DateWithoutSeconds().getDate());
    const maxTime = new DateWithoutSeconds();
    if (this.state.timeMax) {
      maxTime.setHours(this.getHodiny(this.state.timeMax));
      maxTime.setMinutes(this.getMinuty(this.state.timeMax));
      maxTime.setSeconds(0);
    }

    if (this.state.timeMin === null || this.state.timeMax === null) {
      return true;
    }
    if (new DateWithoutSeconds() > maxTime && isToday) {
      return true;
    }
    if (this.state.TIME_FROM == null) {
      return true;
    }
    if (this.state.TIME_TO == null) {
      return true;
    }
    return false;
  };

  render() {
    const { classes } = this.props;
    const fMelements = (
      <div>
        <Typography
          variant="h6"
          className={classNames(
            classes.ml4,
            classes.mr4,
            classes.mt4,
            classes.mb1
          )}
        >
          Parkovanie
        </Typography>
        <div
          className={classNames(
            classes.row,
            classes.alignItemsCenter,
            classes.mb4
          )}
        >
          <ParkingCardIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <SelectField
            disabled={this.state.isLoad || this.props.listValidParkingCard?.length === 1}
            floatingLabelText="Karta"
            value={this.state.I_KARTA_SELECTED}
            onChange={this.handleChange}
            fullwidth={true}
            schema={{ label: "LABEL", value: "I_KARTA" }}
            data={this.state.listValidParkingCard}
            name="I_KARTA_SELECTED"
            className={classNames(classes.mr4)}
            required
          />
        </div>
        <div
          className={classNames(
            classes.row,
            classes.alignItemsCenter,
            classes.mb4
          )}
        >
          <TimerIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <SelectField
            disabled={this.state.I_KARTA_SELECTED === 0 || this.props.listParkingCredit.length === 1}
            floatingLabelText="Kredit"
            value={this.state.I_KREDIT_SELECTED}
            onChange={this.handleChange}
            fullwidth={true}
            schema={{ label: "LABEL", value: "I_KREDIT" }}
            data={this.state.listParkingCredit}
            name="I_KREDIT_SELECTED"
            className={classNames(classes.mr4)}
            required
          />
        </div>
        <div
          className={classNames(
            classes.row,
            classes.alignItemsCenter,
            classes.mb4
          )}
        >
          <PlaceIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <SelectField
            disabled={_zonyLength === 1 || this.state.I_KARTA_SELECTED === 0 || this.state.I_KREDIT_SELECTED === 0}
            errorText={this.state.errorTextZona}
            error={this.state.errorTextZona ? true : false}
            floatingLabelText="Zóna"
            value={this.state.I_ZONA}
            onChange={this.handleChange}
            fullwidth={true}
            schema={{ label: "N_ZONA", value: "I_ZONA" }}
            data={this.state.listZone}
            name="I_ZONA"
            className={classNames(classes.mr4)}
            required
          />
        </div>
        <div
          className={classNames(
            classes.row,
            classes.alignItemsCenter,
            classes.mb4
          )}
        >
          <CarIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <TextField
            disabled={
              (this.state.kredit
                ? this.state.kredit.I_KREDIT_TYP === 1
                : false)
              || this.state.I_KARTA_SELECTED === 0
              || this.state.I_KREDIT_SELECTED === 0
            }
            label="EČV"
            fullwidth={true}
            value={this.state.ECV}
            errorText={this.state.errorTextEcv}
            error={this.state.errorTextEcv ? true : false}
            onChange={this.handleChange}
            name="ECV"
            className={classNames(classes.mr4)}
            variant="standard"
          />
        </div>
        <div
          className={classNames(
            classes.w100,
            classes.row,
            classes.alignItemsCenter,
            classes.mb4
          )}
        >
          <DateRangeIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <DatePicker
            disabled={this.state.I_KARTA_SELECTED === 0 || this.state.I_KREDIT_SELECTED === 0}
            label="Dátum parkovania"
            fullWidth={true}
            value={this.state.DATE.toISOString()}
            onChange={this.handleChange}
            clearable={true}
            name="DATE"
            keyboards={true}
            disableFuture={false}
            disablePast={true}
            className={classNames(classes.mr4)}
            maxDate={this.state.maxDate.toDateString()}
          />
        </div>
        <div className={classNames(
          classes.row,
          classes.justifyContentStart,
          classes.alignItemsCenter,
          classes.mb4,
          classes.mr4
        )}>
          <ScheludeIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <TextField
            disabled={this.state.I_KARTA_SELECTED === 0 || this.state.I_KREDIT_SELECTED === 0}
            name='TIME_FROM'
            type="text"
            label="Čas od"
            onChange={() => { }}
            value={this.state.TIME_FROM ? this.state.TIME_FROM.getHours().toString().padStart(2, "0") + ":" + this.state.TIME_FROM.getMinutes().toString().padStart(2, "0") : ""}
            fullwidth={false}
            inputLabelProps={{
              shrink: true,
            }}
            imputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    disabled={this.shouldBeDisabled()}
                    disableTooltip={true}
                    size="small"
                    onClick={() => this.handleShowTimeDialog(1)}
                  >
                    <ScheludeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </div>
        <div className={classNames(
          classes.row,
          classes.justifyContentStart,
          classes.alignItemsCenter,
          classes.mb4,
          classes.mr4
        )}>
          <ScheludeTwoToneIcon
            style={{ fontSize: 40, marginRight: "7px", marginLeft: "7px" }}
          />
          <TextField
            disabled={this.state.I_KARTA_SELECTED === 0 || this.state.I_KREDIT_SELECTED === 0}
            name='TIME_TO'
            type="text"
            label="Čas do"
            onChange={() => { }}
            value={this.state.TIME_TO ? this.state.TIME_TO.getHours().toString().padStart(2, "0") + ":" + this.state.TIME_TO.getMinutes().toString().padStart(2, "0") : ""}
            fullwidth={false}
            inputLabelProps={{
              shrink: true,
            }}
            imputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    disabled={this.shouldBeDisabled()}
                    disableTooltip={true}
                    size="small"
                    onClick={() => this.handleShowTimeDialog(2)}
                  >
                    <ScheludeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter)} >
          <Paper elevation={3} className={classNames(classes.paper, classes.mb3, classes.w100)}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f5f5f5' }}
              className={classNames(classes.w100, classes.setBtn)}
              onClick={() => this.handleWholeDay()}
              size='large'
              disabled={this.state.I_KARTA_SELECTED === 0 || this.state.I_KREDIT_SELECTED === 0 || this.state.I_ZONA === 0 || this.props.zoneTime?.D_DO == null || this.props.zoneTime?.D_OD == null || this.shouldBeDisabled()}
            >
              {this.props.translate('wholeDay')}
            </Button>
          </Paper>
        </div>
        {
          this.state.showInfo && (
            <div>
              <Typography
                variant="body1"
                style={{ textAlign: "center", color: "#4caf50" }}
              >
                {this.state.infoText1}
              </Typography>
              <Typography
                variant="body1"
                style={{ textAlign: "center", color: "#4caf50" }}
              >
                {this.state.infoText2}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#4caf50",
                  marginBottom: "10px",
                }}
              >
                {this.state.infoText3}
              </Typography>
            </div>
          )
        }

        {
          this.state.errorTextKvota && (
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "red",
                  marginBottom: "10px",
                }}
              >
                {this.state.errorTextKvota}
              </Typography>
            </div>
          )
        }
        {
          this.state.errorTextCerpanie && (
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "red",
                  marginBottom: "10px",
                }}
              >
                {this.state.errorTextCerpanie}
              </Typography>
            </div>
          )
        }
        {
          this.state.errorTextMinInterval && (
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "red",
                  marginBottom: "10px",
                }}
              >
                {this.state.errorTextMinInterval}
              </Typography>
            </div>
          )
        }
        {
          this.state.errorTextDuplicita && (
            <div>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "red",
                  marginBottom: "10px",
                }}
              >
                {this.state.errorTextDuplicita}
              </Typography>
            </div>
          )
        }

        <div style={{ position: 'sticky', bottom: '0', right: '0', }}>
          <Divider variant="fullWidth" style={{ height: "2px" }} />
          <div className={classNames(classes.w100, classes.row)} style={{ background: "#FFFFFF" }}>
            <Button
              variant="text"
              color="primary"
              size="large"
              className={classNames(classes.w100, classes.formButton)}
              onClick={() => this.props.handleClose()}
              style={{ height: "45px" }}
            >
              <Typography variant="body1">Zrušiť</Typography>
            </Button>
            <Button
              disabled={this.shouldBeDisabled()}
              variant="contained"
              color="secondary"
              size="large"
              className={classNames(classes.w100, classes.formButton)}
              onClick={() => this.AddDraw()}
              style={{ height: "45px" }}
            >
              <Typography variant="body1" color="inherit">
                Pridať
              </Typography>
            </Button>
          </div>
        </div>
        <TimeDialog
          handleClose={() => this.handleShowTimeDialog(0)}
          handleModifyTime={this.handleModifyTime}
          data={this.getTimeDialogContents()}
        />
      </div>
    );
    return (
      <>
        <CircularProgress loading={this.state.isLoad} className={classes.dialogZIndex} />
        <FormModal
          open={this.state.isOpen}
          onClose={this.props.handleClose}
          elements={fMelements}
          fullScreen={false}
        />
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    listParkingCredit: state.listParkingCredit.data,
    listCreditDraw: state.listCreditDraw.data,
    isLoading: state.listCreditDraw.isLoading,
    listZone: state.listZone.data,
    zoneTimeMaxDate: state.zoneTimeMaxDate.data.Data,
    zoneTime: state.zoneTime.data.Data,
    person: state.person.data,
    listValidParkingCard: state.listValidParkingCard.data,
    listValidParkingCardCount: state.listValidParkingCard.total,
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(QuickCreditDialog))));
