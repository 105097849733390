import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class TicketIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={this.props.style}
        viewBox="0 0 60 40"
        className={this.props.className}
      >
        <path id="Icon_awesome-ticket-alt" data-name="Icon awesome-ticket-alt" d="M13.333,14.5H46.667v20H13.333ZM55,24.5a5,5,0,0,0,5,5v10a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5v-10a5,5,0,0,0,0-10V9.5a5,5,0,0,1,5-5H55a5,5,0,0,1,5,5v10A5,5,0,0,0,55,24.5ZM50,13.667a2.5,2.5,0,0,0-2.5-2.5h-35a2.5,2.5,0,0,0-2.5,2.5V35.333a2.5,2.5,0,0,0,2.5,2.5h35a2.5,2.5,0,0,0,2.5-2.5Z" transform="translate(0 -4.5)" />    </SvgIcon>
    );
  }
}

TicketIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default TicketIconComponent;
