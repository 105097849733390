
import actionTypes from './actionTypes';
import * as userUtils from '../utils/userUtils';

const loginSuccess = (user) => ({
  type: actionTypes.LOGIN_SUCCESS,
  user
});

const loginRequest = () => ({
  type: actionTypes.LOGIN_REQUEST
});

const loginFailure = (error) => ({
  type: actionTypes.LOGIN_FAILURE,
  error
});

export const login = (username, pwd) => {
  return async (dispatch) => {
    try {
      await dispatch(loginRequest());
      let user = await userUtils.login(username, pwd);
      await dispatch(loginSuccess(user));
    } catch (error) {
      await dispatch(loginFailure(error));
      console.error(error);
    }
  };
};

export const showMsg = (msg) => ({
  type: actionTypes.SHOW_MSG,
  msg
});

export const hideMsg = () => ({
  type: actionTypes.HIDE_MSG
});

export const showErrorMsg = (errorMsg) => ({
  type: actionTypes.SHOW_ERROR_MSG,
  errorMsg
});

export const hideErrorMsg = () => ({
  type: actionTypes.HIDE_ERROR_MSG
});

export const logout = () => ({
  type: actionTypes.LOGOUT_SUCCESS
});


const createCodeRequest = () => ({
  type: actionTypes.CREATE_CODE_REQUEST,
});

const createCodeSuccess = (res) => ({
  type: actionTypes.CREATE_CODE_SUCCESS,
  res
});

const createCodeFailure = (error) => ({
  type: actionTypes.CREATE_CODE_FAILURE,
  error
});

export const createCode = (username) => {
  return async (dispatch) => {
    try {
      dispatch(createCodeRequest());
      let res = await userUtils.createCode(username);
      dispatch(createCodeSuccess(res));
      dispatch(showMsg("Na Váš email bol odoslaný odkaz na jednorázové prihlásenie."));
    } catch (error) {
      dispatch(createCodeFailure(error));
    }
  };
};

export const verifyCode = (code) => {
  return async (dispatch) => {
    try {
      dispatch(loginRequest());
      let user = await userUtils.verifyCode(code);
      dispatch(loginSuccess(user));
    } catch (error) {
      console.error(error);
      dispatch(loginFailure(error));
    }
  };
};


const getUserByNameRequest = () => ({
  type: actionTypes.GET_USER_BY_NAME_REQUEST
});

const getUserByNameSuccess = (res) => ({
  type: actionTypes.GET_USER_BY_NAME_SUCCESS,
  res
});

const getUserByNameFailure = (error) => ({
  type: actionTypes.GET_USER_BY_NAME_FAILURE,
  error
});

export const getUserByName = (name) => {
  return async (dispatch) => {
    try {
      dispatch(getUserByNameRequest());
      let res = await userUtils.getUserByName(name);
      dispatch(getUserByNameSuccess(res));
    } catch (error) {
      dispatch(getUserByNameFailure(error));
    }
  };
};

const UserModifyRequest = () => ({
  type: actionTypes.USER_MODIFY_REQUEST
});

const UserModifySuccess = (res) => ({
  type: actionTypes.USER_MODIFY_SUCCESS,
  res
});

const UserModifyFailure = (error) => ({
  type: actionTypes.USER_MODIFY_FAILURE,
  error
});

export const UserModify = (Email, Heslo, Tel) => {
  return async (dispatch) => {
    try {
      dispatch(UserModifyRequest());
      let res = await userUtils.userModify(Email, Heslo, Tel);
      dispatch(UserModifySuccess(res));
      return true;
    } catch (error) {
      dispatch(UserModifyFailure(error));
      return false;
    }
  };
};

const setLocaleRequest = () => ({
  type: actionTypes.SET_LOCALE_REQUEST
});

const setLocaleSuccess = (res) => ({
  type: actionTypes.SET_LOCALE_SUCCESS,
  res
});

const setLocaleFailure = (error) => ({
  type: actionTypes.SET_LOCALE_FAILURE,
  error
});

export const setLocale = (locale) => {
  return async (dispatch) => {
    try {
      dispatch(setLocaleRequest());
      //let res = await userUtils.setLocale(locale);
      dispatch(setLocaleSuccess(locale));
      return true;
    } catch (error) {
      dispatch(setLocaleFailure(error));
      return false;
    }
  };
};


const loginEidRequest = () => ({
  type: actionTypes.LOGINEID_REQUEST
});

const loginEidSuccess = (user) => ({
  type: actionTypes.LOGINEID_SUCCESS,
  user
});

const loginEidFailure = (error) => ({
  type: actionTypes.LOGINEID_FAILURE,
  error
});

export const loginEid = (code) => {
  return async (dispatch) => {
    try {
      await dispatch(loginEidRequest());
      let user = await userUtils.loginEid(code);
      await dispatch(loginEidSuccess(user));
      return user;
    } catch (error) {
      await dispatch(loginEidFailure(error));
      console.error(error);
      return null;
    }
  };
};

const getPersonEidRequest = () => ({
  type: actionTypes.GET_PERSON_EID_REQUEST
});

const getPersonEidSuccess = (res) => ({
  type: actionTypes.GET_PERSON_EID_SUCCESS,
  res
});

const getPersonEidFailure = (error) => ({
  type: actionTypes.GET_PERSON_EID_FAILURE,
  error
});

export const getPersonEid = (code, type) => {
  return async (dispatch) => {
    try {
      dispatch(getPersonEidRequest());
      let res = await userUtils.getPersonEid(code, type);
      dispatch(getPersonEidSuccess(res));
    } catch (error) {
      dispatch(getPersonEidFailure(error));
    }
  };
};

const getPersonEidTypeRequest = () => ({
  type: actionTypes.GET_PERSON_EID_TYPE_REQUEST
});

const getPersonEidTypeSuccess = (res) => ({
  type: actionTypes.GET_PERSON_EID_TYPE_SUCCESS,
  res
});

const getPersonEidTypeFailure = (error) => ({
  type: actionTypes.GET_PERSON_EID_TYPE_FAILURE,
  error
});

export const getPersonEidType = (code) => {
  return async (dispatch) => {
    try {
      dispatch(getPersonEidTypeRequest());
      let res = await userUtils.getPersonEidType(code);
      dispatch(getPersonEidTypeSuccess(res));
      return res.Data;
    } catch (error) {
      dispatch(getPersonEidTypeFailure(error));
      return false;
    }
  };
};