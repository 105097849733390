import React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';


class EmailIconComponent extends React.Component {
  render() {
    return (
      <EmailIcon
        style={this.props.style}
      />
    )
  }
}
EmailIconComponent.propTypes = {
  style: PropTypes.object
}

export default EmailIconComponent;