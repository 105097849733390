import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class EducationIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polygon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		18,7 9.5,4 1,7 9.5,10 	"/>

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="18" y1="7" x2="18" y2="11" />

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="18" y1="14" x2="18" y2="14" />
          <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
		M15,11v1.8c0,1.5-2.5,2.2-5.5,2.2S4,14.1,4,12.6V11"/>
        </g>
      </SvgIcon>
    );
  }
}

EducationIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default EducationIconComponent;
