import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
};

const listProceedings = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_PROCEEDINGS_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_PROCEEDINGS_SUCCESS:
      return { isLoading: false, data: action.res.Data ? action.res.Data.Items : _initState.data, total: action.res.Data ? action.res.Data.TotalRecords : _initState.total };

    case actionTypes.GET_LIST_PROCEEDINGS_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default listProceedings;