import React from 'react';
import AvTimer from '@mui/icons-material/AvTimer';
import PropTypes from 'prop-types';

class AvTimerIconComponent extends React.Component {
  render() {
    return (
      <AvTimer
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
      />
    )
  }
}

AvTimerIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string
}

export default AvTimerIconComponent;