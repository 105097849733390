import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: []
}

const versionInfo = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_VERSION_INFO_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_VERSION_INFO_SUCCESS:
      return { isLoading: false, data: action.res }

    case actionTypes.GET_VERSION_INFO_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default versionInfo;