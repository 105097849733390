import { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { makeStyles } from '@material-ui/core/styles';
import withWidth from "@material-ui/core/withWidth";
import { IconButton } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save'
import { Box } from '@mui/material';

import withRouter from "../../coraWebMComponents/utils/withRouter";
import { withLocalizationConsumer } from "../../coraWebMComponents/localization/withLocalization";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";
import Button from "../../coraWebMComponents/inputs/Button";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";
import Editor from "../../coraWebMComponents/inputs/Editor";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";
import withMeta from "../../coraWebMComponents/utils/withMeta";

import routes from "../../routes";
import { adminModeSetCurrEdited, adminModeSetEditedData, adminModeUpdateOrAdd } from "../../actions/adminModeActions";
import { PRELOGIN_BTNS, PUBLIC_ZONE, IFrmTxt, ISpMnu } from "../../constants/adminConsts";
import MenuTiles from "./MenuTiles";
import ScrollTopButton from "../info/ScrollTopButton";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  paperCard: {
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "45px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "60px",
    },
  },
  backButton: {
    backgroundColor: "transparent!important",
    paddingLeft: "0px",
  },
}));

/**
 * Infotext verejnej zony, admin mod infotextu verejnej zony, dlazdice public menu
 *
 * @function PublicZone
 */
const PublicZone = (props) => {
  const [textPublicZone, setTextPublicZone] = useState("");
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [scrollTopButton, setScrollTopButton] = useState({
    showScrollTopButton: false,
    showScrollTopRef: null,
  });
  const refTitle = useRef();
  const classes = useStyles();

  const { adminMode } = props;
  const { isActive, currEdited } = adminMode;
  const menuA = props.menuTree.children;
  const menuPublic = menuA.find((x) => x.MenuID === 4004007)?.children.filter(x => x.Visible === 1); // verejna zona

  useEffect(() => {
    initTextData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initTextData = async () => {
    const txtPublicZone = await props.translateWithLocaleAsync(
      props.locale,
      "DM_VEREJNA_ZONA"
    );
    setTextPublicZone(txtPublicZone);
  };

  const onScroll = async (event) => {
    const target = event.target;

    if (target.scrollTop > 10 && !scrollTopButton.showScrollTopButton) {
      setScrollTopButton((prev) => ({ ...prev, showScrollTopButton: true, showScrollTopRef: target }));
    }
    if (target.scrollTop <= 10 && scrollTopButton.showScrollTopButton) {
      setScrollTopButton((prev) => ({ ...prev, showScrollTopButton: false, showScrollTopRef: target }));
    }
  };

  const handleScrollTopButtonClick = async (target) => {
    if (target?.scrollTop) {
      target.scrollTop = 0;
    }

    // Skry tlačidlo po posune na vrch
    setScrollTopButton(prevState => ({ ...prevState, showScrollTopButton: false }));
  };

  const handleEditData = (data) => {
    const { currEdited } = props.adminMode;
    switch (currEdited) {
      case PUBLIC_ZONE:
        props.dispatch(
          adminModeSetEditedData([
            {
              ...textPublicZone,
              TxtFrm: data,
              ID: 4004001,
              locale: props.locale,
              N_KLUC: "DM_VEREJNA_ZONA",
              IFrmTxt: 4,
            },
          ])
        );
        break;
      default:
        return;
    }
  };

  const handleSaveSection = async () => {
    const { dataEdited, currEdited } = props.adminMode;
    if (currEdited === PRELOGIN_BTNS) {
      await props.dispatch(adminModeUpdateOrAdd(ISpMnu, dataEdited));
    } else {
      await props.dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
      await initTextData();
    }
    props.dispatch(adminModeSetCurrEdited(null));
    handleCloseDialog();
  };

  const handleCurrEdited = (value) => {
    props.dispatch(adminModeSetCurrEdited(value));
    props.dispatch(adminModeSetEditedData(null));
  };

  const handleOpenSaveDialog = () => {
    setOpenSaveDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenSaveDialog(false);
  };

  const getEditButton = (drawer) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{
          width: "200px",
          position: "relative",
          left: "50%",
          margin: "20px 0",
          transform: "translateX(-50%)",
        }}
        className={classNames(
          classes.w100,
          classes.textTransformNone,
          classes.adminButton
        )}
        onClick={() => handleCurrEdited(drawer)}
        startIcon={<EditIcon />}
      >
        Upraviť
      </Button>
    );
  };

  const getSaveButton = () => {
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{
          width: "200px",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          margin: "20px 0",
        }}
        type="success"
        className={classNames(classes.w100, classes.textTransformNone)}
        onClick={handleOpenSaveDialog}
        startIcon={<SaveIcon />}
      >
        Uložiť
      </Button>
    );
  };

  const ReactToHtml = (component) => {
    const HTML = ReactDOMServer.renderToStaticMarkup(component);
    const HTMLstring = HTML.toString();
    return HTMLstring;
  };

  return (
    <Box ref={refTitle} onScroll={onScroll}
      style={{ height: "calc(100vh - 70px)", overflow: "auto", marginTop: "70px" }}
    >

      {/* Sipka pre scroll nahor */}
      {scrollTopButton.showScrollTopButton && (
        <ScrollTopButton onClick={() => handleScrollTopButtonClick(scrollTopButton.showScrollTopRef)} />
      )}

      <div className='container public-zone'   >
        <Link to={routes.PRELOGIN}>
          <IconButton
            className={classNames(classes.backButton, classes.navigationBackButton, classes.ml1, classes.mr6)}
            color="inherit"
            toolTip={props.translate("back")}
            toolTipPlace="right"
          >
            <ArrowBack
              variant="fab"
              style={{ fontSize: 30 }}
            />
          </IconButton>
        </Link>
        
        {textPublicZone.length > 0 && currEdited !== PUBLIC_ZONE && (
          <div className={classNames(classes.mt4, classes.mb4)}>
            <Typography className={classNames(classes.textCenter)}>
              {textPublicZone}
            </Typography>
          </div>
        )}

        {textPublicZone.length > 0 &&
          currEdited === PUBLIC_ZONE && (
            <Editor
              onChange={handleEditData}
              value={ReactToHtml(textPublicZone)}
            />
          )}

        {isActive && currEdited !== PUBLIC_ZONE && getEditButton(PUBLIC_ZONE)}
        {isActive && currEdited === PUBLIC_ZONE && getSaveButton()}

        {/* Dlazdice menu */}
        <MenuTiles classes={classes} menuItems={menuPublic} handleMenuClick={props.navigate} />
      </div>

      <Dialog
        modal={false}
        open={openSaveDialog}
        onClose={handleCloseDialog}
        contentText="Informácia: Potvrdením uložíte zmeny"
        title="Uloženie nastavení"
        actions={[
          {
            label: "Nie",
            color: "primary",
            onClick: handleCloseDialog,
            keyboardFocused: false,
          },
          {
            label: "Áno",
            color: "secondary",
            onClick: handleSaveSection,
            keyboardFocused: true,
          },
        ]}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
    locale: state.locale.locale,
    adminMode: state.adminMode,
  };
};

export default withMeta(withRouter(withLocalizationConsumer(
  connect(mapStateToProps)(withWidth()(PublicZone) )
)));
