import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  isLoaded: false,
  data: [],
  total: 0
};

const listZstCDtzOtz = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ZST_C_DTZ_OTZ_LIST_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ZST_C_DTZ_OTZ_LIST_SUCCESS:
      let { isLoading, isLoaded, data, total } = _initState;
      if (Boolean(action.res.Data)) {
        isLoaded = true;
        data = action.res.Data.Items;
        total = action.res.Data.TotalRecords;
      }
      return { isLoading, isLoaded, data, total };

    case actionTypes.GET_ZST_C_DTZ_OTZ_LIST_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default listZstCDtzOtz;