import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from '@mui/icons-material/Close';

import IconButton from "../../coraWebMComponents/inputs/IconButton";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import { Transition, LABEL_NAME, VALUE_NAME } from "./support";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    margin: 0,
    padding: theme.spacing(2),
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "black",
  },
  lbl: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.secondary,
  },
}));

const ComboFilterDialogComponent = ({ closeClick, fltMeta, isOpen, onClick, value }) => {
  const classes = useStyles();

  if (!isOpen) return null;

  const { id, label, dataItems, noItem } = fltMeta;

  let iOrder = 0;
  const addItem = (val, lbl) => (
    <ListItem
      button
      alignItems="center"
      key={`${val}${++iOrder}`}
      selected={value === val}
      onClick={() => onClick(id, val)}
      className={classNames(classes.row)}
      style={val === value ? { backgroundColor: 'rgb(246, 255, 245)' } : null}
      divider
    >
      <ListItemText primary={lbl} className={classNames(classes.lbl)} />
    </ListItem>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeClick}
      scroll="paper"
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.root}>
        <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }} >
          <h3>{label}</h3>
        </Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={closeClick}
          toolTip="Zavieť dialógové okno"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List component="nav">
          {noItem && !noItem.disabled && noItem.topPosition && addItem(noItem.value, noItem.label)}
          {dataItems.map((cboItem) => addItem(cboItem[VALUE_NAME], cboItem[LABEL_NAME]))}
          {noItem && !noItem.disabled && !noItem.topPosition && addItem(noItem.value, noItem.label)}
        </List>
      </DialogContent>
    </Dialog>
  );
};

ComboFilterDialogComponent.propTypes = {
  className: PropTypes.string,
  closeClick: PropTypes.func.isRequired,
  fltMeta: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
};

export default ComboFilterDialogComponent;
