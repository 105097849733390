import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

/**
 * Dialog icon component
 * @module
 * @param {string} [title] - Dialog title
 * @param {array} actions - Dialog actions
 * @param {bool} open - Whether the dialog is open
 * @param {func} [onClose] - Close handler function
 * @param {bool} [modal] - Modal
 */
class DialogIconComponent extends React.Component {
  render() {
    const { title, actions } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        modal={this.props.modal}
        aria-labelledby='ariaLabelledby'
        aria-describedby='ariaDescribedby'
        TransitionComponent={Transition}
      >
        <DialogTitle id='ariaLabelledby'>{title}</DialogTitle>
        <List>
          {actions.map(x => (
            <ListItem button onClick={x.onClick} key={x.label}>
              {x.icon}
              <ListItemText primary={x.label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    )
  }
}

DialogIconComponent.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  modal: PropTypes.bool
}

export default DialogIconComponent;