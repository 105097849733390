import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

class SelfGovIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="1,10 1,18 18,18 
		18,10 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="14,8 14,18 5,18 
		5,8 	"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="0" y1="9" x2="6" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="9" x2="19" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="7" x2="15" y2="7" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="10" y1="9" x2="9" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="8" y1="12" x2="7" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="10" y1="15" x2="9" y2="15" />
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M6,6V5.5
		c0-1.9,1.6-3.6,3.5-3.6S13,3.5,13,5.4V6"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="10" y1="12" x2="9" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="12" y1="12" x2="11" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="8" y1="15" x2="7" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="12" y1="15" x2="11" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="8" y1="9" x2="7" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="12" y1="9" x2="11" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="16" y1="12" x2="15" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="16" y1="15" x2="15" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="15" x2="3" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="12" x2="3" y2="12" />
        </g>
      </SvgIcon>
    );
  }
}

SelfGovIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default SelfGovIconComponent;
