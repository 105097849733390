import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: []
};

const cityOfSetup = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_CITY_OF_SETUP_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_CITY_OF_SETUP_SUCCESS:
      return { isLoading: false, data: action.res };

    case actionTypes.GET_CITY_OF_SETUP_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default cityOfSetup;