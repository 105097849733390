import React from 'react';
import Edit from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

class EditIconComponent extends React.Component {
  render() {
    return (
      <Edit
        style={this.props.style}
      />
    )
  }
}

EditIconComponent.propTypes = {
  style: PropTypes.object
}

export default EditIconComponent;
