import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: []
};

const parkingCredit = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_CREDIT_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_PARKING_CREDIT_SUCCESS:
      return { isLoading: false, data: action.res };

    case actionTypes.GET_PARKING_CREDIT_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default parkingCredit;