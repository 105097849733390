import React from 'react';
import { connect } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";

import IconButton from "../../coraWebMComponents/inputs/IconButton";
import InfoIcon from "../../coraWebMComponents/dataDisplay/icons/InfoIcon";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";
import TextField from "../../coraWebMComponents/inputs/TextField";

import InfoDialog from "./InfoDialog";
import "./PulseInfoButton.css";
import * as adminModeUtils from '../../utils/adminModeUtils';

class PulseInfoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoOpen: false,
      visible: false,
      pulse: false,
      pulseTime: "",
      errorTextPulseTime: "",
    };
  }

  componentDidMount() {
    const { type } = this.props;
    const root = document.getElementById('root');
    this.setState({ pulse: this.props.versionInfo.Data?.L_ZOBRAZ });
    if (type === "PreLogin") {
      root.style.setProperty('--bg-color', '#343434');
      root.style.setProperty('--color', '#343434');
      root.style.setProperty('--info-bg-color', 'white');
    } else {
      root.style.setProperty('--bg-color', '#f5f5f5');
      root.style.setProperty('--color', '#f5f5f5');
      root.style.setProperty('--info-bg-color', 'black');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.versionInfo.Data?.L_ZOBRAZ !== this.props.versionInfo.Data?.L_ZOBRAZ) {
      this.setState({ pulse: this.props.versionInfo.Data?.L_ZOBRAZ });
    }
  }

  handleSetVisible = async (value) => {
    this.setState({ visible: value });
  };

  handleOpenInfo = () => {
    this.setState({ isInfoOpen: true });
  };

  handleCloseInfo = () => {
    this.setState({ isInfoOpen: false });
  };

  handleOpenChangeTimeDialog = () => {
    this.setState({ isChangeTimeOpen: true });
  };

  handleCloseChangeTimeDialog = () => {
    this.setState({ isChangeTimeOpen: false, pulseTime: "", errorTextPulseTime: "" });
  };

  handleChangeTimeInput = (name, value) => {
    if (!/^\d+$/.test(value)) {
      this.setState({ errorTextPulseTime: "Zadávajte iba čísla!" });
    } else {
      this.setState({ errorTextPulseTime: "" });
    }
    this.setState({ pulseTime: value });

  };

  getChangeTimeDialogContents = () => {
    return (
      <>
        <TextField
          onChange={this.handleChangeTimeInput}
          label="Počet dní"
          value={this.state.pulseTime}
          errorText={this.state.errorTextPulseTime}
          error={this.state.errorTextPulseTime ? true : false}
          fullwidth={true}
          variant="standard"
        />
      </>
    );
  };

  handleChangeTime = () => {
    adminModeUtils.updateSpDefOb(4004001001, this.state.pulseTime);
    this.handleCloseChangeTimeDialog();
  };


  render() {
    const { type } = this.props;
    const isPhoneLogin = type === 'Login' && window.innerHeight < 750;
    return (
      <>
        <div
          style={{ float: "right" }}
        >
          {this.props.adminMode.isActive &&
            <>
              <IconButton
                color="secondary"
                toolTip="Nastaviť čas pulzovania po vydaní verzie"
                toolTipPlace="right-start"
                onClick={this.handleOpenChangeTimeDialog}
                style={
                  isPhoneLogin ? {
                    background: "rgba(0,0,0,0)",
                    marginTop: 10,
                    color: type === "PreLogin" ? "#f5f5f5" : "#343434",
                  } : {
                    background: "rgba(0,0,0,0)",
                    position: "fixed",
                    bottom: 10,
                    right: 50,
                    color: type === "PreLogin" ? "#f5f5f5" : "#343434",
                  }
                }
              >
                <EditIcon />
              </IconButton>
              <Dialog
                open={this.state.isChangeTimeOpen}
                onClose={this.handleCloseChangeTimeDialog}
                title="Nastaviť čas pulzovania po vydaní verzie"
                content={this.getChangeTimeDialogContents()}
                actions={[
                  {
                    label: "Zavrieť",
                    color: "primary",
                    onClick: () => this.handleCloseChangeTimeDialog()
                  },
                  {
                    label: "Uložiť",
                    color: "secondary",
                    onClick: () => this.handleChangeTime(),
                    disabled: this.state.errorTextPulseTime || !this.state.pulseTime || this.state.pulseTime.length > 10 ? true : false

                  },
                ]}
              >
              </Dialog>
            </>
          }
          {this.state.visible &&
            <div className="parent"
              style={
                isPhoneLogin ? {
                  background: "rgba(0,0,0,0)",
                  marginTop: 10,
                  float: "right",
                } : {
                  background: "rgba(0,0,0,0)",
                  position: "fixed",
                  bottom: 10,
                  right: 10,
                }
              }
            >
              <div className={"info " + (this.state.pulse ? "" : "noAnimation")}
                style={
                  {
                    background: "rgba(0,0,0,0)",
                  }}
              >
                <IconButton
                  color="secondary"
                  onClick={this.handleOpenInfo}
                  toolTip="Informácia"
                  toolTipPlace="top"
                >
                  <InfoIcon
                    style={
                      {
                        color: type === "PreLogin" ? "#f5f5f5" : "#343434",
                        height: '28px',
                        width: '28px',
                      }
                    }
                    variant='fab' />
                  <div
                    style={{
                      position: "absolute",
                      zIndex: -1,
                      height: '20px',
                      width: '20px',
                      background: this.state.pulse ? type === "PreLogin" ? "#343434" : "#f5f5f5" : "rgba(0,0,0,0)",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
              </div>
            </div >}
          <InfoDialog title={this.props.title} open={this.state.isInfoOpen} handleClose={this.handleCloseInfo} N_KLUC={this.props.N_KLUC} currEdited={this.props.currEdited} handleSetVisible={this.handleSetVisible}>
          </InfoDialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  versionInfo: state.versionInfo.data,
  adminMode: state.adminMode
});

export default connect(mapStateToProps)(PulseInfoButton);