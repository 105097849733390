import {
  blueberryTwilightPalette, blueberryTwilightPaletteLight, blueberryTwilightPaletteDark,
  mangoFusionPalette, mangoFusionPaletteLight, mangoFusionPaletteDark,
  cheerfulFiestaPalette, cheerfulFiestaPaletteLight, cheerfulFiestaPaletteDark,
} from '@mui/x-charts/colorPalettes';

/**
 * Rozdel text na riadky po danom pocte slov, default slov na riadok: 0
 *
 * @param {*} str
 * @param {number} [maxWords=0]
 * @return {*} 
 */
export const splitStringIntoLines = (str, maxWords = 0) => {
  if (maxWords <= 0 || typeof maxWords !== 'number') {
    return str;
  }

  let words = str.trim().replace(/\s+/g, ' ').split(" ");
  let result = '';

  for (let i = 0; i < words.length; i++) {
    result += words[i];
    if ((i + 1) % maxWords === 0) {
      result += '\n';
    } else {
      result += ' ';
    }
  }
  return result;
};

/**
 * Rozdel text na riadky po danom pocte slov, default slov na riadok: 1
 *
 * @param {*} text
 * @param {number} [words=1]
 * @return {*} 
 */
export const splitText = (text, words = 1) => {
  return splitStringIntoLines(text, words);
};

/**
 * Vrati MUI paletu farieb ak je colors typu string, inac ak je colors pole, vrati pole colors. 
 * Defaultne vrati 'mangoFusionPalette' ak paleta neexistuje alebo colors nie je typu pole/array
 *
 * @param {*} colors
 * @return {*} 
 */
export const getColorsAttribute = (colors) => {
  if (Array.isArray(colors)) {
    return colors;
  } else if (typeof colors === 'string') {
    switch (colors) {
      case 'blueberryTwilightPaletteLight':
        return blueberryTwilightPaletteLight;
      case 'blueberryTwilightPaletteDark':
        return blueberryTwilightPaletteDark;
      case 'blueberryTwilightPalette':
        return blueberryTwilightPalette;
      case 'mangoFusionPaletteLight':
        return mangoFusionPaletteLight;
      case 'mangoFusionPaletteDark':
        return mangoFusionPaletteDark;
      case 'mangoFusionPalette':
        return mangoFusionPalette;
      case 'cheerfulFiestaPaletteDark':
        return cheerfulFiestaPaletteDark;
      case 'cheerfulFiestaPaletteLight':
        return cheerfulFiestaPaletteLight;
      case 'cheerfulFiestaPalette':
        return cheerfulFiestaPalette;
      default:
        return mangoFusionPalette; // Default paleta ak nie je colors definovany alebo nezodpoveda ziadnej palete
    }
  } else {
    return mangoFusionPalette; // Default paleta ak nie je colors definovany
  }
};
